@keyframes scroll_line {
  0% { height: 107px; }
  30% { height: 107px; }
  60% { height: 7px; }
  100% { height: 107px; }
}

@keyframes scroll_line_sp {
  0% { height: 68px; }
  30% { height: 68px; }
  60% { height: 7px; }
  100% { height: 68px; }
}

@keyframes loadingAnime{
  0% {
    transform-origin:left;
    transform:scaleX(0);
  }
  50% {
    transform-origin:left;
    transform:scaleX(1);
  }
  100% {
    transform-origin:left;
    transform:scaleX(1);
    opacity: 0;
  }
}

//mainvisual
.mbYTP_wrapper {
  position: relative;
  height: calc(100vh - 55px);
  height: calc(100svh - 55px); //Safari用
  z-index: -1 !important;
  opacity: 1 !important;
  @include media-up(md) {
    height: calc(100vh - 110px);
    height: calc(100svh - 110px); //Safari用
  }
}
.mainvisual {
  position: relative;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 110px);
  height: calc(100svh - 110px); //Safari用
  margin: 0;
  @include media-down(md) {
    height: calc(100vh - 55px);
    height: calc(100svh - 55px); //Safari用
  }

  #loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: $white;
    opacity: 1;
    transition: opacity .3s ease-in-out;
  }

  &__inner.is-loaded #loading {
    animation: loadingAnime 1s .1s ease-in-out forwards;
  }

  &__wrap {
    width: 100%;
    @include media-down(lg) {
      padding-left: 32px;
      background: $white;
    }
  }

  &__inner {
    width: 100%;
    margin-left: auto;
    z-index: 1;
    @include media-up(lg) {
      width: calc(100% - 100px);
      height: calc(100vh - 110px);
      height: calc(100svh - 110px); //Safari用
      margin-right: auto;
    }
  }
  &__catch {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    margin: auto;
    padding: 0 0 52px 25px;
    color: $white;
    @include media-up(lg) {
      padding: 0 0 84px min(70px, 8vw);
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    width: min(282px, 76vw);
    @include media-up(sm) {
      width: clamp(400px, 48vw, 661px);
    }
    img {
      max-width: 100%;
      width: 100%;
      height: auto;
    }
  }

  &__scroll {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 200px;
    margin: auto;
    border-width: 0;
    font-family: 'Cantarell', $font-family-base;
    font-weight: $font-weight-bold;
    color: #005FAA;
    font-size: $font-size-xs;
    letter-spacing: .2em;
    transform: translateX(-100%);
    @include media-down(lg) {
      width: 32px;
      height: 130px;
      font-size: $font-size-xxs;
    }
    
    &:hover,
    &:focus {
      color: #005FAA;
      text-decoration: none;
    }

    >span {
      display: block;
      line-height: 1;
      transform: rotate(90deg) translateY(0) translateX(40%);
      @include media-up(lg) {
        transform: rotate(90deg) translateY(0) translateX(85%);
      }
    }

    .is-scroll-line {
      position: absolute;
      top: auto;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      width: 1px;
      height: 68px;
      margin: auto;
      background: #CED4DF;
      z-index: 1;
      @include media-up(lg) {
        height: 107px;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        width: 1px;
        height: 6px;
        background: #003E99;
        animation: scroll_line_sp 2s infinite ease-in-out;
        @include media-up(lg) {
          animation: scroll_line 2s infinite ease-in-out;
        }
      }
    }
  }

  &__copyright {
    @include media-up(lg) {
      position: absolute;
      top: 0; bottom: 0;
      left: auto; right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 50px;
      height: 100%;
      margin: 0 auto 0 0;
      writing-mode: vertical-rl;
      font-feature-settings: "pkna";
      transform: translateX(100%);
    }
    small {
      font-family: 'Jost', $font-family-base;
      font-size: $font-size-xxs;
      color: #CED4DF;
      letter-spacing: 0.24em;
      text-indent: 0.24em;
    }
  }
}

.rwd002-mainImg {
  display: flex;
  justify-content: center;
  margin-bottom: map-get($spacers, 6);
  @include media-down(lg) {
    margin-bottom: map-get($spacers, 4);
  }
  &__inner {
    img {
      max-width: 100%;
      height: auto;
    }
    > img[width="100%"] {
      width: 100vw;
      height: 100%;
      object-fit: cover;
      max-height: calc(100vh - 120px);
      @include media-down(md) {
        max-height: calc(100vh - 55px);
      }
    }
  }
}

