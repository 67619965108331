.is--company {
  .rwd002-detail {
    &__title {
      color: #0057A8;
      font-size: min(4vw, 1rem);
      line-height: 0;
      letter-spacing: 0.34em;
      margin: 0;
      border: none;
    }
  }

  .rwd002-pagination {
    display: none;
  }

  .content-head-banner {
    margin-top: 46px;
    margin-bottom: 38px;
    @include media-up(lg) {
      margin-top: 60px;
      margin-bottom: 64px;
    }
  }

  .anchor-links {
    padding: 0 2rem;
  }

  .page-nav {
    gap: 16px 24px;
    padding: 0 0;
    .btn {
      width: 310px;
      height: 60px;
      color: #4E638A;
      @include media-up(lg) {
        width: 100%;
        height: 87px;
      }
      &.current, &:hover, &:focus {
        background-color: #4E638A;
        color: $white;
      }
    }
  }
}


.block-company-title, .block-philosophy-title {
  .title {
    font-size: 1.25rem;
    font-family: 'Jost', $font-family-base;
    font-weight: bold;
    letter-spacing: 0.1em;
    display: flex;
    flex-direction: column;
    position: relative;

    @include media-up(lg) {
      width: 210px;
    }

    &::before {
      content: '';
      width: 35px;
      height: 1px;
      position: absolute;
      top: -45px;
      background-color: #CCD4E0;
    }

    .subtitle {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.18rem;
      letter-spacing: 0.24em;
      margin-bottom: 1.25rem;
    }
  }
}

.subblock {
  margin-top: 18px;

  .title {
    color: #566384;
    font-size: 1.25rem;
    font-family: $font-family-base;
    font-weight: bold;
    line-height: 46px;
    letter-spacing: 0.14em;
    display: flex;
    gap: 10px;
  }
}

.link-google-map {
  margin-top: 20px;
  display: flex;
  justify-content: center;

  a {
    color: #2B334C;
    width: fit-content;
    font-size: 12px;
    font-family: 'Jost', sans-serif;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0.160em;
    padding-bottom: 5px;
    display: flex;
    gap: 5px;
    position: relative;
    transition: all 0.5s;

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      background-color: #4D535F;
    }

    &:hover {
      text-decoration: none;
      gap: 10px;
    }
  }
}

.block__philosophy {
  position: relative;
  max-width: calc(960px + calc(map-get($spacers, 3) * 2));
  width: 100%; 
  margin: 65px auto 70px;
  padding: 2.8rem map-get($spacers, 3) 0;
  font-feature-settings: "palt";

  @include media-up(lg) {
    display: flex;
    gap: 100px;
  }

  &::before {
    content: '';
    width: 35px;
    height: 1px;
    position: absolute;
    top: 0;
    background-color: #CCD4E0;
  }

  &-title {
    @include media-up(lg) {
      width: 256px;
    }
  }

  &-title__img {
    margin-top: 40px;
    @include media-down(lg) {
      margin-top: 50px;
      text-align: center;
    }
  }

  .title {
    font-size: 1.25rem;
    font-family: 'Jost', sans-serif;
    font-weight: bold;
    letter-spacing: 0.1em;
    display: flex;
    flex-direction: column;

    @include media-up(lg) {
      width: 210px;
    }

    .subtitle {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.18rem;
      letter-spacing: 0.24em;
      margin-bottom: 1.25rem;
    }
  }

  figcaption {
    margin-top: 30px;
    p {
      font-size: 14px;
    }
    h5 {
      font-size: 1.4375rem;
      font-family: $font-family-serif;
      font-weight: 500;
      text-align: center;
      line-height: 23px;
      letter-spacing: 0.2em;
      display: flex;
      flex-direction: column;
      align-items: center;

      .subtitle {
        font-size: 0.875rem;
        font-family: $font-family-base;
        font-weight: 500;
        margin-bottom: 5px;
      }
    }
  } 

  &-body {
    padding-top: 20px;
    @include media-up(lg) {
      padding-top: 0;
      margin-top: 2.2em;
    }
    @include media-down(lg) {
      margin-bottom: 65px;
    }
  }
  
  &-body__title {
    display: flex;
    gap: 10px;
    margin-bottom: 30px;
    color: #566384;
    font-size: 1.25rem;
    font-weight: $font-weight-bold;
    letter-spacing: 0.14em;
    @include media-up(lg) {
      margin-bottom: 40px;
    }
  }

  &-body__list {
    margin: 0;
    padding: 0 0 0 2em;
    list-style: none;
    li {
      font-size: 0.875rem;
      font-family: $font-family-serif;
      font-weight: 600;
      line-height: 2;
      letter-spacing: 0.2em;
      position: relative;

      &:not(:last-child) {
        margin-bottom: 31px;
      }

      &::before {
        content: '';
        width: 21px;
        height: 8px;
        position: absolute;
        top: 15px;
        left: -30px;
        background-image: url('data:image/svg+xml;utf,<svg id="_レイヤー_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.6 7.58"><g id="_文字"><path d="m10.04,1.41l1.04-1.41s1.18.98,1.89,1.65c-.04.17-.21.22-.43.22H.14l-.14-.46h10.04Z"/><path d="m19.43,6.51c0-.6.49-1.08,1.09-1.08s1.08.48,1.08,1.08-.48,1.06-1.08,1.06-1.09-.48-1.09-1.06Z"/></g></svg>');
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
  &-body__name {
    margin-top: 48px;
    margin-left: 20px;
    .info {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.8;
      letter-spacing: .2em;
    }

    .name {
      font-size: 23px;
      font-family: $font-family-serif;
      font-weight: 600;
      line-height: 23px;
      letter-spacing: 0.2em;
      margin: 0;
      span {
        font-size: 15px;
        font-weight: 600;
        margin-right: 10px;
      }
    }
  }
}

.block__our-heart {
  position: relative;
  width: 100%;
  margin: 0 auto;
  background-color: #F8F8F8;
  padding: 78px 31px 55px;
  font-feature-settings: "palt";
  @include media-up(lg) {
    padding: 160px 31px 175px;
  }
  
  &__title {
    h2 {
      text-align: center;
    }
  }

  &__body {
    max-width: 750px;
    margin-inline: auto;
    h3 {
      text-align: center;
      strong, span {
        display: block;
        line-height: 1;
      }
      strong {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: .2em
      }
      span {
        font-size: 15px;
        font-weight: 700;
        letter-spacing: .24em;
        margin-top: 15px;
      }
    }
    .arrow_title {
      @include arrow-title;
      margin-top: 50px;
    }
    h5 {
      font-size: 27px;
      font-weight: 700;
      line-height: 1;
      letter-spacing: .16em;
      padding-bottom: 15px;
      border-bottom: 2px solid #000;
      min-width: 363px;
      max-inline-size: max-content;
      margin: 47px auto 0;
      text-align: center;
    }
    .text {
      margin-top: 25px;
      font-size: 15px;
      font-weight: 500;
      line-height: 2;
      letter-spacing: .08em;
    }
    .arrow_wrap {
      margin-top: 35px;
      padding-block: 30px 30px;
      position: relative;
      border-block: 1px solid #4E638A;
      &::before, &::after {
        content: '';
        display: block;
        position: absolute;
        width: 13px;
        height: 13px;
        border-right: 1px solid #4E638A;
        border-bottom: 1px solid #4E638A;
        background-color: #f8f8f8;
        transform: rotate(45deg);
        left: 0;
        right: 0;
        margin: auto;
      }
      &::before {
        top: -7px;
      }
      &::after {
        bottom: -7px;
      }
      p {
        font-size: 15px;
        font-weight: 500;
        line-height: 1.9;
        letter-spacing: .08em;
        margin: 0;
        @include media-up(lg){
          text-align: center;
        }
      }
    }
    dl {
      margin-top: 38px;
      text-align: center;
      dt {
        font-size: 15px;
        font-weight: 500;
        letter-spacing: .08em;
        line-height: 1;
      }
      dd {
        margin-top: 50px;
        line-height: 1;
        font-family: $font-family-serif;
        font-weight: 600;
        letter-spacing: .2em;
        font-size: 23px;
        span {
          font-size: 15px;
          margin-right: 20px;
        }
      }
    }
    figure {
      max-width: 620px;
      margin: 50px auto 0;
      img {
        max-width: 100%;
        height: auto;
      }
    }

    &-one {
      margin-top: 110px;
    }
    &-two {
      margin-top: 67px;
      padding-top: 65px;
      position: relative;
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: min(90vw, 960px);
        height: 1px;
        background-color: #E0E3EA;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
      }
    }
    
    @include media-down(lg){
      h3 {
        strong {
          font-size: 18px;
          line-height: 1.3;
        }
        span {
          letter-spacing: .14em;
          margin-top: 12px;
          line-height: 1.5;
        }
      }
      .arrow_title {
        margin-top: 36px;
      }
      h5 {
        font-size: 18px;
        padding-bottom: 10px;
        min-width: unset;
        margin-top: 38px;
        text-align: center;
      }
      .text {
        margin-top: 25px;
        font-size: 14px;
        line-height: 1.8;
      }
      .arrow_wrap {
        margin-top: 35px;
        padding-block: 30px 25px;
        p {
          font-size: 14px;
        }
      }
      dl {
        margin-top: 36px;
        text-align: center;
        dt {
          font-size: 15px;
          font-weight: 500;
          letter-spacing: .08em;
          line-height: 1;
        }
        dd {
          margin-top: 26px;
          font-size: 20px;
          span {
            font-size: 14px;
            margin-right: 13px;
          }
        }
      }
      figure {
        margin-top: 36px;
      }
  
      &-one {
        margin-top: 60px;
      }
      &-two {
        margin-top: 50px;
        padding-top: 50px;
        position: relative;
        &::before {
          width: 100%;
          left: 0;
          transform: none;
        }
      }
      
    }
  }

  &__section {
    font-family: $font-family-base;
    margin-top: 50px;
    @include media-up(lg) {
      display: flex;
      justify-content: center;
      max-width: 100%;
      width: 960px;
      margin: 0 auto;
    }

    &--two {
      margin-top: 150px;
      gap: 35px;
      @include media-down(lg){
        margin-top: 40px;
      }
    }

    &__img {
      text-align: center;
      margin-bottom: 25px;

      @include media-up(lg) {
        width: 363px;
      }

      &--two {
        margin-top: 25px;
        margin-bottom: 35px;
        order: 2;

        @include media-up(lg) {
          width: 475px;
        }
      }
    }

    .is-animation01 {
      @include media-down(lg) {
        max-width: 237px;
        height: auto;
      }
    }
    .is-animation02 {
      @include media-down(lg) {
        max-width: 280px;
        height: auto;
      }
    }

    &__content {

      @include media-up(lg) {
        width: 413px;
      }

      &--one {

        @include media-up(lg) {
          margin-left: 60px;
          position: relative;
          top: -20px;
          left: 50px;
        }
      }

      &__title {
        font-size: min(5.87vw, 1.5rem);
        font-weight: bold;
        line-height: 40px;
        letter-spacing: 0.2em;
        margin-bottom: 20px;
        
        span{
          padding-left: 10px;
        }
      }

      &__details {
        font-size: 0.9375rem;
        line-height: 32px;
        letter-spacing: 0.12em;

        @include media-up(lg) {
          line-height: 33px;
        }
      }
    }
  }
}

.block__message {
  position: relative;
  max-width: calc(960px + calc(map-get($spacers, 3) * 2));
  width: 100%;
  margin: 75px auto 50px;
  padding: 2.8rem map-get($spacers, 3) 0;
  font-family: $font-family-base;
  font-feature-settings: "palt";

  @include media-up(lg) {
    display: flex;
    gap: 135px;
    margin-top: 85px;
    margin-bottom: 120px;
    padding: 2.8rem map-get($spacers, 3) 2.8rem;
  }

  @media (min-width: 1100px) {
    max-width: calc(960px + 90px + 90px);
    padding-right: 90px;
    padding-left: 90px;
  }

  &::before {
    content: '';
    width: 35px;
    height: 1px;
    position: absolute;
    top: 0;
    background-color: #CCD4E0;
  }

  &-content__img {
    margin-top: 50px;
    margin-bottom: 70px;
    @include media-up(lg) {
      margin-top: 70px;
      margin-bottom: 80px;
    }
  }

  &-title__img {
    margin-top: 90px;
  }

  figcaption {
    margin-top: 30px;

    h5 {
      font-size: 1.4375rem;
      font-family: $font-family-serif;
      font-weight: 500;
      text-align: center;
      line-height: 23px;
      letter-spacing: 0.2em;
      display: flex;
      flex-direction: column;
      align-items: center;

      .subtitle {
        font-size: 0.875rem;
        font-family: $font-family-base;
        font-weight: 500;
        margin-bottom: 5px;
      }
    }
  }


  .title {
    font-size: 1.25rem;
    font-family: 'Jost';
    font-weight: bold;
    letter-spacing: 0.1em;
    display: flex;
    flex-direction: column;

    @include media-up(lg) {
      width: 210px;
    }

    .subtitle {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.18rem;
      letter-spacing: 0.24em;
      margin-bottom: 1.25rem;
    }
  }

  &-content {
    margin-top: 43px;

    @include media-up(lg) {
      margin-top: 0;
    }

    @media (min-width: 1100px) {
      margin-right: -80px;
    }

    &__title {
      color: #47648D;
      font-size: min(4.95vw, 2rem);
      font-family: $font-family-serif;
      font-weight: 500;
      line-height: 36px;
      letter-spacing: 0.12em;
      margin-left: -10px;
      margin-bottom: 20px;
      @media (max-width:380px){
        letter-spacing: .1em;
      }

      @include media-up(lg) {
        line-height: 58px;
        margin-bottom: 35px;
      }
      
      span{
        padding-left: 10px;
      }
    }

    h4 {
      font-size: min(4.53vw, 1.375rem);
      font-weight: bold;
      line-height: 32px;
      letter-spacing: 0.14em;
      margin: 50px 0;
      position: relative;

      &::after {
        content: '';
        width: 20px;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: -20px;
        background-color: #4E638A;
      }
    }

    p {
      font-size: 15px;
      line-height: 32px;
      letter-spacing: 0.1em;
      margin-bottom: 33px;

      @include media-up(lg) {
        line-height: 33px;
      }
    }

    &__author {
      font-size: 0.9375rem;
      text-align: right;
      line-height: 43px;
      letter-spacing: 0.2em;

      span {
        font-size: 1.4375rem;
        font-family: 'YuMincho', sans-serif;
        font-weight: 600;
      }
    }

    figure {
      text-align: center;
    }

    &__img {
      img {
        max-width: 100%;
        height: auto;
      }
    }

    figcaption {
      margin-top: 30px;

      h5 {
        font-size: 1.4375rem;
        font-family: $font-family-serif;
        font-weight: 500;
        text-align: center;
        line-height: 23px;
        letter-spacing: 0.2em;
        display: flex;
        flex-direction: column;
        align-items: center;

        .subtitle {
          font-size: 0.875rem;
          font-family: $font-family-base;
          font-weight: 500;
          margin-bottom: 5px;
        }
      }
    }
  }
}

/**************************************/
/******* Company *********/
/**************************************/

.block__company-lead {
  font-family: $font-family-base;
  font-feature-settings: "palt";
  margin: 100px auto 0;
  padding: 0 map-get($spacers, 3);
  position: relative;
  max-width: calc(750px + calc(map-get($spacers, 3) * 2));
  .lead_ttl {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.8;
    letter-spacing: .2em;
  }
  .lead_text {
    margin-top: 45px;
    font-size: 15px;
    font-weight: 500;
    line-height: 2.1;
    letter-spacing: .12em;
  }
  
  @include media-down(lg){
    margin-top: 50px;
    .lead_ttl {
      font-size: 16px;
    }
    .lead_text {
      margin-top: 35px;
      font-size: 14px;
      line-height: 1.8;
    }

  }
}

.block__company {
  position: relative;
  font-family: $font-family-base;
  font-feature-settings: "palt";
  max-width: calc(960px + calc(map-get($spacers, 3) * 2));
  width: 100%;
  margin: 55px auto 0;
  padding: 45px map-get($spacers, 3) 0;

  @include media-up(lg) {
    display: flex;
    gap: 120px;
    padding-top: 75px;
  }

  &__table {
    list-style: none;
    margin-bottom: 40px;
    padding-top: 25px;
    padding-left: 0;

    @include media-up(lg) {
      padding-top: 0;
      margin-bottom: 65px;
    }

    tr {
      position: relative;
      border-bottom: 1px #E9E9E9 solid;
      font-size: 0.875rem;
      font-family: $font-family-base;
      @include media-down(lg) {
        display: flex;
        flex-direction: column;
      }

      &:first-child  {
        th, td {
          @include media-up(lg) {
            padding-top: 0;
          }
        }
      }

      &:last-child {
        border-bottom: none;
      }
      &:last-child:after {
        display: none;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 77px;
        height: 1px;
        background-color: #C0C1C1;
        @include media-up(lg) {
          width: 40px;
        }
      }

      th, td {
        line-height: 2;
        @include media-up(lg) {
          padding: 26px 0;
        }
        @include media-down(lg) {
          display: block;
        }
      }

      th {
        vertical-align: top;
        font-weight: $font-weight-bold;
        letter-spacing: .34em;
        @include media-up(lg) {
          min-width: 146px;
        }
        @include media-down(lg) {
          padding-top: 16px;
        }
      }

      td {
        letter-spacing: .11em;
        @include media-down(lg) {
          padding-bottom: 16px;
        }
      }
    }
  }
}

.block__extra-info {
  font-family: $font-family-base;
  font-feature-settings: "palt";
  margin: 40px auto 0;
  padding: 0 map-get($spacers, 3);
  position: relative;
  max-width: calc(960px + calc(map-get($spacers, 3) * 2));
  .info_list {
    display: flex;
    flex-direction: column;
    gap: 40px 0;
    margin: 0;
    padding: 0;
    list-style: none;
    @include media-up(lg) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto;
      gap: 80px 85px;
    }
    > li {
      @include media-up(lg) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      }
      @include media-down(lg) {
        max-width: 440px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
      .name {
        display: block;
        width: 100%;
        margin-bottom: 28px;
        padding-bottom: 20px;
        border-bottom: 1px solid #E0E3EA;
        font-family: $font-family-serif;
        color: #627597;
        font-size: calc(20 / 16 * $font-size-base);
        letter-spacing: .14em;
        text-indent: 0.14em;
        text-align: center;
      }
      .desc {
        display: block;
        margin-bottom: 15px;
        font-size: calc(15 / 16 * $font-size-base);
        letter-spacing: .16em;
        text-indent: 0.16em;
        text-align: center;
      }
      .data {
        margin-bottom: 0.5em;
        font-family: 'Roboto', $font-family-base;
        font-weight: $font-weight-bold;
        font-size: calc(31 / 16 * $font-size-base);
        line-height: 1;
        letter-spacing: .12em;
        text-indent: 0.12em;
        text-align: center;
        strong {
          font-weight: $font-weight-medium;
          font-size: calc(46 / 16 * $font-size-base);
        }
      }
      >strong {
        display: block;
        margin-bottom: 1em;
        font-weight: $font-weight-bold;
        font-size: calc( 15 / 16 * $font-size-base);
        letter-spacing: .16em;
        text-indent: 0.16em;
        text-align: center;
      }
      >p {
        font-size: $font-size-sm;
        line-height: 1.85;
        letter-spacing: .14em;
        @include media-up(lg) {
          margin-top: 28px;
        }
      }
      >small {
        font-size: $font-size-xs;
        letter-spacing: .16em;
        text-indent: 0.16em;
      }
      figure {
        margin: 8px 0 0;
        @include media-down(lg){
          margin-bottom: 15px;
        }
        img {
          margin: 0;
        }
      }
  
      .img {
        margin-top: auto;
      }
  
      img {
        max-width: 100%;
        margin-top: 2.5em;
        height: auto;
      }
  
      >ul {
        column-count: 2;
        padding: 0;
        list-style: none;
        color: #536387;
        @include media-up(lg) {
          margin-top: 28px;
        }
        &.list-sm-column1 {
          @include media-down(sm) {
            column-count: 1;
          }
        }
        li {
          position: relative;
          padding-left: 1em;
          break-inside: avoid;
          margin-bottom: 10px;
          font-weight: $font-weight-bold;
          font-size: calc(13 / 16 * $font-size-base);
          letter-spacing: .16em;
          &:before {
            content: '';
            position: absolute;
            top: .5em;
            left: 0;
            display: inline-block;
            width: 6px;
            height: 6px;
            margin: 0 .5em auto 0;
            border-radius: 100em;
            background-color: #CED4DF;
          }
          &.etc {
            font-family: $font-family-base;
            &:before {
              display: none;
            }
          }
        }
      }
  
      .num_list {
        column-count: 1;
        width: min(100%, 280px);
        margin: 15px auto 0;
        li {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}

.block__map {
  max-width: calc(960px + calc(map-get($spacers, 3) * 2));
  width: 100%;
  margin: 0 auto;
  padding: 0 map-get($spacers, 3);
  @include media-down(lg){
    margin-top: 65px;
  }
  iframe{
    width: 100%;
  }
}

.plate_img {
  max-width: calc(750px + calc(map-get($spacers, 3) * 2));
  padding: 0 map-get($spacers, 3);
  margin: 68px auto 0;
  @include media-up(lg){
    margin-top: 56px;
  }
  img {
    max-width: 100%;
    height: auto;
  }
}

.block__office {
  font-family: $font-family-base;
  font-feature-settings: "palt";
  margin: 60px 0 50px 0;
  padding: 110px map-get($spacers, 3) 70px;
  position: relative;
  background-color: #F8F8F8;

  @include media-up(lg) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 150px;
    padding-bottom: 100px;
    align-items: center;
  }

  &-container {
    max-width: calc(960px + calc(map-get($spacers, 3) * 2));
    width: 100%;
    margin: 0 auto;
  }

  &-content {
    margin-top: 58px;
    

    @include media-up(lg) {
      margin-top: 35px;
      display: grid;
      grid-template-columns: 278px 1fr;
      gap: 70px;
    }

    &:not(:first-child) {

      @include media-up(lg) {
        margin-top: 50px;
      }
    }

    &__img {
      @include media-up(lg) {
        width: 278px;
      }

      img {
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }

    .subblock {

      @include media-up(lg) {
        margin-top: 0;
        padding-bottom: 50px;
        border-bottom: 1px #E1E1E1 solid;
      }

      .title {

        @include media-up(lg) {
          line-height: 20px;
          margin-bottom: 25px;
        }
      }

      .indonesia {

        @include media-up(lg) {
          border: none;
        }

        .link-google-map {
          @include media-up(lg) {
            margin-top: 30px;
          }
        }
      }
    }

    .contact {
      list-style: none;
      padding: 0;

      li {
        font-size: 0.8125rem;
        font-family: $font-family-base;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.15em;
        margin-bottom: 8px;
        display: flex;
        gap: 30px;

        span:first-child {
          width: 40px;
        }
      }
    }

    .access {
      font-size: 0.8125rem;
      font-family: $font-family-base;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.15em;
      margin-top: 24px;

      &-title {
        margin-bottom: 5px;
      }

      &-details {
        line-height: 26px;
      }

      .link-google-map {

        @include media-up(lg) {
          margin-top: 15px;
          justify-content: flex-start;
        }
      }
    }
  }
}

.block__history {
  padding: 60px map-get($spacers, 3) 0;
  @include media-up(lg) {
    max-width: calc(960px + calc(map-get($spacers, 3) * 2));
    width: 100%;
    margin: 0 auto;
    padding: 75px map-get($spacers, 3) 0;
  }

  &__content {
    .history-list {
      list-style: none;
      margin-top: 40px;
      position: relative;

      @include media-up(lg) {
        padding-left: 0;
      }

      &::after {
        content: '';
        width: 1px;
        height: 100%;
        position: absolute;
        top: 15px;
        left: 0;
        background-color: #E1E1E1;

        @include media-up(lg) {
          height: 96.5%;
          left: 100px;
        }
      }

      &-item {

        @include media-up(lg) {
          display: grid;
          grid-template-columns: 60px 1fr;
          gap: 85px;
        }
      }

      &-item:not(:last-child) {
        margin-bottom: 24px;
      }

      .date {
        color: #4E638A;
        font-size: 1.1875rem;
        font-family: 'Jost', sans-serif;
        font-weight: 600;
        line-height: 34px;
        letter-spacing: 0.12em;
        position: relative;

        &::before {
          content: '';
          width: 7.5px;
          height: 7.5px;
          position: absolute;
          top: 50%;
          left: -35px;
          transform: translateY(-50%);
          background-color: #E1E1E1;
          border-radius: 50%;

          @include media-up(lg) {
            width: 6px;
            height: 6px;
            top: 15px;
            left: 98px;
          }
        }
      }

      .details-title {
        font-size: 1rem;
        font-family: $font-family-base;
        font-weight: bold;
        line-height: 28px;
        letter-spacing: 0.15em;
        margin-bottom: 0;
      }

      .details-content {
        font-size: 0.8125rem;
        font-family: $font-family-base;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0.15em;
        margin-bottom: 0;
      }
    }
  }

  &__img {
    margin-top: 260px;
    h3 {
      text-align: center;
      strong, span {
        display: block;
        line-height: 1;
      }
      strong {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: .2em;
      }
      span {
        margin-top: 23px;
        font-size: 15px;
        font-weight: 700;
        letter-spacing: .24em;
      }
    }

    .img_list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 40px 45px;
      margin: 60px auto 0;
      max-width: 950px;
      padding: 0;
      list-style: none;
      img {
        max-width: 100%;
        height: auto;
      }
      h4 {
        text-align: center;
        margin-top: 22px;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: .14em;
        color: #566384;
      }
      p {
        margin-top: 10px;
        font-size: 13px;
        font-weight: 500;
        line-height: 1.8;
        letter-spacing: .08em;
      }
    }
    
    @include media-down(lg){
      margin-top: 80px;
      h3 {
        strong {
          letter-spacing: .12em;
        }
        span {
          margin-top: 17px;
          letter-spacing: .14em;
        }
      }

      .img_list {
        grid-template-columns: repeat(2, 1fr);
        gap: 12px 16px;
        margin-top: 40px;
        h4 {
          margin-top: 15px;
          font-size: 15px;
        }
        p {
          font-size: 13px;
          line-height: 1.7;
        }
      }
    }
  }
}

.block__support {
  max-width: calc(960px + calc(map-get($spacers, 3) * 2));
  width: 100%;
  margin: 20px auto 50px;
  padding: 100px map-get($spacers, 3) 0;
  font-feature-settings: "palt";

  @include media-up(lg) {
    margin-top: 70px;
    display: grid;
    grid-template-columns: 1fr 576px;
  }

  &-content {
    &__details {
      p {
        letter-spacing: .15em;
        font-weight: bold;
        line-height: 1.8;
      }
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.block__links__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  @include media-up(sm) {
    flex-direction: row;
    justify-content: center;
    gap: 25px;
  }

  .btn-link {
    color: #4E638A;
    width: 310px;
    height: 60px;
    font-size: 0.9375rem;
    font-family: $font-family-base;
    font-weight: bold;
    line-height: 31px;
    letter-spacing: 0.28em;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
    border: 1px #CAD0DC solid;
    border-radius: 3px;

    @include media-up(lg) {
      max-width: 466px;
      width: 100%;
      height: 87px;
    }

    &:hover,
    &--active {
      color: white;
      text-decoration: none;
      background-color: #4E638A;
      border: 1px #4E638A solid;
    }
  }
}

/**************************************/
/******* Letter *********/
/**************************************/

.block__letter {
  &-lead {
    max-width: calc(960px + calc(map-get($spacers, 3) * 2));
    width: 100%;
    padding: 0 map-get($spacers, 3);
    font-feature-settings: "palt";
    margin: 72px auto 0;
    .lead_text {
      font-weight: 700;
      text-align: center;
      font-size: 16px;
      line-height: 2.1;
      letter-spacing: .1em
    }
    .letter_ttl {
      @include vertical-title;
      margin-top: 40px;
    }
    .unit {
      @include media-up(lg){
        display: grid;
        grid-template-columns: 190px 1fr;
        column-gap: 63px;
        margin: 25px 0 0 auto;
        max-width: 885px;
        row-gap: 35px;
        h4 {
          grid-column: 2 / 3;
          grid-row: 1 / 2;
        }
        figure {
          grid-column: 1 / 2;
          grid-row: 2 / 3;
        }
        p {
          grid-column: 2 / 3;
          grid-row: 2 / 3;
        }
      }
      figure {
        img {
          max-width: 100%;
        }
        figcaption {
          font-size: 13px;
          letter-spacing: .18em;
          font-weight: 500;
          line-height: 1;
          margin-top: 20px;
          margin-left: 10px;
        }
      }
      h4 {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: .2em;
        line-height: 1;
      }
      p {
        font-size: 15px;
        font-weight: 500;
        line-height: 2.1;
        letter-spacing: .12em;
      }
    }
  }

  &-content {
    max-width: calc(750px + calc(map-get($spacers, 3) * 2));
    width: 100%;
    margin: 90px auto 170px;
    padding: 0 map-get($spacers, 3);
    font-feature-settings: "palt";
    .letter_list {
      margin-top: 68px;
    }
    .letter_item {
      &:first-of-type {
        margin-bottom: 70px;
      }
      + .letter_item {
        margin-top: 50px;
      }
    }
    .letter_ttl {
      @include bottom-border-title;
    }
    .letter_text {
      margin-top: 30px;
      font-size: 15px;
      font-weight: 500;
      line-height: 2.1;
      letter-spacing: .12em;
    }
    .info_list {
      margin-top: 57px;
      @include media-up(lg){
        display: grid;
        grid-template-columns: 450px 1fr;
        row-gap: 15px;
      }
      dl {
        display: flex;
        align-items: center;
        column-gap: 15px;
        margin: 0;
      }
      dt {
        display: grid;
        place-content: center;
        width: 150px;
        height: 34px;
        background-color: #627597;
        color: #fff;
        border-radius: 3px;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: .14em;
      }
      dd {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: .14em;
        margin: 0;
        small {
          font-size: 11px;
          font-weight: 700;
        }
      }
    }

    .letter_media {
      margin-top: 86px;
      .media_ttl {
        @include arrow-title;
      }
      .media_text {
        margin-top: 50px;
        line-height: 2.1;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: .12em;
      }
    }
  }

  @include media-down(lg){
    &-lead {
      margin-top: 80px;
      .lead_text {
        font-size: 15px;
        line-height: 1.8;
      }
      .letter_ttl {
        margin-top: 20px;
      }
      .unit {
        margin-top: 20px;
        figure {
          width: 60%;
          margin: auto;
          figcaption {
            margin-top: 1px;
          }
        }
        h4 {
          font-size: 18px;
          margin-top: 30px;
        }
        p {
          line-height: 1.8;
          margin-top: 25px;
        }
      }
    }
  
    &-content {
      margin-block: 50px 74px;
      .letter_list {
        margin-top: 50px;
      }
      .letter_item {
        &:first-of-type {
          margin-bottom: 50px;
        }
        + .letter_item {
          margin-top: 35px;
        }
      }
      .letter_text {
        margin-top: 18px;
        line-height: 1.8;
      }
      .info_list {
        margin-top: 18px;
        dl {
          + dl {
            margin-top: 10px;
          }
          dt {
            width: 140px;
            height: 30px;
            font-size: 13px;
          }
          small {
            display: block;
          }
        }
      }
  
      .letter_media {
        margin-top: 63px;
        .media_text {
          margin-top: 27px;
          line-height: 1.8;
          font-size: 15px;
        }
      }
    }
  }
}