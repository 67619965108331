@keyframes up_down_line {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
}

@keyframes active_btn_line {
  0% {
    height: 107px;
  }
  30% {
    height: 107px;
  }
  60% {
    height: 7px;
  }
  100% {
    height: 107px;
  }
}


.rwd002-pagetop {
  font-size: $font-size-xxs;
  @include media-up(lg) {
    position: absolute;
    width: 50px;
    height: 160px;
    right: 0;
  }
  @include media-down(lg) {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 36px;
    margin: 0 auto 22px;
    font-family: "Roboto", $font-family-sans-serif;
    font-weight: $font-weight-medium;
    font-size: calc(11 / 16 * $font-size-base);
    letter-spacing: .18em;
    text-indent: 0.18em;
    color: $body-color;
    text-decoration: none;
    letter-spacing: 0.1em;
    transform-origin: bottom;
  }
  &:before {
    @include media-down(lg) {
      content: '';
      display: block;
      margin-bottom: .5em;
      width: 17px;
      height: 16px;
      background: svgUrlFunc('<svg viewBox="0 0 17 16.4" xmlns="http://www.w3.org/2000/svg"><path d="m16.87 8.88-8.01-8.72s0 0 0 0c-.09-.1-.22-.16-.36-.16s-.27.06-.36.15l-8.01 8.73c-.19.2-.17.52.03.7.2.19.52.17.7-.03l7.14-7.78v14.13c0 .27.22.5.5.5s.5-.22.5-.5v-14.13l7.14 7.78c.1.11.23.16.37.16.12 0 .24-.04.34-.13.2-.19.22-.5.03-.7z" fill="#{$body-color}"/></svg>') no-repeat center / contain;
    }
  }

  > span {
    @include media-up(lg) {
      display: block;
      transform: rotate(90deg) translateY(5%) translateX(40%);
      letter-spacing: .24em;
      color: #005FAA;
    }
  }

  .is-pagetop-line {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    width: 1px;
    height: 107px;
    margin: auto;
    background: #CED4DF;
    z-index: 1;
    @include media-down(lg) {
      display: none;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      width: 1px;
      height: 6px;
      background: #0057A8;
      animation: active_btn_line 2s infinite ease-in-out;
    }
  }
  &:hover,
  &:focus {
    text-decoration: none;
    &:before {
      animation: up_down_line 0.9s infinite ease-in-out;
    }
  }
  &__wrap {
    width: 100%;
    z-index: 10;
    transition: $transition-base;
    z-index: 1;
    @include media-up(lg) {
      position: fixed;
      position: sticky;
      bottom: 180px;
      margin: 0 auto;
      padding-left: map-get($spacers, 1);
      padding-right: map-get($spacers, 1);
    }
    @include media-down(lg) {
      position: relative;
    }
    &.hidden {
      @include media-up(lg) {
        transform: translateY(200px);
        opacity: 0;
      }
    }
  }
}

.footer-contact {
  &__inner {
    position: relative;
    padding: 0 50px 0 0;
    z-index: 0;
    @include media-down(lg) {
      padding: 0;
    }
  }
  &__banners {
    display: flex;
    width: 100%;
    color: $white;
    row-gap: map-get($spacers, 3);
    @include media-down(lg) {
      flex-direction: column;
      row-gap: 0;
      max-width: 100%;
      margin: 0;
      padding: 0;
    }
  }
  .banner {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 1fr/1fr;
    grid-template-rows: 1fr/1fr;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    backface-visibility:hidden;
    &:hover .banner-img{
      transform: scale(1.075);
    }
    &-img {
      grid-column: 1/2;
      grid-row: 1/2;
      width: 100%;
      height: auto;
      transition: transform .3s ease-in-out;
      .sp {
        display: none;
      }
      @include media-down(md) {
        max-width: 100%;
        .pc {
          display: none;
        }
        .sp {
          display: block;
        }
      }
      
    }
    &-body {
      grid-column: 1/2;
      grid-row: 1/2;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      padding: 1em 1em 22px 22px;
      width: 100%;
      font-size: min(4.53vw, calc(22 / 16 * $font-size-base));
      font-feature-settings: "palt";
      z-index: 1;
      @include media-up(lg) {
        padding-left: 78px;
        padding-bottom: 55px;
      }
    }
    .title {
      position: relative;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      margin-bottom: 0;
      font-family: 'Cantarell', $font-family-base;
      line-height: 1;
      font-weight: $font-weight-normal;
      font-size: calc(36 / 16 * $font-size-base);
      letter-spacing: 0.14em;
      @include media-down(lg) {
        font-size: calc(30 / 16 * $font-size-base);
      }
      small {
        margin-bottom: .5em;
        font-size: $font-size-sm;
        letter-spacing: .34em;
        @include media-up(lg) {
          margin-bottom: 1em;
        }
      }
      &:after {
        content: "";
        position: absolute;
        bottom: .3em;
        left: calc(100% + .5em);
        width: 18px;
        height: 5px;
        background: center / contain no-repeat;
        background-image: svg-right-arrow-lg($white);
        @include media-up(lg) {
          left: calc(100% + .75em);
        }
      }
    }
  }
}

.recruit-footer-contact {
  &__inner {
    position: relative;
    padding: 0 50px 0 0;
    z-index: 0;
    @include media-down(lg) {
      padding: 0;
    }
  }
  &__banners {
    display: flex;
    width: 100%;
    color: $white;
    row-gap: map-get($spacers, 3);
    @include media-down(lg) {
      flex-direction: column;
      row-gap: 0;
      max-width: 100%;
      margin: 0;
      padding: 0;
    }
  }
  .banner {
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    text-decoration: none;
    backface-visibility:hidden;
    transition: $transition-base;
    @include media-up(lg) {
      width: 50%;
    }
    .title {
      display: flex;
      align-items: center;
      margin: 0;
      .icon {
        fill: currentColor;
        margin-left: 0.25em;
      }
    }

    &.is-requirements {
      display: grid;
      grid-template-columns: 1fr/1fr;
      grid-template-rows: 1fr/1fr;
      color: $white;

      &:hover .banner-img,
      &:focus .banner-img {
        transform: scale(1.075);
      }

      .title {
        position: relative;
        justify-content: center;
        line-height: 1;
        font-weight: $font-weight-bold;
        font-size: $font-size-lg;
        letter-spacing: 0.14em;
        @include media-up(lg) {
          font-size: calc(24 / 16 * $font-size-base);
        }
      }

      .banner-img {
        grid-column: 1/2;
        grid-row: 1/2;
        width: 100%;
        height: auto;
        transition: transform .3s ease-in-out;
        @include media-down(md) {
          max-width: 100%;
        }
      }

      .banner-body {
        grid-column: 1/2;
        grid-row: 1/2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 1em;
        font-feature-settings: "palt";
        z-index: 1;
      }
    }

    &.is-entry {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      background-color: $tertiary;
      font-family: 'Jost', $font-family-base;
      font-weight: $font-weight-medium;
      color: $black;
      letter-spacing: .2em;
      text-indent: 0.2em;
      @include media-down(lg) {
        aspect-ratio: 375/138;
      }
      &:hover, &:focus {
        background-color: #0057A8;
        color: $white;
      }
      .title {
        display: flex;
        align-items: center;
        flex-direction: row;
        color: inherit;
      }
    }
  }
}

.rwd002-footer {
  position: relative;
  background-color: #1E3065;
  color: $white;
  @include media-up(sm) {
    padding: 75px map-get($spacers, 4) 75px;
  }
  @include media-down(sm) {
    padding: 50px map-get($spacers, 3) map-get($spacers, 2);
  }

  &__inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 1100px;
    margin: 0 auto;
    @include media-up(lg) {
      min-height: 300px;
    }
    @include media-down(lg) {
      align-items: stretch;
    }
  }

  &__info {
    margin-bottom: 2em;
    @include media-up(lg) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
    }
  }

  &__tel {
    display: flex;
    flex-direction: column;
    font-size: calc(25 / 16 * $font-size-base);
    @include media-up(lg) {
      padding-left: 1.1em;
      margin: 0 min(1.75em, 4.72vw) 0 min(1.55em, 5.6vw);
    }
    @include media-down(lg) {
      text-align: center;
      margin: 0 0 0.6em;
    }
    .tel {
      display: block;
      font-family: 'Jost', $font-family-base;
      letter-spacing: .16em;
      @include media-up(lg) {
        text-indent: -1.1em;
        pointer-events: none;
      }
      &:before {
        content: '';
        display: inline-block;
        width: 1.1em;
        height: 0.75em;
        margin-top: 0.1em;
        background: svgUrlFunc('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fill="#{$white}" d="M10,0C4.48,0,0,4.48,0,10s4.48,10,10,10,10-4.48,10-10S15.52,0,10,0Zm3.8,12.93s-.06,.06-.63,.63c-.67,.67-3.13-.61-4.5-1.99-1.37-1.37-2.66-3.83-1.99-4.5,.58-.58,.63-.63,.63-.63,.15-.15,.39-.16,.53-.02l.97,.97c.14,.14,.13,.38-.02,.53l-.39,.39c-.14,.14-.16,.35-.05,.5,0,0,.13,.9,1.18,1.95,1.07,1.07,1.92,1.15,1.92,1.15,.14,.11,.36,.09,.5-.05l.39-.39c.15-.15,.39-.16,.53-.02l.97,.97c.14,.14,.13,.38-.02,.53Z"/></svg>') left center / contain no-repeat;
      }
    }
    .opentime {
      font-size: calc(10 / 16 * $font-size-base);
      letter-spacing: 0.19em;
      font-feature-settings: "palt";
    }
  }

  &__sns {
    @include media-up(lg) {
      display: flex;
      gap: 0 10px;
    }
    @include media-down(lg) {
      text-align: center;
    }
    .btn-link {
      position: relative;
      display: inline-flex;
      align-items: center;
      font-family: 'Manrope', $font-family-base;
      font-size: calc(12 / 16 * $font-size-base);
      font-weight: $font-weight-medium;
      letter-spacing: .16em;


      &:hover, &:focus {
        text-decoration: none;
        span:after {
          transform: translateX(4px);
        }
      }

      .icon {
        margin-right: 0.5em;
      }
      &.is-instagram .icon {
        font-size: calc(18 / 16 * $font-size-base);
      }
      &.is-youtube .icon {
        font-size: calc(22 / 16 * $font-size-base);
      }

      span:after {
        content: '';
        display: inline-block;
        width: 18px;
        height: 5px;
        margin-left: 0.2em;
        margin-bottom: 0.2em;
        background: center / contain no-repeat;
        background-image: svg-right-arrow-lg($white);
        transition: transform .2s ease-in-out;
      }
    }
  }

  &__companyName {
    font-size: $font-size-base * 1.375;
    font-weight: $font-weight-bold;
    letter-spacing: 0.2em;
    font-feature-settings: "palt";
    text-align: center;
    @include media-up(lg) {
      margin-bottom: 0;
      text-align: left;
    }
    
  }

  &__address-list {
    display: flex;
    width: 100%;
    @include media-up(lg) {
      display: grid;
      grid-template-columns: auto min(42%, 32vw) auto;
      grid-template-rows: auto;
    }
    @include media-down(lg) {
      flex-direction: column;
    }
  }

  &__address {
    position: relative;
    margin-bottom: 0;
    padding: 25px 0;
    font-size: $font-size-xs;
    line-height: 1.9;
    font-feature-settings: "palt";
    text-align: center;
    @include media-up(lg) {
      display: flex;
      justify-content: center;
      padding: 12px map-get($spacers, 3);
      text-align: left;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: block;
      width: 100%;
      height: 1px;
      margin: auto;
      background-color: rgba($white, .4);
      @include media-up(lg) {
        top: 0;
        bottom: 0;
        left: 0;
        right: auto;
        width: 1px;
        height: 100%;
      }
    }
    &:first-child {
      @include media-up(lg) {
        justify-content: flex-start;
        padding-left: 0;
      }
    }
    &:first-child:before {
      @include media-up(lg) {
        display: none;
      }
    }
    &:last-child {
      @include media-up(lg) {
        justify-content: flex-end;
        padding-right: 0;
      }
      @include media-down(lg) {
        margin-bottom: 0;
      }
    }
    .title {
      display: block;
      font-weight: $font-weight-normal;
      font-size: calc(14 / 16 * $font-size-base);
      margin-bottom: 15px;
      letter-spacing: .21em;
      @include media-up(lg) {
        margin-bottom: 20px;
      }
    }
    .addr {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      letter-spacing: .21em;
      @include media-up(lg) {
        max-width: 250px;
        box-decoration-break: clone;
      }
    }
    .btnwrap {
      display: flex;
      flex-wrap: wrap;
      gap: 8px 4px;
      max-width: 208px;
      margin: 20px auto 0;
      @include media-up(lg) {
        margin: 1em 0 0;
      }
      .btn {
        flex-grow: 1;
        width: calc(50% - 2px);
        font-family: 'Jost', $font-family-base;
        font-weight: $font-weight-medium;
        font-size: $font-size-xs;
        letter-spacing: .16em;
        text-indent: 0.16em;
        .icon {
          margin-right: 0.4em;
          color: rgba($white, .8);
          transition: $transition-base;
        }
        &:hover, &:focus {
          color: #1E3065;
          .icon {
            color: #1E3065;
          }
        }
      }
      .btn.is-google-map {
        width: 100%;
      }
    }
  }

  &__bottom {
    @include media-up(lg) {
      position: absolute;
      right: 40px;
      bottom: 30px;
      display: flex;
    }
  }
  &__copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 24px;
    @include media-up(lg) {
      position: absolute;
      top: 0; bottom: 0;
      left: auto; right: 0;
      width: 50px;
      height: 100%;
      margin: 0 auto 0 0;
      writing-mode: vertical-rl;
      font-feature-settings: "pkna";
    }
    small {
      font-family: 'Jost', $font-family-base;
      font-size: $font-size-xxs;
      color: rgba(#CED4DF, 0.6);
      letter-spacing: 0.24em;
      text-indent: 0.24em;
    }
    @include media-down(md) {
      margin-left: -1.3em;
      margin-right: -1.3em;
    }
  }
  &__login {
    @include media-down(lg) {
      position: absolute;
      bottom: 6px;
      right: 60px;
    }
    .icon {
      fill: rgba($white, .6);
    }
  }
}

.footer-switchNav {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  margin: 30px auto 30px;
  @include media-up(lg) {
    flex-direction: row;
    margin: 42px auto 0;
  }
  @include media-down(lg) {
    align-items: center;
    width: 100%;
  }
  .btn {
    @extend .btn-icon-right-arrow-white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    width: 270px;
    height: 50px;
    font-weight: $font-weight-normal;
    font-size: calc(13 / 16 * $font-size-base);
    letter-spacing: .2em;
    text-indent: 0.2em;
    &:hover, &:focus {
      color: #1E3065;
      &:after {
        background: center / contain no-repeat;
        background-image: svg-right-arrow(#1E3065);
      }
    }
  }

}

.rwd002-footerNav {
  margin-top: 15px;
  @include media-up(lg) {
    margin-top: 50px;
  }
  @include media-down(lg) {
    margin-left: -1em;
    margin-right: -1em;
  }
  &__list,
  &__sublist {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    @include media-down(lg) {
      justify-content: center;
      text-align: center;
    }
  }
  &__list {
    font-size: $font-size-base * 0.9375;
    color: darken($white, 20%);
    @include media-up(md) {
      column-count: 2;
      column-gap: 1em;
    }
    @include media-up(lg) {
      grid-column: 1;
      grid-row: 1 / span 2;
      column-gap: 0;
    }
    @include media-down(lg) {
      gap: 10px 0;
    }
    @include media-down(md) {
      flex-grow: 1;
      margin-bottom: 1.5em;
    }
  }
  &__sublist {
    font-family: 'Roboto', $font-family-base;
    color: rgba($white, .6);
    font-weight: $font-weight-normal;
    font-size: $font-size-xs;
    @include media-up(lg) {
      grid-column: 2;
      grid-row: 1;
    }
    @include media-down(lg) {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.rwd002-footerNav-item {
  display: flex;
  flex-wrap: wrap;
  @include media-down(md) {
    position: relative;
  }
  &__link {
    position: relative;
    display: inline-block;
    padding: 0.3em 1em;
    font-size: $font-size-xs;
    letter-spacing: 0.2em;
    font-feature-settings: "palt";
    font-weight: $font-weight-bold;
    text-decoration: none;
    @include media-up(lg) {
      padding: 0.3em 1.2em;
    }
    &:hover, &:focus {
      opacity: 0.6;
      color: $white;
      text-decoration: none;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      display: block;
      width: 1px;
      height: 1em;
      margin: auto;
      background-color: rgba(#87A5CF, 0.8);
      transform: skewX(-25deg);
    }
  }

  &:first-child &__link::before {
    display: none;
  }

}

.rwd002-footerNav-subitem {
  &__link {
    position: relative;
    display: inline-block;
    padding: 0.3em 1em;
    letter-spacing: 0.16em;
    transition: $transition-base;
    &:hover,
    &:focus {
      color: $white;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      display: block;
      width: 1px;
      height: 1em;
      margin: auto;
      background-color: rgba(#87A5CF, 0.8);
      transform: skewX(-25deg);
    }
  }

  &:first-child &__link::before {
    display: none;
  }
}

.rwd002-footer-subnav {
  @include media-up(md) {
    display: none;
  }
  &__tgl {
    @extend .btn;

    &:focus {
      box-shadow: none;
    }

    @include media-up(md) {
      display: none;
    }
    @include media-down(md) {
      position: relative;
      flex-basis: 40px;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: calc(50% - 5px);
        width: 10px;
        height: 1px;
        background-color: darken($white, 50%);
        transition: transform 0.2s ease-in-out;
      }

      &:after {
        transform: rotate(-90deg);
      }

      &.is-active {
        &:before {
          transform: rotate(180deg);
        }
        &:after {
          transform: rotate(0deg);
        }
      }
    }
  }
  &__list {
    list-style: none;
    @include media-down(md) {
      padding-left: 1.7em;
      padding-bottom: 1em;
    }
  }
}

.rwd002-footer-subnav-item {
  &__link {
    @include media-down(md) {
      display: block;
      padding: 0.2em 0;
    }
  }
}

.rwd002-footer-search {
  margin-top: 2em;
  @include media-down(lg) {
    width: 100%;
    margin-top: 1.5em;
    margin-bottom: 3em;
  }
  &__inner {
    position: relative;
    max-width: 320px;
    @include media-down(md) {
      margin-left: -0.8em;
      margin-right: -0.8em;
    }
  }
  &__input {
    @extend .form-control;
    padding-right: 2.2em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    background-color: tint($dark, 10%);
    transition: $transition-base;
    border-width: 0;
  }
  &__btn {
    @extend .btn;
    position: absolute;
    top: 0;
    bottom: 0;
    right: calc(100% - 2.2em);
    width: 2.2em;
    padding: 0 0.5em;
    pointer-events: none;
    transition: $transition-base;
    > svg {
      width: 1em;
      height: 100%;
      fill: tint($dark, 30%);
    }
  }
  &__btn:focus,
  &__input:focus + &__btn {
    pointer-events: auto;
    right: 0;
  }
}
