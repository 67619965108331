.recruit-comic {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 960px;
  margin: 0 auto;
  &__title {
    @include c-recruit-title();
    margin-bottom: 34px;
    @include media-up(lg) {
      margin-bottom: 44px;
    }
    small {
      @include media-down(lg) {
        color: $white;
      }
    }
  }

  &__balloon {
    position: relative;
    width: fit-content;
    margin: 0 auto 10px;
    padding: 0 15px 10px;
    font-weight: $font-weight-bold;
    font-size: 0.75rem;
    letter-spacing: 0.22em;
    line-height: 15px;
    @include media-down(lg) {
      color: $white;
    }

    &::before {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      left: 50%;
      top: 100%;
      transform: translateX(-50%);
      border-left: 4.5px solid transparent;
      border-right: 4.5px solid transparent;
      border-top: 5px solid #CFD4DE;
    }

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: #CFD4DE;
    }
  }

  &__row {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 66px;
    margin: 38px auto 30px;
    padding: 0 2rem;
    @include media-up(lg) {
      flex-direction: row;
      gap: 78px;
      margin: 45px auto 30px;
    }
  }

  &__modal {
    img {
      max-width: 100%;
      height: auto;
    }

    img + img {
      margin-top: 8px;
    }

    .modal-header {
      padding: 0 0.5em;
      @include media-up(lg) {
        padding: .5em 0.5em;
      }
      .btn-close {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: calc(20 / 16 * $font-size-base);
        margin: 0 0 0 auto;
        color: $primary;
        padding: .5em;
      }
    }
    .modal-dialog {
      @include media-up(lg) {
        max-width: 55%;
      }
    }
    .modal-body {
      text-align: center;
    }
  }
}

.recruit-comic-item {
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  box-shadow: 15px 15px 15px -10px rgba($black, 0.2);
  &:hover, &:focus {
    img { opacity: 0.8; }
    .icon { transform: scale(1.1); }
  }

  &__img {
    margin: 0;
    img {
      transition: $transition-base;
      max-width: 100%;
      height: auto;
    }
  }

  .icon {
    position: absolute;
    bottom: -30px;
    right: -15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 84px;
    height: 84px;
    background-color: $white;
    border-radius: 100em;
    box-shadow: 0px 0px 20px -3px rgba($black, 0.2);
    transition: $transition-base;
    @include media-up(lg) {
      bottom: -16px;
      right: -24px;
    }
  }
}