.recruit-data {
  max-width: 100%;
  width: calc(340px + calc(map-get($spacers, 2) * 2));
  margin: 0 auto;
  padding: 0 map-get($spacers, 2);
  font-feature-settings: "palt";
  @include media-up(lg) {
    width: calc(1000px + calc(map-get($spacers, 2) * 2));
  }
  &__title {
    @include c-recruit-title();
    margin-bottom: 34px;
    @include media-up(lg) {
      margin-bottom: 44px;
    }
  }

  &__grid {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: auto auto auto;
    @include media-down(lg) {
      grid-template-columns: 1fr 1fr;
      gap: 8px 9px;
    }
  }

  &__item {
    background-color: #EFF2F6;
    padding: map-get($spacers, 3) map-get($spacers, 2);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #0057A8;
    row-gap: 0.3em;
    column-gap: 15%;
    font-size: min(9vw, calc(34 / 16 * $font-size-base));
    font-feature-settings: "palt";
    font-family: 'Roboto', $font-family-base;
    @include media-up(lg) {
      font-size: min(9vw, calc(39 / 16 * $font-size-base));
    }

    .name {
      display: flex;
      flex-direction: column;
      gap: .22em;
      font-weight: $font-weight-bold;
      font-size: 0.41em;
      color: $black;
      letter-spacing: 0.14em;
      text-indent: 0.14em;
      text-align: center;
      &:after {
        content: '';
        display: inline-block;
        width: 1.5em;
        height: 2px;
        margin: 0 auto;
        background-color: #869CBB;
      }
    }

    p {
      margin: 0;
      line-height: 1;
    }

    span {
      font-family: 'Roboto', $font-family-base;
      font-weight: $font-weight-medium;
      letter-spacing: .06em;
    }
    small{
      font-size: 0.4em;
      font-weight: $font-weight-bold;
      letter-spacing: 0.06em;
      text-indent: 0.06em;
    }
    em {
      font-style: normal;
      font-size: .4em;
      font-weight: $font-weight-bold;
      letter-spacing: 0.06em;
      text-indent: 0.06em;
    }
    .icon{
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  &__item.is-data01 {
    grid-column: 1/span 2;
    grid-row: 1;
    justify-content: center;
    @include media-up(lg) {
      grid-column: 1/span 2;
      grid-row: 1/span 2;
    }
    .name {
      font-size: 0.692em;
    }
    span {
      font-size: 1.8em;
      @include media-up(lg) {
        font-size: 2.05em;
      }
    }
    small {
      font-size: 1em;
    }
  }

  &__item.is-data02 {
    grid-column: 1/span 2;
    grid-row: 2;
    @include media-up(lg) {
      grid-column: 3/span 2;
      grid-row: 1;
    }
  }

  &__item.is-data07 {
    @include media-down(lg) {
      grid-column: 1/span 2;
      grid-row: 6;
    }
  }

  &__item.is-data09 {
    grid-column: 1/span 2;
    grid-row: 5;
    @include media-up(lg) {
      grid-column: 3/span 2;
      grid-row: 3;
    }
    p > small:first-child {
      margin-right: .5em;
    }
    p > small:last-child {
      margin-left: .5em;
    }
    span small {
      margin: 0;
      font-size: .8em;
    }
  }

  .update-month {
    margin-top: 20px;
    font-size: $font-size-xs;
    letter-spacing: .14em;
    text-align: right;
  }
}

.recruit-data-offtime {
  display: block;
  max-width: 100%;
  width: calc(340px + calc(map-get($spacers, 2) * 2));
  margin: 75px auto 0;
  padding: 0 map-get($spacers, 2);
  @include media-up(lg) {
    width: calc(1000px + calc(map-get($spacers, 2) * 2));
    margin: 60px auto 100px;
  }
  &__title {
    margin-bottom: 34px;
    font-weight: $font-weight-bold;
    color: #2A417D;
    font-size: calc(24 / 16 * $font-size-base);
    letter-spacing: .2em;
    text-indent: 0.2em;
    text-align: center;
    @include media-up(lg) {
      margin-bottom: 44px;
    }
    .icon {
      img {
        width: 27px;
        height: 24px;
      }
    }
  }

  &__grid {
    display: flex;
    flex-direction: column;
    gap: 6px; 
    @include media-up(lg) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto;
      gap: 12px;
    }
  }

  &__item {
    margin: 0;
    img {
      max-width: 100%;
      height: auto;
    }
  }
}