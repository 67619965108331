.rwd002-breadcrumbs {
  display: flex;
  padding-left: map-get($spacers, 3);
  padding-right: map-get($spacers, 3);
  margin-top: map-get($spacers, 3);
  margin-bottom: map-get($spacers, 3);

  @include media-up(lg) {
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 3);
  }
  &__list {
    @extend .breadcrumb;
    width: 100%;
    max-width: 1100px;
    margin: auto;
  }
  &__item {
    @extend .breadcrumb-item;
    @extend .text-truncate;
    padding-left: 0;
    margin-right: 1em;
    max-width: 33%;
    color: #B2B3B3;
    font-size: calc(13 / 16 * $font-size-base);
    letter-spacing: 0.14em;
    font-feature-settings: "palt";
    @include media-down(md) {
      font-size: $font-size-xs;
      max-width: 14em;
    }
    & + &{
      padding-left: 0;
    }
    & + &::before {
      content: 'ー';
    }
    &:last-child {
      color: #373737;
    }
  }
}
