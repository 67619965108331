.about-entry {
  max-width: 100%;
  @include media-down(lg) {
    margin-top: 46px;
  }
}

.solution-anchor-links {
  @extend .anchor-links;
  margin-bottom: 58px;
  padding: 0 2rem;
  @include media-up(lg) {
    margin-bottom: 112px;
    padding: 0;
  }
  .anchor-link {
    letter-spacing: .2em;
    @include media-up(lg) {
      width: 160px;
      letter-spacing: .2em;
    }
  }
}

.solution-desc {
  background-color: #F8F8F8;
  font-feature-settings: "palt";
  &__inner {
    max-width: 100%;
    width: 1105px;
    margin: 0 auto;
    padding: 80px map-get($spacers, 3) 62px;
    @include media-up(lg) {
      padding: 110px map-get($spacers, 3);
    }
    p {
      font-weight: $font-weight-bold;
      line-height: 2.2;
      font-size: calc(15 / 16 * $font-size-base);
      letter-spacing: .16em;
      @include media-up(md) {
        text-align: center;
        text-indent: .16em;
      }
    }
  }

  &__title {
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 35px;
    font-size: calc(22 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    line-height: 1.5;
    letter-spacing: .2em;
    text-indent: 0.2em;
    text-align: center;
    @include media-up(lg) {
      margin-bottom: 20px;
      padding-bottom: 45px;
      font-size: calc(24 / 16 * $font-size-base);
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      display: inline-block;
      width: 1px;
      height: 21px;
      margin: auto;
      background-color: #CED4DF;
      @include media-up(lg) {
        width: 1px;
        height: 30px;
      }
    }
  }

  &__img {
    margin-top: 62px;
    margin-bottom: 0;
    @include media-down(lg) {
      margin-top: 44px;
      text-align: center;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }


}

.solution-qa {
  max-width: 100%;
  width: calc(960px + 3.5rem);
  margin: 108px auto 50px;
  padding: 0 1.75rem;
  font-feature-settings: "palt";
  .qa_list {
    counter-reset: qaNum 0;
    margin: 0;
    padding: 0;
    p, dl, dd {
      margin: 0;
    }
    img {
      max-width: 100%;
      height: auto;
    }

    li {
      border: 1px solid #4E638A;
      box-sizing: border-box;
      counter-increment: qaNum 1;
      list-style: none;
      background-color: #fff;
      + li {
        margin-top: 54px;
      }
    }
    .q_wrap {
      &::before {
        content: 'Q' counter(qaNum);
        display: grid;
        place-content: center;
        width: 100px;
        height: 100%;
        color: #fff;
        font-family: 'Jost', $font-family-base;
        font-weight: 500;
        font-size: 27px;
        letter-spacing: .08em;
        background-color: #4E638A;
      }
      .ttl {
        letter-spacing: .14em;
        line-height: 1.3;
        font-size: 24px;
        font-weight: 500;
        color: #4E638A;
        font-family: $font-family-serif;
      }
    }
    .a_wrap {
      border-top: 1px solid #4E638A;
      box-sizing: border-box;
      padding: 46px 16px 48px;
      position: relative;
      &::before {
        content: 'A' counter(qaNum);
        line-height: 1;
        font-family: 'Jost', $font-family-base;
        font-weight: 500;
        font-size: 27px;
        letter-spacing: .08em;
        color: #4E638A;
        position: absolute;
        left: 33px;
        top: 49px;
      }
      dl {
        dt {
          color: #4E638A;
          letter-spacing: .14em;
          font-weight: 500;
        }
        dd {
          font-size: 15px;
          font-weight: 500;
          line-height: 2;
        }
      }
      .first_wrap {
        padding-left: 110px;
        dt {
          font-size: 24px;
          line-height: 1.4;
          font-family: $font-family-serif;
        }
        dd {
          margin-top: 25px;
          letter-spacing: .16em;
        }
      }
      .second_wrap {
        padding: 28px 100px 0 110px;
        margin-top: 26px;
        border-top: 1px dotted #627597;
        dt {
          font-size: 18px;
          line-height: 1.5;
        }
        dd {
          margin-top: 18px;
          letter-spacing: .08em;
        }
      }
    }
    @include media-up(lg){
      .sp {
        display: none;
      }
      .q_wrap {
        display: grid;
        grid-template-columns: 97px 1fr;
        column-gap: 30px;
        align-items: center;
        height: 97px;
      }
      .first_wrap {
        display: grid;
        grid-template-columns: 1fr 290px;
        column-gap: 38px;
      }
    }
  }
  @include media-down(lg){
    margin: 60px auto 50px;
    padding: 0 16px;
    .solution-list__title .bg img {
      width: 160px;
    }

    .qa_list {
      max-width: 600px;
      margin-inline: auto;
      li {
        + li {
          margin-top: 40px;
        }
      }
      .q_wrap {
        &::before {
          width: 100%;
          height: 42px;
          font-size: 23px;
        }
        .ttl {
          letter-spacing: .22em;
          font-size: 18px;
          text-align: center;
          padding-block: 15px;
        }
      }
      .a_wrap {
        padding: 46px 25px 30px;
        &::before {
          font-size: 23px;
          left: 0;
          right: 0;
          top: 14px;
          text-align: center;
        }
        dl {
          dt {
            text-align: center;
          }
          dd {
            font-size: 15px;
            line-height: 2;
            letter-spacing: .12em;
          }
        }
        .first_wrap {
          padding-left: 0;
          dt {
            font-size: 18px;
            letter-spacing: .22;
          }
          dd {
            margin-top: 25px;
          }
          figure {
            margin-top: 20px;
            text-align: center;
          }
        }
        .second_wrap {
          padding: 19px 0 0;
          dt {
            font-size: 16px;
            letter-spacing: .14em;
          }
          dd {
            margin-top: 18px;
          }
        }
      }
    }
  }
}

.solution-list {
  margin-top: 76px;
  @include media-up(lg) {
    margin-top: 114px;
  }

  &__title {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-bottom: 44px;
    font-weight: $font-weight-bold;
    font-size: calc(22 / 16 * $font-size-base);
    text-align: center;
    z-index: 0;
    @include media-up(lg) {
      margin-bottom: 66px;
      font-size: calc(24 / 16 * $font-size-base);
    }

    .bg {
      display: inline-block;
      margin: 0 auto -12px;
      z-index: -1;
      img {
        max-width: 100%;
        height: auto;
        @include media-down(lg) {
          max-width: 80vw;
        }
      }
    }

    .icon {
      display: inline-block;
      margin-bottom: 12px;
      font-size: 0;
      img {
        max-width: 100%;
        height: auto;
      }
    }

    strong {
      letter-spacing: .2em;
      text-indent: 0.2em;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    color: $white;
    gap: 1px;
    @include media-down(lg) {
      flex-direction: column;
      row-gap: 5px;
      max-width: calc(100% - 16px);
      margin: 0 auto;
      padding: 0;
    }
  }

  .banner {
    flex-grow: 1;
    position: relative;
    display: grid;
    grid-template-columns: 1fr/1fr;
    grid-template-rows: 1fr/1fr;
    width: 100%;
    overflow: hidden;
    text-decoration: none;
    font-feature-settings: "palt";
    @include media-up(lg) {
      width: calc(50% - 3px);
    }

    &:hover, &:focus {
      .banner-img {
        transform: scale(1.075);
      }
      .banner-body {
        @include media-up(lg) { opacity: 0; }
      }
      .banner-hover {
        @include media-up(lg) { opacity: 1; }
      }
    }
    &-img {
      grid-column: 1/2;
      grid-row: 1/2;
      width: 100%;
      height: auto;
      transition: transform .3s ease-in-out;
      @include media-down(md) {
        max-width: 100%;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .title {
      position: relative;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      margin-bottom: 0;
      line-height: 1;
      font-weight: $font-weight-bold;
      font-size: $font-size-lg;
      letter-spacing: 0.12em;
      @include media-up(lg) {
        font-size: calc(22 / 16 * $font-size-base);
      }
      small {
        margin-bottom: 1rem;
        font-family: 'Cantarell', $font-family-base;
        font-weight: $font-weight-normal;
        font-size: $font-size-xxs;
        letter-spacing: .2em;
        @include media-up(lg) {
          font-size: calc(11 / 16 * $font-size-base);
        }
      }
      &:after {
        content: "";
        position: absolute;
        bottom: .3em;
        left: calc(100% + .5em);
        width: 18px;
        height: 5px;
        background: center / contain no-repeat;
        background-image: svg-right-arrow-lg($white);
        @include media-up(lg) {
          left: calc(100% + .75em);
        }
      }
    }

    .banner-body {
      grid-column: 1/2;
      grid-row: 1/2;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      padding: 1em map-get($spacers, 2);
      width: 100%;
      opacity: 1;
      transition: $transition-base;
      z-index: 1;
      @include media-up(lg) {
        padding-left: min(3.5rem, 4vw);
        padding-bottom: 2.8rem;
      }
    }

    .banner-hover {
      grid-column: 1/2;
      grid-row: 1/2;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: map-get($spacers, 2) 2em map-get($spacers, 2) min(3.5rem, 4vw);
      background-color: rgba(#004098, .8);
      color: $white;
      opacity: 0;
      transition: $transition-base;
      z-index: 1;
      @include media-up(xl) {
        justify-content: flex-end;
        padding: map-get($spacers, 2) 2em 2.8rem min(3.5rem, 4vw);
      }
      @include media-down(lg) {
        display: none;
      }
      .title {
        margin-bottom: map-get($spacers, 2);
        @include media-up(xl) {
          margin-bottom: map-get($spacers, 3);
        }
      }
      p {
        margin-bottom: 0;
        font-size: $font-size-xs;
        line-height: 1.92;
        letter-spacing: .1em;
        @include media-up(xl) {
          font-size: $font-size-sm;
          line-height: 1.92;
        }
      }
    }

  }
}

.solution-strength {
  margin-top: 66px;
  @include media-up(lg) {
    margin-top: 117px;
  }
  
  &__title {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-bottom: 44px;
    font-weight: $font-weight-bold;
    font-size: calc(22 / 16 * $font-size-base);
    text-align: center;
    z-index: 0;
    @include media-up(lg) {
      margin-bottom: 66px;
      font-size: calc(24 / 16 * $font-size-base);
    }

    .bg {
      display: inline-block;
      margin: 0 auto -12px;
      z-index: -1;
      img {
        max-width: 100%;
        height: auto;
        @include media-down(lg) {
          max-width: 80vw;
        }
      }
    }

    .icon {
      display: inline-block;
      margin-bottom: 12px;
      font-size: 0;
      img {
        max-width: 100%;
        height: auto;
      }
    }

    strong {
      letter-spacing: .2em;
      text-indent: 0.2em;
    }
  }

  &__list {
    padding-left: 0;
    list-style: none;
  }
}

.solution-strength-item {
  font-feature-settings: "palt";
  @include media-up(lg) {
    margin-top: 126px;
  }

  &__row {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 64px;
    @include media-up(lg) {
      gap: 70px;
      margin-bottom: 120px;
      padding-right: map-get($spacers, 3);
      flex-direction: row;
    }
  }

  &:nth-child(even) &__row{
    @include media-up(lg) {
      flex-direction: row-reverse;
      padding-left: map-get($spacers, 3);
      padding-right: 0;
    }
  }

  &__img {
    margin-bottom: 0;
    @include media-up(lg) {
      width: calc(50% - 110px);
      aspect-ratio: 595/451;
    }
    img {
      width: 100%;
      height: auto;
    }
  }

  &__body {
    @include media-up(lg) {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      max-width: calc(50% + 40px);
      width: 530px;
    }
    @include media-down(lg) {
      padding-left: map-get($spacers, 3);
      padding-right: map-get($spacers, 3);
    }
    p {
      font-size: calc(15 / 16 * $font-size-base);
      line-height: 2.2;
      letter-spacing: .16em;
    }
    .btnwrap {
      margin-top: 40px;
      .btn {
        @include c-btn-bg-flow(transparent, #373737, $primary, $white);
        max-width: 100%;
        width: 310px;
        height: 65px;
        background-color: $white;
        font-weight: $font-weight-bold;
        letter-spacing: 0.12em;
        text-indent: 0.12em;
        font-size: $font-size-sm;
        @include media-up(lg) {
          width: 366px;
          height: 77px;
          font-size: calc(15 / 16 * $font-size-base);
          letter-spacing: 0.22em;
          text-indent: 0.22em;
        }
        &:after {
          display: none;
        }
        .icon {
          margin-right: 0.5em;
        }
      }
    }
  }

  &__title {
    position: relative;
    display: flex;
    align-items: baseline;
    margin-bottom: 30px;
    font-family: 'Jost', $font-family-base;
    color: #4E638A;
    font-weight: $font-weight-medium;
    font-size: calc(27 / 16 * $font-size-base);
    letter-spacing: .12em;
    @include media-up(lg) {
      margin-bottom: 54px;
    }
    &:before {
      content: '';
      display: inline-block;
      width: 54px;
      height: 1px;
      margin-right: 0.5em;
      background-color: #4E638A;
    }
    small {
      font-size: $font-size-sm;
    }
    .number {
      line-height: 1;
      letter-spacing: .08em;
    }
  }

  &__catch {
    display: block;
    margin-bottom: 28px;
    font-family: $font-family-serif;
    font-weight: $font-weight-medium;
    font-size: calc(19 / 16 * $font-size-base);
    line-height: 1.8;
    color: #4E638A;
    letter-spacing: .12em;
    @include media-up(lg) {
      margin-bottom: 32px;
      font-size: min(calc(26 / 16 * $font-size-base), 2.14285vw);
    }
  }
}

.solution-strength-point {
  padding: 72px map-get($spacers, 3);
  background-color: #F8F8F8;
  @include media-up(lg) {
    padding: 130px map-get($spacers, 3);
  }
  &__title {
    position: relative;
    margin-bottom: 28px;
    padding-bottom: 35px;
    font-size: calc(22 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    line-height: 1.5;
    letter-spacing: .2em;
    text-indent: 0.2em;
    text-align: center;
    @include media-up(lg) {
      margin-bottom: 42px;
      padding-bottom: 45px;
      font-size: calc(24 / 16 * $font-size-base);
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      display: inline-block;
      width: 1px;
      height: 21px;
      margin: auto;
      background-color: #CED4DF;
      @include media-up(lg) {
        width: 1px;
        height: 30px;
      }
    }
  }

  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 20px 0;
    max-width: 100%;
    width: 960px;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    @include media-up(lg) {
      gap: 68px 7.5%;
      flex-direction: row;
    }
    @include media-down(lg) {
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__item {
    @include media-up(lg) {
      width: calc(calc(100% / 3) - 5%);
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      margin-bottom: 20px;
      font-family: $font-family-serif;
      font-weight: 600;
      color: #627597;
      strong {
        letter-spacing: 0.14em;
        text-indent: 0.14em;
      }
      .icon {
        min-height: 84px;
      }
    }
    .body {
      .catch {
        display: block;
        margin-bottom: 24px;
        color: #627597;
        font-size: calc(15 / 16 * $font-size-base);
        line-height: 1.7;
        letter-spacing: .14em;
        text-indent: 0.14em;
        text-align: center;
        @include media-up(lg) {
          white-space: nowrap;
          margin-bottom: 28px;
        }
      }
      p {
        font-size: $font-size-sm;
        line-height: 1.85;
        letter-spacing: .14em;
      }
    }
  }
}

.solution-bg-fixed {
  display: block;
  width: 100%;
  @include media-up(lg) {
    height: 466px;
    background: 50%/cover no-repeat;
    background-attachment: fixed;
    background-image: url('/assets/img/about/solution/bg_data_fixed@2x.jpg');
    overflow: hidden;
  }
  /* Safari用のハック */
  _::-webkit-full-page-media,
  _:future,
  :root & {
    @include media-up(lg) {
      background-attachment: scroll !important;
      background: url('/assets/img/about/solution/bg_data_fixed@2x.jpg') 50%/cover no-repeat;
    }
  }
  img {
    @include media-up(lg) {
      pointer-events: none;
      opacity: 0;
    }
    @include media-down(lg) {
      width: 100%;
      height: auto;
    }
  }
}

.solution-data {
  max-width: 100%;
  width: calc(960px + calc(map-get($spacers, 3) * 2));
  margin: 50px auto;
  padding: 0 map-get($spacers, 3);
  font-feature-settings: "palt";
  @include media-up(lg) {
    margin-top: 130px;
    margin-bottom: 72px;
  }
  &__title {
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 35px;
    font-size: calc(22 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    line-height: 1.5;
    letter-spacing: .2em;
    text-indent: 0.2em;
    text-align: center;
    @include media-up(lg) {
      margin-bottom: 42px;
      padding-bottom: 45px;
      font-size: calc(24 / 16 * $font-size-base);
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      display: inline-block;
      width: 1px;
      height: 21px;
      margin: auto;
      background-color: #CED4DF;
      @include media-up(lg) {
        width: 1px;
        height: 30px;
      }
    }
  }

  .update-month {
    margin-top: 40px;
    font-size: $font-size-xs;
    letter-spacing: .16em;
  }

  > ul {
    display: flex;
    flex-direction: column;
    gap: 40px 0;
    margin: 0;
    padding: 0;
    list-style: none;
    @include media-up(lg) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto;
      gap: 80px 85px;
    }
  }

  > ul > li {
    @include media-up(lg) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
    @include media-down(lg) {
      max-width: 440px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    .name {
      display: block;
      width: 100%;
      margin-bottom: 28px;
      padding-bottom: 20px;
      border-bottom: 1px solid #E0E3EA;
      font-family: $font-family-serif;
      color: #627597;
      font-size: calc(20 / 16 * $font-size-base);
      letter-spacing: .14em;
      text-indent: 0.14em;
      text-align: center;
    }
    .desc {
      display: block;
      margin-bottom: 15px;
      font-size: calc(15 / 16 * $font-size-base);
      letter-spacing: .16em;
      text-indent: 0.16em;
      text-align: center;
    }
    .data {
      margin-bottom: 0.5em;
      font-family: 'Roboto', $font-family-base;
      font-weight: $font-weight-bold;
      font-size: calc(31 / 16 * $font-size-base);
      line-height: 1;
      letter-spacing: .12em;
      text-indent: 0.12em;
      text-align: center;
      strong {
        font-weight: $font-weight-medium;
        font-size: calc(46 / 16 * $font-size-base);
      }
    }
    >strong {
      display: block;
      margin-bottom: 1em;
      font-weight: $font-weight-bold;
      font-size: calc( 15 / 16 * $font-size-base);
      letter-spacing: .16em;
      text-indent: 0.16em;
      text-align: center;
    }
    >p {
      font-size: $font-size-sm;
      line-height: 1.85;
      letter-spacing: .14em;
      @include media-up(lg) {
        margin-top: 28px;
      }
    }
    >small {
      font-size: $font-size-xs;
      letter-spacing: .16em;
      text-indent: 0.16em;
    }
    figure {
      margin: 8px 0 0;
      @include media-down(lg){
        margin-bottom: 15px;
      }
      img {
        margin: 0;
      }
    }

    .img {
      margin-top: auto;
    }

    img {
      max-width: 100%;
      margin-top: 2.5em;
      height: auto;
    }

    >ul {
      column-count: 2;
      padding: 0;
      list-style: none;
      color: #536387;
      @include media-up(lg) {
        margin-top: 28px;
      }
      &.list-sm-column1 {
        @include media-down(sm) {
          column-count: 1;
        }
      }
      li {
        position: relative;
        padding-left: 1em;
        break-inside: avoid;
        margin-bottom: 10px;
        font-weight: $font-weight-bold;
        font-size: calc(13 / 16 * $font-size-base);
        letter-spacing: .16em;
        &:before {
          content: '';
          position: absolute;
          top: .5em;
          left: 0;
          display: inline-block;
          width: 6px;
          height: 6px;
          margin: 0 .5em auto 0;
          border-radius: 100em;
          background-color: #CED4DF;
        }
        &.etc {
          font-family: $font-family-base;
          &:before {
            display: none;
          }
        }
      }
    }

    .num_list {
      column-count: 1;
      width: min(100%, 280px);
      margin: 15px auto 0;
      li {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}