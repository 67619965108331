@keyframes skewanime {
  100% {
    left:-10%;/*画面の見えていない左から右へ移動する終了地点*/
  }
}

@function svg-right-arrow($theme: $primary) {
  @return svgUrlFunc(
    '<svg viewBox="0 0 11.83 4.98" xmlns="http://www.w3.org/2000/svg"><path d="m8.75-.62h1v6.08h-1z" fill="#{$theme}" transform="matrix(.67636191 -.73656946 .73656946 .67636191 1.21 7.6)"/><path d="m0 3.98h11.49v1h-11.49z" fill="#{$theme}"/></svg>'
  );
}
@function svg-right-arrow02($theme: $primary) {
  @return svgUrlFunc(
    '<svg viewBox="0 0 13 12" xmlns="http://www.w3.org/2000/svg"><path fill="#{$theme}" d="m6.43 0-1.33 1.44 3.93 3.58h-9.03v1.96h9.03l-3.93 3.58 1.33 1.44 6.57-6z"/></svg>'
  );
}
@function svg-right-arrow-lg($theme: $primary) {
  @return svgUrlFunc(
    '<svg viewBox="0 0 11.83 4.98" xmlns="http://www.w3.org/2000/svg"><path d="m8.75-.62h1v6.08h-1z" fill="#{$theme}" transform="matrix(.67636191 -.73656946 .73656946 .67636191 1.21 7.6)"/><path d="m0 3.98h11.49v1h-11.49z" fill="#{$theme}"/></svg>'
  );
}

@function svg-right-angle($theme: $primary) {
  @return svgUrlFunc(
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$theme}" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>'
  );
}

@function svg-bottom-angle($theme: $body-color) {
  @return svgUrlFunc('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$theme}" d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"/></svg>');
}

.btn-icon-right-angle {
  position: relative;
  &:after{
    position: absolute;
    content: "";
    top: calc(50% - 0.3em);
    right: 1em;
    width: 1em;
    height: 0.6em;
    background: center / contain no-repeat;
    background-image: svg-right-angle();
  }
  &:hover:after,&:focus:after{
    background-image: svg-right-angle($white);
  }
}

.btn-icon-right-angle-white{
  @extend .btn-icon-right-angle;
  &:after{
    background-image: svg-right-angle($white);
  }
}

.btn-icon-bottom-angle {
  position: relative;
  &:after{
    position: absolute;
    content: "";
    top: calc(50% - 0.3em);
    right: 0;
    width: 2em;
    height: 0.6em;
    transition: all .5s ease-in-out;
    background: center / contain no-repeat;
    background-image: svg-bottom-angle();
    transform: rotateX(180deg);
  }
  &.collapsed:after{
    transform: rotateX(0deg);
  }
}

.btn-icon-right-arrow {
  position: relative;
  &:after{
    position: absolute;
    content: "";
    top: calc(50% - 0.3em);
    right: 1em;
    width: 1em;
    height: 0.6em;
    background: center / contain no-repeat;
    background-image: svg-right-arrow();
  }
  &:hover:after,&:focus:after{
    background-image: svg-right-arrow($white);
  }
}

.btn-icon-right-arrow-white{
  @extend .btn-icon-right-arrow;
  &:after{
    background-image: svg-right-arrow($white);
  }
}

.btn-pill {
  border-radius: 100em;
}

@mixin c-btn-bg-flow($theme_bg: transparent, $theme_color: $primary, $theme_bg_hover: $primary, $theme_color_hover: $white) {
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $theme_bg;
  border-color: #CED4DF;
  color: $theme_color;
  font-family: 'Roboto', $font-family-base;
  font-size: calc(13 / 16 * $font-size-base);
  font-weight: $font-weight-medium;
  letter-spacing: .12em;
  text-indent: 0.12em;
  @include media-up(lg) {
    font-size: $font-size-base;
  }
  span {
    position: relative;
    z-index: 3;
    transition: .2s ease-in-out;
  }
  &:hover, &:focus {
    background-color: transparent;
    span {
      color: $theme_color_hover;
    }
    &:before {
      animation: skewanime .5s forwards;
    }
    &:after {
      background-image: svg-right-arrow($white);
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -130%;
    background: $theme_bg_hover;
    width: 120%;
    height: 100%;
    transform: skewX(-25deg);
  }
  &:after {
    position: absolute;
    content: "";
    top: calc(50% - 0.3em);
    right: 1em;
    width: 1em;
    height: 0.6em;
    background: center / contain no-repeat;
    background-image: svg-right-arrow(#CED4DF);
    transition: .2s ease-in-out;
  }
}

@mixin c-btn-bg-flow-fill( $theme_bg: $tertiary, $theme_color: $white, $theme_bg_hover: $white, $theme_color_hover: $tertiary ) {
  @include c-btn-bg-flow();
  background-color: $theme_bg;
  border-color: $theme_bg;
  &::before {
    background-color: $theme_bg_hover;
  }
  &:after {
    background: center / contain no-repeat;
    background-image: svg-right-arrow($theme_color);
  }
  
  span {
    color: $white;
  }

  &:hover, &:focus {
    background-color: $theme_bg_hover;
    span {
      color: $theme_color_hover;
    }
    &:after {
      background-image: svg-right-arrow($theme_color_hover);
    }
  }
}

.btn-btn-bg-flow {
  @include c-btn-bg-flow();
}

@mixin c-btn-underline-arrow-right($theme: $primary) {
  position: relative;
  width: 148px;
  min-height: 50px;
  padding: 1em 0;
  font-family: 'Roboto', $font-family-base;
  font-size: $font-size-sm;
  color: $theme;
  letter-spacing: .18em;
  text-align: left;
  transition: $transition-base;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: block;
    width: 13px;
    height: 12px;
    margin: auto;
    background: center / contain no-repeat;
    background-image: svg-right-arrow02($theme);
    transition: transform .2s ease-in-out;
  }
  &:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    display: block;
    width: 100%;
    height: 3px;
    background: $theme;
  }
  &:hover, &:focus {
    letter-spacing: .25em;
    &:after {
      transform: translateX(8px);
    }
  }
}