@mixin c-module-title() {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.75em;
  font-weight: normal;
  letter-spacing: 0.22em;
  text-indent: 0.22em;
  font-size: $h2-font-size;
  @include media-down(lg) {
    font-size: 1.875rem;
  }
  @include media-down(md) {
    font-size: $h4-font-size;
  }

  span {
    font-family: 'Roboto', $font-family-sans-serif;
    font-weight: $font-weight-normal;
  }

  &:after{
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    display: block;
    width: 82px;
    height: 1px;
    margin: auto;
    background: $primary;
  }
}

@mixin c-title-spanEn-smallJa($theme: #373737, $theme_en: #0057A8) {
  display: flex;
  flex-direction: column;
  font-weight: $font-weight-bold;
  font-size: calc(34 / 16 * $font-size-base);
  letter-spacing: .12em;
  @include media-up(lg) {
    font-size: calc(40 / 16 * $font-size-base);
  }
  @include media-down(lg) {
    text-indent: 0.12em;
  }
  small {
    margin-bottom: 14px;
    font-size: $font-size-sm;
    color: $theme_en;
    letter-spacing: .34em;
    text-indent: 0.34em;
    @include media-up(lg) {
      margin-bottom: 16px;
      font-size: calc(15 / 16 * $font-size-base);
      text-indent: 0;
    }
  }
  span {
    font-family: 'Cantarell', $font-family-base;
    color: $theme;
    @include media-up(lg) {
      text-indent: -.12em;
    }
  }
}

@mixin c-recruit-title($theme: #373737, $theme_en: $tertiary) {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: $font-weight-bold;
  font-size: calc(36 / 16 * $font-size-base);
  letter-spacing: .12em;
  text-indent: 0.12em;
  @include media-up(lg) {
    font-size: calc(40 / 16 * $font-size-base);
  }
  small {
    margin-bottom: 14px;
    font-size: $font-size-sm;
    color: $theme;
    letter-spacing: .34em;
    text-indent: 0.34em;
    @include media-up(lg) {
      margin-bottom: 16px;
      font-size: $font-size-base;
      text-indent: 0;
    }
  }
  span {
    font-family: 'Jost', $font-family-base;
    font-weight: $font-weight-medium;
    color: $theme_en;
    letter-spacing: 0.18em;
    text-indent: 0.18em;
  }
}

@mixin vertical-title {
  padding-top: 42px;
  position: relative;
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 35px;
    height: 1px;
    background-color: #CED4DF;
    left: 0;
    top: 0;
  }
  span, strong {
    display: block;
    line-height: 1;
  }
  span {
    font-family: 'Jost', $font-family-base;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: .24em;
  }
  strong {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: .1em;
    margin-top: 20px;
  }
  @include media-down(lg){
    padding-top: 26px;
    strong {
      margin-top: 13px;
    }
  }
}

@mixin bottom-border-title {
  padding-bottom: 25px;
  border-bottom: 1px solid #627597;
  color: #627597;
  line-height: 1.2;
  font-family: $font-family-serif;
  font-weight: 800;
  font-size: 20px;
  letter-spacing: .14em;
  @include media-down(lg) {
    padding-bottom: 14px;
    font-size: 17px;
    line-height: 1.3;
  }
}

@mixin arrow-title {
  display: grid;
  place-content: center;
  height: 36px;
  background-color: #4E638A;
  color: #fff;
  position: relative;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: .12em;
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 36px;
    height: 8px;
    background-color: #4E638A;
    clip-path: polygon(0 0, 100% 0, 50% 100%);
    left: 0;
    right: 0;
    bottom: -7px;
    margin: auto;
  }
  @include media-down(lg){
    height: 34px;
    font-size: 16px;
    &::before {
      width: 30px;
      height: 6px;
      bottom: -5px;
    }
  }
}