.about-techno-container {
  max-width: 960px;
  margin: 0 auto;
}

.block-about-techno {
  max-width: 747px;
  width: 100%;
  font-family: $font-family-base;
  font-feature-settings: "palt";
  margin: 0 auto 50px;
  padding: 0 1.95rem;

  @include media-up(md) {
    margin: 110px auto 95px;
    padding: 0;
  }

  &-title {
    margin-bottom: 30px;

    @include media-up(md) {
      margin-bottom: 35px;
    }

    .title {
      font-size: min(5vw, 1.5rem);
      font-family: $font-family-base;
      font-feature-settings: "palt";
      font-weight: bold;
      text-align: center;
      line-height: 34px;
      letter-spacing: 0.1em;
      margin-bottom: 25px;

      @include media-up(md) {
        line-height: 44px;
        letter-spacing: 0.24em;
      }
    }
  }


  &-details {

    &-content {
      font-size: 0.9375rem;
      font-weight: 300;
      line-height: 32px;
      letter-spacing: 0.12em;

      @include media-up(lg) {
        line-height: 33px;
        letter-spacing: 0.16em;
      }
    }
  }
}

.block-about-techno-list {
  font-family: $font-family-base;
  font-feature-settings: "palt";
  margin: 0 auto;
  padding: 0 1.95rem;
  position: relative;

  &-title {
    margin-bottom: 18px;
    @include media-up(md) {
      margin-bottom: 32px;
    }
    h3 {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      gap: 12px 0;
      font-size: min(4.8vw, 20px);
      font-weight: $font-weight-bold;
      text-align: center;
      line-height: 1.6;
      letter-spacing: 0.16em;
      text-indent: 0.16em;
      &::after {
        content: '';
        display: block;
        width: 1px;
        height: 24px;
        margin: 0 auto;
        background-color: #CED4DF;
        @include media-up(md) {
          height: 30px;
        }
      }
    }
  }

  &-content {
    max-width: 720px;
    width: 100%;
    margin: 0 auto;
    overflow: visible;

    .about__techno__list {
      max-width: 100%;
      width: 320px;
      margin: 0 auto;
      padding: 0;
      column-count: 2;
      list-style: none;
      font-weight: $font-weight-bold;
      color: #536387;
      line-height: 1.7;
      letter-spacing: 0.16em;
      @include media-up(lg) {
        width: 100%;
        margin: 0 auto;
        column-count: 3;
        overflow: visible;
      }
      &__item {
        position: relative;
        margin-bottom: 0.5em;
        padding-left: 1em;
        font-size: calc(15 / 16 * $font-size-base);
        @include media-up(lg) {
          word-break: keep-all;
          overflow: visible;
          font-size: $font-size-base;
        }
        &::before {
          content: '';
          position: absolute;
          top: .5em;
          left: 0;
          width: 7px;
          height: 7px;
          background-color: #CED4DF;
          border-radius: 100%;
        }
      }
    }
  }
}

.block-about-techno-links-buttons {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  @include media-up(sm) {
    flex-direction: row;
    justify-content: center;
    gap: 25px;
  }

  @include media-up(lg) {
    margin: 55px;
  }

  .btn-link {
    color: #373737;
    width: 310px;
    height: 65px;
    font-size: min(3.73vw, 0.9375rem);
    font-family: $font-family-base;
    font-feature-settings: "palt";
    font-weight: bold;
    line-height: 23px;
    letter-spacing: 0.14em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
    transition: all 0.5s;
    background-color: white;
    border: 1px #CAD0DC solid;
    border-radius: 50px;

    @include media-up(lg) {
      max-width: 366px;
      width: 100%;
      height: 77px;
      letter-spacing: 0.18em;
    }

    svg {
      width: 13px;
      height: 10px;

      g {
        transition: all 0.5s;
      }
    }
    
    &::after {
      content: '';
      width: 11px;
      height: 10px;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      background-image: url('data:image/svg+xml;utf-8,<svg id="_レイヤー_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.95 10.58"><defs><style>.cls-1{fill:none;stroke:#d6d6d6;stroke-miterlimit:10;}</style></defs><g id="_文字"><g><rect class="cls-1" x=".5" y=".5" width="8.21" height="6.85"/><polyline class="cls-1" points="11.45 3.24 11.45 10.08 3.24 10.08"/></g></g></svg>');
      background-size: 100%;
      background-repeat: no-repeat;

      @include media-up(lg){
        right: 30px;
      }
    }

    &:hover,
    &--active {
      color: white;
      text-decoration: none;
      background-color: #4E638A;
      border: 1px #4E638A solid;

      svg g {
        fill: white
      }
    }
  }
}

.about__nav__links {
  max-width: 1200px;
  margin: 105px auto;
  padding: 0 10px;
  display: grid;
  gap: 5px;

  @include media-up(sm) {
    grid-template-columns: 1fr 1fr;
  }

  @include media-up(lg) {
    grid-template-columns: repeat(4, 1fr);
    gap: 1px;
    margin-top: 100px;
    margin-bottom: 70px;
    padding: 0;
  }

  &__btn {
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      transition: transform 0.5s;
    }

    .link-title {
      color: white;
      font-size: 1.125rem;
      font-weight: bold;
      margin: 0;
      display: flex;
      flex-direction: column;
      position: absolute;
      left: 28px;
      bottom: 19px;
      gap: 12px;
    }

    .title {
      position: relative;

      &::after {
        content: '';
        width: 16px;
        height: 5px;
        position: absolute;
        top: 50%;
        right: -33px;
        transform: translateY(-50%);
        transition: transform 0.5s;
        background-image: url('data:image/svg+xml;utf-8,<svg id="_レイヤー_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.24 4.51"><defs><style>.cls-1{fill:none;stroke:#fff;stroke-miterlimit:10;}</style></defs><g id="_文字"><g><path class="cls-1" d="m11.94.37l3.97,3.64"/><line class="cls-1" x1="15.91" y1="4.01" x2="0" y2="4.01"/></g></g></svg>');
        background-size: 100%;
        background-repeat: no-repeat;
      }
    }

    .sublink {
      font-size: 0.625rem;
      font-weight: 500;
      letter-spacing: 0.2em;
    }

    &:hover {

      img {
        transform: scale(1.1);
      }

      .title {

        &::after {
          transform: translateY(-50%) translateX(5px);
        }
      }
    }
  }
}

@keyframes jump {

  0%,
  20% {
    transform: translateY(0);
  }

  10% {
    transform: translateY(3px);
  }
}