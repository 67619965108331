@keyframes slider {
  from {transform: translateX(0);}
  to {transform: translateX(-3423px - 15px);}
}
@keyframes slider_sp {
  from {transform: translateX(0);}
  to {transform: translateX(-2178px - 10px);}
}

.is--home {
  .rwd002-container {
    margin-top: 0;
  }

  .rwd002-gnav-item {
    &.is-form {
      @include media-up(lg) {
        margin-bottom: -40px;
      }
    }

    &.is-form .rwd002-gnav-item__link {
      @include media-up(lg) {
        clip-path: polygon(0 0,100% 0,100% 100%,66% 100%,66% 73.5%,0 73.5%);
        padding: 0 1em 40px;
      }
    }
  }
}

.block-about {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  max-width: calc(1180px + 60px);
  width: 100%;
  margin: 0 auto;
  padding: 64px 2rem;
  @include media-up(lg) {
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: row-reverse;
    margin: 180px auto 170px;
    padding: 0 30px;
  }
  @include media-down(lg) {
    background: $white;
  }

  &__body {
    @include media-up(lg) {
      padding-top: 72px;
      padding-left: 65px;
      flex: 1;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    font-family: 'Cantarell', $font-family-base;
    font-weight: $font-weight-bold;
    color: #373737;
    letter-spacing: .2em;
    font-size: calc(20 / 16 * $font-size-base);
    @include media-up(lg) {
      margin-bottom: 40px;
    }
    &:before {
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      margin-right: 9px;
      background-color: #CED4DF;
      border-radius: 100em;
    }
  }
  
  &__catch {
    display: block;
    margin-bottom: 26px;
    font-family: $font-family-serif;
    color: #233569;
    font-size: calc(24 / 16 * $font-size-base);
    font-weight: $font-weight-normal;
    line-height: 1.875;
    letter-spacing: .16em;
    @include media-up(lg) {
      margin-bottom: 26px;
      font-size: min(2em, 2.95vw);
      letter-spacing: .14em;
    }
  }
  
  &__text {
    margin-bottom: 30px;
    font-family: $font-family-serif;
    font-weight: $font-weight-normal;
    font-size: calc(15 / 16 * $font-size-base);
    line-height: 2;
    letter-spacing: .05em;
    @include media-up(lg) {
      margin-bottom: 43px;
      font-size: 15px;
      letter-spacing: .18em;
    }
  }

  &__btnwrap {
    @include media-down(lg) {
      text-align: center;
    }
    .btn {
      @include c-btn-bg-flow();
      @extend .btn-outline-primary;
      width: 275px;
      min-height: 55px;
    }
  }

  &__img {
    margin: 0;
    @include media-down(lg) {
      margin-top: 48px;
    }
    @include media-up(lg) {
      transition: $transition-base;
      width: 51%;
    }

    &.is-hidden {
      @include media-up(lg) {
        opacity: 0;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

}

.block-solution {
  font-feature-settings: "palt";
  @include media-up(lg) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-row: auto;
    margin-bottom: 100px;
  }
  @include media-down(lg) {
    background: $white;
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 40px;
    @include media-up(lg) {
      grid-column: 1;
      margin-bottom: 0;
    }
  }

  &__body-inner {
    max-width: 18em;
    @include media-down(lg) {
      text-align: center;
    }
  }

  &__title {
    @include c-title-spanEn-smallJa();
    margin-bottom: 24px;
    @include media-up(lg) {
      margin-bottom: 44px;
    }
  }

  &__text {
    font-weight: $font-weight-bold;
    line-height: 2.1;
    letter-spacing: .1em;
  }

  &__btnwrap {
    @include media-up(lg) {
      margin-top: 36px;
    }
    .btn {
      @include c-btn-bg-flow();
      @extend .btn-outline-primary;
      width: 230px;
      min-height: 48px;
      @include media-up(lg) {
        width: 275px;
        min-height: 55px;
      }
    }
  }

  &__list {
    grid-column: 2;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    color: $white;
    gap: 6px;
    @include media-down(lg) {
      flex-direction: column;
      row-gap: 5px;
      max-width: calc(100% - 16px);
      margin: 0 auto;
      padding: 0;
    }
  }

  .banner {
    flex-grow: 1;
    position: relative;
    display: grid;
    grid-template-columns: 1fr/1fr;
    grid-template-rows: 1fr/1fr;
    width: 100%;
    overflow: hidden;
    text-decoration: none;
    font-feature-settings: "palt";
    @include media-up(lg) {
      width: calc(50% - 3px);
    }

    &:hover, &:focus {
      .banner-img {
        transform: scale(1.075);
      }
      .banner-body {
        @include media-up(lg) { opacity: 0; }
      }
      .banner-hover {
        @include media-up(lg) { opacity: 1; }
      }
    }
    &-img {
      grid-column: 1/2;
      grid-row: 1/2;
      width: 100%;
      height: auto;
      transition: transform .3s ease-in-out;
      @include media-down(md) {
        max-width: 100%;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .title {
      position: relative;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      margin-bottom: 0;
      line-height: 1;
      font-weight: $font-weight-bold;
      font-size: $font-size-lg;
      letter-spacing: 0.12em;
      @include media-up(lg) {
        font-size: calc(22 / 16 * $font-size-base);
      }
      small {
        margin-bottom: 1rem;
        font-family: 'Cantarell', $font-family-base;
        font-weight: $font-weight-normal;
        font-size: $font-size-xxs;
        letter-spacing: .2em;
        @include media-up(lg) {
          font-size: calc(11 / 16 * $font-size-base);
        }
      }
      &:after {
        content: "";
        position: absolute;
        bottom: .3em;
        left: calc(100% + .5em);
        width: 18px;
        height: 5px;
        background: center / contain no-repeat;
        background-image: svg-right-arrow-lg($white);
        @include media-up(lg) {
          left: calc(100% + .75em);
        }
      }
    }

    .banner-body {
      grid-column: 1/2;
      grid-row: 1/2;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      padding: 1em map-get($spacers, 2);
      width: 100%;
      opacity: 1;
      transition: $transition-base;
      z-index: 1;
      @include media-up(lg) {
        padding-left: 2em;
        padding-bottom: 2em;
      }
    }

    .banner-hover {
      grid-column: 1/2;
      grid-row: 1/2;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: map-get($spacers, 2) 2em map-get($spacers, 2);
      background-color: rgba(#004098, .8);
      color: $white;
      opacity: 0;
      transition: $transition-base;
      z-index: 1;
      @include media-up(xl) {
        justify-content: flex-end;
        padding: map-get($spacers, 2) 2em map-get($spacers, 4);
      }
      @include media-down(lg) {
        display: none;
      }
      .title {
        margin-bottom: map-get($spacers, 2);
        @include media-up(xl) {
          margin-bottom: map-get($spacers, 3);
        }
      }
      p {
        margin-bottom: 0;
        font-size: $font-size-xs;
        line-height: 1.92;
        letter-spacing: .1em;
        @include media-up(xl) {
          font-size: $font-size-sm;
          line-height: 1.92;
        }
      }
    }

  }
  
}

.block-works {
  max-width: calc(1240px + 60px);
  width: 100%;
  margin: 0 auto;
  padding: 58px 0;
  font-feature-settings: "palt";
  @include media-up(lg) {
    margin: 100px auto;
    padding: 0 30px;
  }
  @include media-down(lg) {
    background: $white;
  }

  &__inner {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @include media-up(lg) {
      flex-direction: row-reverse;
      justify-content: flex-end;
      padding-top: 30px;
      padding-bottom: 30px;
    }
    @include media-up(xl) {
      padding-left: min(80px, 5.7143vw);
    }
  }

  &__img {
    margin-bottom: 0;
    @include media-up(lg) {
      transition: $transition-base;
    }
    img {
      max-width: 100%;
      height: auto;
    }

    &.is-hidden {
      @include media-up(lg) {
        opacity: 0;
      }
    }
  }
  &__img.is-img01 {
    @include media-up(lg) {
      position: absolute;
      top: 0;
      right: 0;
    }
    @include media-down(lg) {
      margin-left: auto;
      margin-right: map-get($spacers, 3);
      margin-bottom: 36px;
      width: 145px;
      height: 112px;
    }
  }

  &__img.is-img02 {
    position: relative;
    @include media-up(lg) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    @include media-down(lg) {
      margin-right: auto;
      max-width: calc(100% - 32px);
    }
    .img02-02 {
      @include media-up(lg) {
        margin-top: -76px;
        margin-right: -165px;
      }
    }
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 56px;
    @include media-up(lg) {
      margin-bottom: 20px;
      padding-left: calc(min(80px, 5vw) + 165px);
    }
  }

  &__body-inner {
    max-width: 26em;
    @include media-down(lg) {
      max-width: 18em;
      text-align: center;
    }
  }

  &__title {
    @include c-title-spanEn-smallJa();
  }

  &__text {
    font-weight: $font-weight-bold;
    line-height: 2.1;
    letter-spacing: .1em;
    @include media-down(lg) {
      font-size: min(15px, 4.2667vw);
    }
  }

  &__btnwrap {
    @include media-up(lg) {
      margin-top: 36px;
    }
    .btn {
      @include c-btn-bg-flow();
      @extend .btn-outline-primary;
      width: 230px;
      min-height: 48px;
      @include media-up(lg) {
        width: 275px;
        min-height: 55px;
      }
    }
  }

}

.block-company {
  padding: 74px map-get($spacers, 3 ) 50px;
  font-feature-settings: "palt";
  @include media-up(lg) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto;
    height: 540px;
    padding: 0 0;
    border: solid #CED4DF;
    border-width: 1px 0;
  }
  @include media-down(lg) {
    background : linear-gradient(240.34deg, #143484 0%, #0c4c9e 33.67%, #0364b7 75.14%, #006dc1 100%);
  }
  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
    text-align: center;
    @include media-up(lg) {
      margin: 0;
      background : linear-gradient(240.34deg, #143484 0%, #0c4c9e 33.67%, #0364b7 75.14%, #006dc1 100%);
      text-align: left;
    }
  }

  &__title {
    @include c-title-spanEn-smallJa($white, $white);
  }

  &__text {
    margin-bottom: 0;
    font-weight: $font-weight-bold;
    line-height: 2.1;
    letter-spacing: .1em;
    color: $white;
    @include media-down(lg) {
      width: 100%;
      font-size: min(15px, 4.2667vw);
    }
  }

  &__banners {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 0;
    @include media-up(lg) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
    }

    .banner + .banner {
      border: 1px solid #CED4DF;
      border-width: 1px 0 0;
      @include media-up(lg) {
        border-width: 0 0 0 1px;
      }
    }

    &:hover, &:focus {
      background-color: transparent;
      .banner {
        color: $white;
        text-decoration: none;
        background-color: transparent;
      }
    }

    @media (any-hover: none) {
      &:active, &:focus {
        .banner {
          color: $white;
          text-decoration: none;
        }
      }
    }
  }

  .banner {
    display: flex;
    transition: .3s ease-in-out;
    @include media-up(lg) {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      background-color: $white;
    }
    @include media-down(lg) {
      padding: 20px map-get($spacers, 2) 20px 0;
      color: $white;
    }
    &:hover, &:focus {
      text-decoration: none;
      &:after {
        background-image: svg-right-arrow-lg($white);
      }
    }
    &:after {
      @include media-up(lg) {
        grid-column: 1/2;
        grid-row: 1/2;
        content: "";
        display: inline-block;
        width: 18px;
        height: 5px;
        margin: auto 14px 18px auto;
        background: center / contain no-repeat;
        background-image: svg-right-arrow-lg(#CED4DF);
      }
    }

    &__img {
      @include media-down(lg) {
        width: 48.125%;
        height: auto;
        aspect-ratio: 154/96;
      }
    }

    &-img {
      width: 100%;
      height: auto;
    }

    &__title {
      position: relative;
      display: inline-flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      margin: auto auto auto map-get($spacers, 3);
      line-height: 1;
      font-weight: $font-weight-bold;
      font-size: $font-size-lg;
      letter-spacing: 0.12em;
      @include media-up(lg) {
        grid-column: 1/2;
        grid-row: 1/2;
        align-items: center;
        height: 100%;
        margin: auto;
        font-size: calc(22 / 16 * $font-size-base);
        text-indent: 0.12em;
        text-align: center;
      }
      small {
        margin-bottom: 1rem;
        font-family: 'Jost', $font-family-base;
        font-weight: $font-weight-normal;
        font-size: $font-size-xxs;
        letter-spacing: .2em;
        @include media-up(lg) {
          font-size: calc(11 / 16 * $font-size-base);
          text-indent: 0.2em;
        }
      }
      &:after {
        @include media-down(lg) {
          content: "";
          position: absolute;
          bottom: .3em;
          left: calc(100% + .5em);
          width: 18px;
          height: 5px;
          background: center / contain no-repeat;
          background-image: svg-right-arrow-lg($white);
        }
      }
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity .3s ease-in-out;
    pointer-events: none;
    z-index: -1;
    @include media-down(lg) {
      display: none;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.block-recruit {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 60px 0 78px;
  background: $white;
  font-feature-settings: "palt";
  @include media-up(lg) {
    padding: 110px 0 85px;
  }
  &__slider {
    overflow: hidden;
    &-inner {
      display: flex;
      align-items: center;
      column-gap: 15px;
      animation: slider 50s linear infinite;
      @include media-down(lg) {
        column-gap: 10px;
        animation: slider_sp 50s linear infinite;
      }
    }
    img {
      @include media-down(lg) {
        max-width: 2178px;
        height: auto;
      }
    }
  }

  &__inner {
    position: relative;
    max-width: 640px;
    width: 100%;
    margin: -24px auto 0;
    text-align: center;
    z-index: 1;
    @include media-up(lg) {
      margin: -12px auto 0;
    }
  }

  &__title {
    margin-bottom: 20px;
    @include media-up(lg) {
      margin-bottom: 28px;
    }
  }

  &__text {
    font-weight: $font-weight-bold;
    line-height: 2.1;
    letter-spacing: .1em;
    @include media-down(lg) {
      font-size: calc(15 / 16 * $font-size-base);
    }
  }

  &__btnwrap {
    @include media-up(lg) {
      margin-top: 36px;
    }
    .btn {
      @include c-btn-bg-flow-fill( #17ACBD, $white, $white, #17ACBD);
      width: 230px;
      min-height: 48px;
      @include media-up(lg) {
        width: 275px;
        min-height: 55px;
      }
    }
  }
}

//お知らせ
.block-news {
  position: relative;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  font-feature-settings: "palt";
  z-index: 0;
  @include media-down(lg) {
    padding-top: 70px;
    margin-bottom: 80px;
  }
  &__inner {
    max-width: 100%;
    width: calc(960px + calc(map-get($spacers, 3) * 2));
    margin: 0 auto;
    padding: 0 map-get($spacers, 3);
    @include media-up(lg) {
      padding: 135px map-get($spacers, 3) 50px;
    }
  }
  &__row {
    @include media-up(lg) {
      display: flex;
      justify-content: flex-start;
      gap: 64px;
    }
  }

  &__head {
    width: 100%;
    @include media-up(lg) {
      max-width: 130px;
    }
    @include media-down(lg) {
      display: flex;
      align-items: center;
    }
  }

  &__list-wrap {
    width: 100%;
    @include media-up(lg) {
      max-width: 100%;
      flex-grow: 1;
    }
  }

  &__list {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    @include media-up(lg) {
      margin-top: -1.5rem;
    }
  }

  &__title {
    position: relative;
    align-items: flex-start;
    margin-bottom: 0;
    padding-bottom: 14px;
    font-family: 'Cantarell', $font-family-base;
    font-weight: $font-weight-normal;
    font-size: calc(33 / 16 * $font-size-base);
    letter-spacing: .14em;
    color: #1E5199;
    text-align: left;
    @include media-down(lg) {
      margin-bottom: 20px;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 28px;
      height: 1px;
      background-color: #1E5199;
    }
  }

  &__btnwrap {
    text-align: right;
    .btn {
      padding-left: 0;
      padding-right: 0;
      font-family: 'Cantarell', $font-family-base;
      font-weight: $font-weight-medium;
      font-size: calc(13 / 16 * $font-size-base);
      color: $primary;
      letter-spacing: 0.12em;
      text-indent: 0.12em;
      transition: $transition-base;
      @include media-up(lg) {
        margin-top: 2.375rem;
      }
      @include media-down(lg) {
        margin-left: auto;
        font-size: $font-size-base * 0.8125;
      }
      &:hover,
      &:focus {
        text-decoration: none;
        color: #0057A8;
        span {
          border-color: #0057A8;
        }
      }
      span {
        padding: .375rem;
        border-bottom: 1px solid $primary;
        transition: $transition-base;
      }
      .icon {
        margin-left: 0.2em;
        color: #0057A8;
      }
    }
  }
}

.block-news-item {
  @include list-common__item();
  position: relative;
  &__link {
    display: block;
    padding: 1.4rem 0;
    color: #373737;
    transition: $transition-base;
    &:hover,
    &:focus {
      color: $primary;
      text-decoration: none;
      .date,
      .cate,
      .title.is-new:after,
      .comment {
        color: inherit;
      }
    }
  }

  &__body {
    @include media-up(lg) {
      display: flex;
      flex-direction: column;
    }
  }

  .title {
    flex-grow: 1;
    font-weight: $font-weight-bold;
    font-size: calc(15/16 * $font-size-base);
    letter-spacing: .16em;
    color: inherit;
    @include media-up(lg) {
      font-size: $font-size-base;
    }
  }

  .date-cate {
    margin-bottom: 0.5em;
    color: #A4A3A3;
    @include media-up(lg) {
      margin-right: 1em;
    }
  }
  
  .date {
    margin-bottom: 0;
    margin-right: 0.5em;
    font-family: 'Manrope', $font-family-base;
    font-weight: $font-weight-medium;
    font-size: $font-size-xs;
    color: inherit;
    letter-spacing: .2em;
    @include media-down(md) {
      display: inline-block;
      vertical-align: middle;
    }
  }
  
  .cate {
    vertical-align: middle !important;
    min-width: auto;
    margin: 0 0 0 .5em;
    padding: 0;
    background: none;
    border: none;
    font-weight: $font-weight-bold;
    color: inherit;
    @include media-down(md) {
      max-width: calc(50vw - map-get($spacers, 3) - map-get($gutters, 3));
    }
  }

  & + & {
    border: solid #EAEBEB;
    border-width: 1px 0 0;
  }
}

//インスタ、YouTubeボタン
.block-sns {
  padding: 0 map-get($spacers, 3);
  margin: 80px auto 57px;
  @include media-up(lg) {
    margin: 70px auto 124px;
  }
  &__inner {
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
  }

  &__title {
    display: flex;
    align-items: flex-end;
    margin-bottom: 18px;
    font-family: 'Cantarell', $font-family-base;
    font-size: calc(26/16 * $font-size-base);
    font-weight: $font-weight-normal;
    text-align: center;
    letter-spacing: .12em;
    @include media-up(lg) {
      margin-bottom: 36px;
      text-align: left;
    }
    @include media-down(lg) {
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .icon {
      margin-right: 0.5em;
      @include media-up(lg) {
        margin-right: 0.75em;
      }
    }

    span {
      display: inline-flex;
      align-items: center;
      @include media-down(lg) {
        margin-bottom: 32px;
      }
    }

    span+a {
      font-family: 'Roboto', $font-family-base;
      font-size: $font-size-sm;
      color: #9D9D9E;
      letter-spacing: 0.2em;
      @include media-up(lg) {
        margin-left: auto;
      }
      @include media-down(lg) {
        text-indent: 0.2em;
      }
      &:after {
        content: '';
        display: inline-block;
        width: 18px;
        height: 5px;
        margin-left: 0.2em;
        background: center / contain no-repeat;
        background-image: svg-right-arrow-lg(#9D9D9E);
        transition: transform .2s ease-in-out;
      }
      &:hover:after, &:focus:after {
        transform: translateX(5px);
      }
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto auto auto;
    gap: 6px;
    width: 100%;
    margin: 0 auto;
    @include media-up(lg) {
      display: grid;
      grid-template-columns: 39.0625% 1fr 1fr 1fr;
      grid-template-rows: auto auto;
      margin-bottom: 75px;
      gap: 12px;
    }
  }

  &__item {
    margin: 0;
    &:first-child {
      grid-column: 1/ span 2;
      grid-row: 1/ span 2;
      @include media-up(lg) {
        grid-column: 1;
        grid-row: 1/span 2;
      }
    }

    &:nth-child(n+10) {
      @include media-down(lg) {
        display: none;
      }
    }
    &:nth-child(n+8) {
      @include media-up(lg) {
        display: none;
      }
    }

    a {
      position: relative;
      display: block;
      max-width: 100%;
      width: 100%;
      height: 100%;
      aspect-ratio: 1/1;
      overflow: hidden;

      &:hover img,
      &:focus img,
      &.is-video:hover video,
      &.is-video:focus video {
        transform: scale(1.1);
      }
    }

    img, video {
      display: block;
      width: 100%;
      height: 100%;
      aspect-ratio: 1/1;
      object-fit: cover;
      object-position: center;
      transition: $transition-base;
    }
    .is-video:after,
    .is-carousel_album:after {
      position: absolute;
      top: 0.7em;
      right: 0.7em;
      line-height: 1;
      font-family: 'Font Awesome 6 Free';
      color: $white;
    }

    .is-video:after {
      @extend .fa-solid;
      content: '\f03d';
    }

    .is-carousel_album:after {
      content: '\f24d';
    }
  }

  &__btnwrap {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    margin: 30px 0;

    @include media-down(lg) {
      margin-left: -5px;
      margin-right: -5px;
    }

    .button {
      height: 9.5rem;
      width: 100%;
      max-width: 420px;
      margin: 9px;
      font-family: 'Jost', $font-family-base;
      font-size: 1.5rem;
      letter-spacing: 0.18em;
      font-weight: $font-weight-medium;

      @include media-down(lg) {
        margin: 5px;
        height: 7rem;
      }

      img+span {
        margin-left: 0.8em;
      }
    }

  }

  &__youtube {
    margin: 75px auto 0;
    text-align: center;
    .btn {
      @extend .btn-outline-dark;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      width: 320px;
      min-height: 67px;
      font-family: 'Cantarell', $font-family-base;
      font-weight: $font-weight-normal;
      font-size: $font-size-lg;
      border-color: #CFD4DE;
      background-color: $white;
      color: #373737;
      letter-spacing: .14em;
      text-indent: 0.14em;
      @include media-up(lg) {
        width: 366px;
        min-height: 77px;
        font-size: calc(20 / 16 * $font-size-base);
        letter-spacing: .12em;
        text-indent: 0.12em;
      }
      &:hover, &:focus {
        border-color: $sns-youtube-bg;
        background-color: $sns-youtube-bg;
        color: $white;
        box-shadow: none;
      }
      .icon {
        margin-right: 0.5em;
      }
    }
  }
}