@import 'rwd002';

/*
font-family: 'Cantarell', $font-family-base;
font-family: 'Jost', $font-family-base;
font-family: 'League Spartan', $font-family-base;
font-family: 'Manrope', $font-family-base;
font-family: 'Noto Serif JP', $font-family-serif;
*/ 
@import url('https://fonts.googleapis.com/css2?family=Cantarell:wght@400;700&family=Jost:wght@400;500;600&family=League+Spartan:wght@500&family=Manrope:wght@500&family=Noto+Serif+JP:wght@400;500&display=swap');


//pages
@import './pages/home';
@import './pages/recruit';
@import './pages/about';
@import './pages/company';
@import './pages/people';
@import './pages/guideline';


body {
  //Corporate
  @include media-up(lg) {
    background: linear-gradient(to right, #F8F8F8 calc(50% - 233px), transparent calc(50% - 233px)) no-repeat;
  }
  &::before {
    @include media-down(lg) {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: calc(50% - 62px);
      height: 438px;
      background: #F8F8F8;
      z-index: -1;
    }
  }

  &.is--news,
  &.is--contact,
  &.is--sitemap,
  &.is--privacy,
  &.is--about.is--article-6 {
    &:before { display: none; }
  }
  
  //Corporate TOP
  &.is--home {
    background: linear-gradient(to right, #F8F8F8 calc(100%/3), transparent calc(100%/3));
    &:before {
      @include media-down(lg) {
        display: none;
      }
    }
  }

  //Recruit
  &.is--recruit,
  &.is--people,
  &.is--guideline {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      display: block;
      height: auto;
      background: center/contain no-repeat;
      z-index: -1;
    }
    @include media-up(lg) {
      background: linear-gradient(to right, #0057A8 calc(50% - 233px), transparent calc(50% - 233px));
      &:before {
        top: 46px;
        width: calc(50% - 233px);
        aspect-ratio: 457/454;
        background-image: url('/assets/img/recruit/bg_recruit_body.svg');
      }
    }
    @include media-down(lg) {

      &:before {
        top: 0;
        width: min(216px, 57.6vw);
        height: min(240px, 64vw);
        background-image: url('/assets/img/recruit/bg_recruit_body_sp.svg');
      }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 375px;
        clip-path: polygon(100% 55px, calc(100% - 176px) 100%, 0% 100%, 0% 0%, 100% 0%);
        background-color: #0057A8;
        z-index: -2;
      }
    }

  }

  //Recruit TOP
  &.is--recruit.is--list {
    background: transparent;
    @include media-up(lg) {
      background: linear-gradient(to right, #0057A8 calc(100%/3), transparent calc(100%/3));
    }
  }
}