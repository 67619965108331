.is--recruit.is--article-4 {
  .rwd002-container {
    margin-top: 0;
  }
}

.recruit-requirements__banner {
  max-width: 100%;
  width: calc(100% - 2em);
  margin-left: auto;
  margin-bottom: 16px;
  font-feature-settings: "palt";
  @include media-up(lg) {
    width: calc(50% + 486px);
    margin-bottom: 50px;
  }

  &-title {
    @include c-title-spanEn-smallJa();
    margin-bottom: 2rem;
    font-size: min(9.6vw, 2.5rem);
    @include media-up(lg) {
      margin-bottom: 66px;
    }
  }

  &-img {
    img {
      max-width: 100%;
      width: 100%;
      height: auto;
      @include media-down(lg) {
        width: 100%;
      }
    }
  }
}

.recruit-requirements-section {
  max-width: 100%;
  margin: 0 auto 50px;
  padding: 30px map-get($spacers, 3) 0;
  font-feature-settings: "palt";
  @include media-up(lg) {
    display: flex;
    gap: 104px;
    width: calc(980px + calc(map-get($spacers, 3) * 2));
    margin: 0 0 100px;
    padding: 20px map-get($spacers, 3) 0;
    transform: translateX(calc(50vw - 486px - map-get($spacers, 3)));
  }

  &__catch {
    margin: 0 0 100px;
    line-height: 1;
    font-size: calc(26 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    color: #0057A8;
    letter-spacing: .28em;
    @include media-up(lg) {
      margin-bottom: 70px;
      font-size: calc(44 / 16 * $font-size-base);
      color: $white;
    }
    @include media-down(lg) {
      max-width: 11em;
    }
    .bg-wrap {
      position: relative;
      display: inline-block;
      margin-bottom: 10px;
    }
    &.is-animated .bg-wrap::before {
      animation: bg 2.6s cubic-bezier(0.22, 1, 0.36, 1) forwards;
      background: $white;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform-origin: left center;
      @include media-up(lg) {
        background: #0057A8;
      }
    }
    .bg-inner {
      position: relative;
      display: inline-block;
      padding: .4em .75em;
      white-space: nowrap;
      z-index: 1;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    color: #0057A8;
    font-family: 'Jost', $font-family-base;
    font-weight: $font-weight-medium;
    font-size: calc(24 / 16 * $font-size-base);
    letter-spacing: .14em;
    @include media-up(lg) {
      gap: 30px;
      min-width: 253px;
      color: $white;
      font-size: calc(30 / 16 * $font-size-base);
    }
    small {
      font-weight: $font-weight-bold;
      font-size: $font-size-base;
      color: #373737;
      letter-spacing: .26em;
      @include media-up(lg) {
        color: $white;
        letter-spacing: .36em;
      }
      @include media-down(lg) {
        margin-bottom: 1em;
      }
    }
  }

  &__body {
    .title {
      position: relative;
      margin: 0 0 24px;
      font-size: calc(17 / 16 * $font-size-base);
      color: #566384;
      line-height: 1.7;
      font-weight: bold;
      letter-spacing: 0.14em;
      &:before {
        content: '';
        display: inline-block;
        width: 13px;
        height: 11px;
        margin-right: 0.5em;
        background: 50%/contain no-repeat;
        background-image: svgUrlFunc('<svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11"><g fill="#566384"><path d="M3.48,4.91c-.25,.43-.67,.43-.92,0L.11,.78C-.14,.35,.06,0,.57,0H5.47C5.98,0,6.18,.35,5.93,.78L3.48,4.91Z"/><path d="M5.54,6.32c.5,0,.76-.35,1.02-.77L9.47,.77C9.73,.35,9.52,0,9.02,0h-1.22c-.5,0-.73,.35-.99,.77L3.9,5.55c-.26,.43-.06,.77,.45,.77h1.19Z"/><path d="M5.86,10.7c.28,.41,.72,.41,.98-.02L12.89,.77C13.15,.34,12.94,0,12.44,0h-1.21c-.5,0-.75,.35-1.01,.77L5.32,8.84c-.26,.42-.11,.89,.17,1.3l.37,.55Z"/></g></svg>');
      }
    }

    p {
      font-size: calc(15 / 16 * $font-size-base);
      line-height: 2.2;
      letter-spacing: .12em;
    }

    .btnwrap {
      .btn {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 86px;
        font-size: calc(19 / 16 * $font-size-base);
        color: $white;
        letter-spacing: 0.25em;
        text-indent: 0.25em;
        border-radius: 0;
        clip-path: polygon(0% 0%, 0% 100%, calc(100% - 20px) 100%, 100% calc(100% - 20px), 100% 0%);
        @include media-up(lg) {
          height: 100px;
          font-size: calc(19 / 16 * $font-size-base);
        }
        @include media-down(lg) {
          line-height: 1.3;
        }
        &:after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 18px;
          width: 13px;
          height: 12px;
          margin: auto;
          background: 50%/contain no-repeat;
          background-image: svg-right-arrow02($white);
        }
        &.is-rikunabi {
          border-color: #0069BD;
          background-color: #0069BD;
        }
        &.is-mynavi {
          border-color: #00AAEA;
          background-color: #00AAEA;
        }

        span {
          position: relative;
        }

        &[target="_blank"] span:after{
          content: '';
          display: inline-block;
          width: 13px;
          height: 13px;
          margin: auto auto auto .3em;
          background: 50%/contain no-repeat;
          background-image: svgUrlFunc('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path fill="#{$white}" d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"/></svg>');
          @include media-up(lg) {
            margin-left: 0.5em;
          }
        }

        &:hover, &:focus {
          background-color: $primary;
          border-color: $primary;
        }
      }
      .btn + .btn {
        margin-top: 12px;
        @include media-up(lg) {
          margin-top: 20px;
        }
      }
    }
  }

  &__body + &__body {
    margin-top: 50px;
    @include media-up(lg) {
      margin-top: 70px;
    }
  }

  ol {
    counter-reset: number;
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    z-index: 0;
    @include media-up(lg) {
      margin: 40px auto 0;
    }
  }

  li {
    counter-increment: number 1;
    position: relative;
    display: flex;
    align-items: flex-start;
    padding-bottom: 25px;
    @include media-up(lg) {
      padding-bottom: 34px;
    }
    &:before {
      content: '';
      flex-shrink: 0;
      display: inline-block;
      width: 14px;
      height: 13px;
      margin-top: 0.45em;
      margin-right: .75em;
      background-image: svgUrlFunc('<svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13"><path fill="#{#cad0dc}" d="M7,0C3.13,0,0,2.91,0,6.5s3.13,6.5,7,6.5,7-2.91,7-6.5S10.87,0,7,0Zm0,9.29c-1.66,0-3-1.25-3-2.79s1.34-2.79,3-2.79,3,1.25,3,2.79-1.34,2.79-3,2.79Z"/><ellipse fill="#{#4e638a}" cx="7" cy="6.5" rx="3" ry="2.79"/></svg>');
      z-index: 1;
      @include media-up(lg) {
        margin-right: 1em;
      }
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 7px;
      display: block;
      width: 1px;
      height: 100%;
      background-color: #CAD0DC;
      z-index: -1;
    }
    &:first-child {
      &:after {
        height: calc(100% - .45em);
      }
    }
    &:last-child {
      margin-top: -0.45em;
      &:after {
        display: none;
      }
    }
    strong {
      display: block;
      margin-bottom: 16px;
      font-size: $font-size-lg;
      letter-spacing: .14em;
      &:before {
        content: counter(number, decimal-leading-zero)'.';
        font-family: 'Roboto', $font-family-base;
        font-weight: $font-weight-normal;
        letter-spacing: .14em;
      }
    }
    p {
      margin-bottom: 0;
      padding: 0;
      @include media-up(lg) {
        font-size: calc(13 / 16 * $font-size-base);
        line-height: 2;
        letter-spacing: .16em;
      }
    }
    p + p {
      margin-top: 1em;
    }

    a:not(.btn) {
      text-decoration: underline;
      color: $blue;
      &:hover, &:focus {
        color: $primary;
      }
    }
  }

  table {
    width: 100%;
    margin-top: 35px;
    @include media-up(lg) {
      margin-top: 40px;
    }

    tr:last-child {
      th, td {
        border: none;
      }
      th:after {
        display: none;
      }
    }

    th, td {
      font-family: $font-family-base;
      font-size: $font-size-sm;
      letter-spacing: 0.15em;
      border-bottom: 1px #E9E9E9 solid;
      @include media-up(lg) {
        padding: 1.5rem 0;
      }
      @include media-down(lg) {
        display: block;
      }
    }

    th {
      position: relative;
      font-weight: $font-weight-bold;
      letter-spacing: .34em;
      z-index: 0;
      @include media-up(lg) {
        min-width: 145px;
      }
      @include media-down(lg) {
        padding: 1rem 0 .3em;
        border-bottom: none;
      }
      &:after {
        @include media-up(lg) {
          content: '';
          position: absolute;
          top: 100%;
          left: 0;
          display: block;
          width: 32px;
          height: 1px;
          background-color: #C0C1C1;
          z-index: -1;
        }
      }
    }

    td {
      @include media-down(lg) {
        position: relative;
        padding: 0 0 1rem;
        border-bottom: 1px #E9E9E9 solid;
        z-index: 1;
      }
      &:after {
        @include media-down(lg) {
          content: '';
          position: absolute;
          top: 100%;
          left: 0;
          display: block;
          width: 32px;
          height: 1px;
          background-color: #C0C1C1;
          z-index: -1;
        }
      }
    }
  }
}