//Recruit TOP
//////// 先輩インタビューの新卒タグ非表示 ////////////
.is--recruit.is--list {
  .block-people-item:nth-of-type(2) .block-people-item__thumb {
    &::before {
      content: 'キャリア';
      display: block;
      position: absolute;
      min-width: 103px;
      padding: 0.5em 1em;
      background-color: #17d1cf;
      font-size: .6875rem;
      color: #fff;
      font-weight: 700;
      letter-spacing: .2em;
      text-indent: 0.2em;
      text-align: center;
      left: 0;
      bottom: 0;
    }
    .cate {
      display: none;
    }
  }
}
////////////////////////////////////////////////

@keyframes gradient {
  0% {
    top: 200%;
  }
  50% {
    top: -200%;
  }
  100% {
    top: 200%;
  }
}

.is--recruit.is--list {
  #allbox {
    padding-top: 0;
  }
  
  .rwd002-header {
    top: 0;
    left: 0;
    @include media-up(lg) {
      position: absolute;
    }
    &__logo .logo-recruit {
      fill: $white;
    }
  }

  .rwd002-gnav-item {
    &__link {
      @include media-up(lg) {
        color: $white;
      }
    }
  }

  .rwd002-container {
    margin-top: 0;
    overflow: hidden;
  }

  .rwd002-breadcrumbs {
    display: none;
  }

  .block-sns {
    background: $white;
    @include media-up(lg) {
      margin: 0 auto;
      padding-top: 70px;
      padding-bottom: 124px;
    }
  }
}

.recruit-mainvisual__wrap {
  position: relative;
}

.recruit-mainvisual {
  position: relative;
  width: 100%;
  aspect-ratio: 1400/852;
  background: url('/assets/img/recruit/mainvisual_bg_sp@2x.jpg') center / cover no-repeat;
  z-index: 0;
  @include media-up(lg) {
    background: url('/assets/img/recruit/mainvisual_bg@2x.jpg') center / cover no-repeat;
  }

  &__catch {
    position: relative;
    width: 100%;
    aspect-ratio: 1400/852;
    margin-bottom: 0;
    opacity: 0;
    transition: .7s ease-in-out;
    z-index: 2;

    .is-start & {
      opacity: 1;
    }

    img {
      max-width: 100%;
      width: 100%;
      height: auto;
      aspect-ratio: 375/611;
      @include media-up(lg) {
        aspect-ratio: 1400/852;
      }
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.35);
    z-index: 0;
  }
  
  &__grad {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    width: 0;
    height: 100%;
    margin: auto;
    clip-path: polygon(65% 0%,120% 0%,30% 100%, -25% 100%);
    transition: width .5s ease-in-out;
    overflow: hidden;
    z-index: 0;
    @include media-up(lg) {
      clip-path: polygon(58% 0,100% 0,42% 100%,0 100%);
    }
    &:before {
      content: '';
      position: absolute;
      top: 200%;
      left: -200%;
      right: 0;
      bottom: 0;
      display: block;
      width: 400%;
      height: 400%;
      margin: auto;
      background : linear-gradient(30deg, #003680 0%, #01519C 30%, #1297C4 50%, #01519C 70%, #003680 100%);
      background-size: 100% 100%;
      animation: gradient 10s ease infinite;
      z-index: -1;
    }

    .is-start & {
      width: 100%;
      @include media-up(lg) {
        width: 64.714%;
        clip-path: polygon(58% 0,100% 0,42% 100%,0 100%);
      }
    }
  }

  &__scroll {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 200px;
    margin: auto;
    border-width: 0;
    font-family: 'Cantarell', $font-family-base;
    color: $white;
    font-size: $font-size-xs;
    letter-spacing: .2em;
    z-index: 2;
    @include media-down(lg) {
      width: 32px;
      height: 130px;
      font-size: $font-size-xxs;
      top: 0;
      left: auto;
      right: 0;
      margin: auto;
    }
    
    &:hover,
    &:focus {
      color: $white;
      text-decoration: none;
    }

    >span {
      display: block;
      line-height: 1;
      transform: rotate(90deg) translateY(0) translateX(40%);
      @include media-up(lg) {
        transform: rotate(90deg) translateY(0) translateX(85%);
      }
    }

    .is-scroll-line {
      position: absolute;
      top: auto;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      width: 1px;
      height: 68px;
      margin: auto;
      background: #CED4DF;
      z-index: 1;
      @include media-up(lg) {
        height: 107px;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        width: 1px;
        height: 6px;
        background: #003E99;
        animation: scroll_line_sp 2s infinite ease-in-out;
        @include media-up(lg) {
          animation: scroll_line 2s infinite ease-in-out;
        }
      }
    }
  }

  &__copyright {
    @include media-up(lg) {
      position: absolute;
      top: 0; bottom: 0;
      left: auto; right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 50px;
      height: 100%;
      margin: 0 auto 0 0;
      color: $white;
      writing-mode: vertical-rl;
      font-feature-settings: "pkna";
      z-index: 2;
    }
    small {
      font-family: 'Jost', $font-family-base;
      font-size: $font-size-xxs;
      letter-spacing: 0.24em;
      text-indent: 0.24em;
    }
  }
}

.recruit-mainvisual-ticker {
  display: flex;
  align-items: stretch;
  width: 100%;
  margin-left: auto;
  background-color: $white;
  font-feature-settings: "palt";
  @include media-up(lg) {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 560px;
    width: 39.8vw;
    z-index: 1;
  }

  &__title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    padding: 1.75rem 0.7em 1.75rem 1.25em;
    color: #373737;
    font-family: 'League Spartan', $font-family-base;
    font-size: calc(17 / 16 * $font-size-base);
    font-weight: $font-weight-medium;
    letter-spacing: 0.12em;
    @include media-up(lg) {
      padding: 1em 1.75em;
      font-size: calc(23 / 16 * $font-size-base);
    }
    @include media-down(lg) {
    }
  }

  &__body{
    flex-grow: 1;
    min-width: 0;
    padding: 1.2em 1.5em 1.2em 0;
    @include media-down(lg) {
      padding: 1.75rem map-get($spacers, 3) 1.75rem map-get($spacers, 2);
    }
  }

  &__link{
    min-width: 0;
    color: inherit;
    display: block;
    &:hover,&:focus{
      text-decoration: none;
      .title{
        text-decoration: underline;
      }
    }
  }

  .cate {
    position: relative;
    display: inline-block;
    margin-right: 0.5em;
    padding-right: 0.5em;
    font-size: calc(13 / 16 * $font-size-base);
    letter-spacing: 0.06em;
    &:after{
      position: absolute;
      content: '';
      top: calc(50% - 0.5em);
      bottom: 0;
      right: 0;
      width: 0;
      height: 1em;
      border-left: 1px solid;
    }
  }

  .date{
    display: inline-block;
    font-family: 'League Spartan', $font-family-base;
    font-size: $font-size-xxs;
    color: #373737;
    font-size: calc(13 / 16 * $font-size-base);
    letter-spacing: 0.2em;
  }

  .title{
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.25em 0;
    font-weight: $font-weight-normal;
    font-size: calc(14 / 16 * $font-size-base);
    letter-spacing: 0.06em;
    span{
      @extend .text-truncate;
      display: block;
      white-space: nowrap;
    }
    &:after{
      flex-shrink: 0;
      content: '';
      background: svgUrlFunc('<svg viewBox="0 0 12.33 4.29" xmlns="http://www.w3.org/2000/svg"><path d="m.55 3.74h11.23l-2.55-3.19" fill="none" stroke="#D8D8D8" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.11"/></svg>') center / contain no-repeat;
      width: 12px;
      height: 5px;
      margin-left: 0.3em;
    }
  }

}

.block-message {
  position: relative;
  width: 100%;
  font-feature-settings: "palt";
  @include media-up(lg) {
    height: 250vh;
    height: 250dvh;
  }
  @include media-down(lg) {
    padding: 66px 0 0;
    background-color: #0057A8;
  }

  .pin-spacer {
    pointer-events: none;
  }

  &__row {
    position: relative;
    @include media-up(lg) {
      height: 100vh;
      height: 100dvh;
      display: grid;
      grid-template-columns: calc(100% / 3) calc(calc(100% / 3) * 2);
      grid-template-rows: 100%;
    }
    &:after{
      content: '';
      position: absolute;
      pointer-events: none;
      top: -150px;
      bottom: 0;
      right: 0;
      display: block;
      width: calc(calc(100% / 3) * 2);
      transition: all .5s ease-in-out;
    }
    
    .is-dark &:after {
      background-color: fade-out($black, 0.4);
    }
  }

  &__img-wrap {
    grid-column: 2;
    grid-row: 1;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    @include media-down(lg) {
      height: auto;
    }
  }

  &__img {
    width: 50%;
    height: 100%;
    margin-bottom: 0;
    img {
      width: 100%;
      height: auto;
      @include media-up(lg) {
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &__body {
    padding: 0 map-get($spacers, 3);
    @include media-up(lg) {
      grid-column: 1;
      grid-row: 1;
      padding: 158px 22%;
      margin-right: -50%;
      z-index: 1;
    }
    @include media-down(lg) {
      margin-bottom: 48px;
    }
  }

  &__title {
    @include c-title-spanEn-smallJa($tertiary, $white);
  }

  &__catch {
    margin: 0;
    line-height: 1;
    font-size: calc(26 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    color: #0057A8;
    letter-spacing: .28em;
    @include media-up(lg) {
      font-size: 3.142857143vw;
      // font-size: calc(44 / 16 * $font-size-base);
    }
    @include media-down(lg) {
      max-width: 11em;
    }
    .bg-wrap {
      position: relative;
      display: inline-block;
      margin-top: 10px;
    }
    &.is-animated .bg-wrap::before {
      animation: bg 2.6s cubic-bezier(0.22, 1, 0.36, 1) forwards;
      background: $white;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform-origin: left center;
    }
    .bg-inner {
      position: relative;
      display: inline-block;
      padding: .4em .75em;
      white-space: nowrap;
      z-index: 1;
    }
  }

  @keyframes bg {
    0% {
      opacity: 0;
      transform: scaleX(0) translateX(-5%);
    }
    30% {
      transform: scaleX(1) translateX(0);
    }
    100% {
      transform: scaleX(1) translateX(0);
    }
    30%, 100% {
      opacity: 1;
    }
  }

  &__overlay {
    position: relative;
    color: $white;
    z-index: 0;
    @include media-up(lg) {
      position: absolute;
      top: auto;
      right: 0;
      bottom: 0;
      width: calc(calc(100% / 3) * 2);
      height: 150vh;
      height: 150dvh;
      margin: 0 0 0 auto;
      z-index: 1;
    }
  }

  &__overlay-inner {
    @include media-up(lg) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
      height: 100dvh;
      margin-bottom: auto;
    }
  }

  &__overlay-body {
    max-width: 100%;
    width: 480px;
    margin: auto;
    padding: 40px map-get($spacers, 3) 66px;
    font-size: calc(15 / 16 * $font-size-base);
    line-height: 2;
    letter-spacing: .18em;
    font-weight: $font-weight-bold;
    pointer-events: auto;
    @include media-up(lg) {
      height: auto;
      bottom: 0;
      padding: 0;
      font-size: calc(17 / 16 * $font-size-base);
      line-height: 2.588;
      opacity: 0;
      transition: opacity .3s .2s ease-in-out;
    }
    .is-dark & {
      @include media-up(lg) {
        opacity: 1;
      }
    }
  }

  &__overlay-figure {
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    z-index: -1;
    @include media-up(lg) {
      top: 84px;
      right: 0;
      width: calc(100% + 2.4285vw + 6.9285vw);
      height: 100vh;
      height: 100dvh;
    }
    img {
      max-width: 100%;
      width: 100%;
      height: auto;
      @include media-up(lg) {
        width: 100%;
        height: 100%;
        margin: 0 -2.4285vw 0 -6.9285vw;
      }
    }
  }

  .btnwrap {
    @include media-down(lg) {
      text-align: center;
    }
    .btn {
      @include c-btn-underline-arrow-right($white);
    }
  }
}

.block-businessfield {
  position: relative;
  padding-top: 70px;
  font-feature-settings: "palt";
  @include media-up(lg) {
    margin: 0 auto 64px;
    padding-top: 140px;
  }
  @include media-down(lg) {
    background-color: $white;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 42.13333333%;
    height: auto;
    aspect-ratio: 487/820;
    background: center / contain no-repeat;
    background-image: url('/assets/img/recruit/businessfield_bg@2x.png');
    transform: translateY(27%);
    @include media-up(lg) {
      width: 34.78571429%;
      transform: translateY(25%);
    }
  }

  &__body {
    @include media-down(lg) {
      padding: 0 map-get($spacers, 3);
    }
  }

  &__title {
    @include c-title-spanEn-smallJa($tertiary, #373737);
    justify-content: center;
    text-align: center;
    @include media-up(lg) {
      margin-bottom: 35px;
    }
  }

  &__lead {
    font-size: calc(15 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    line-height: 2;
    letter-spacing: .12em;
    text-indent: 0.12em;
    text-align: center;
  }

  .btnwrap {
    text-align: center;
    .btn {
      @include c-btn-underline-arrow-right();
    }
  }

  &__list {
    margin-top: 40px;
    color: $white;
    @include media-up(lg) {
      overflow: visible;
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: 38px;
      height: 38px;
      @include media-up(lg) {
        display: none;
      }
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 100em;
      }
    }

    .swiper-button-prev {
      left: 2vw;
      &:after {
        background: svgUrlFunc(
          '<svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg"><circle cx="19" cy="19" fill="#{#00478f}" r="19"/><path d="m21.76 25.38c-.17 0-.33-.06-.47-.17l-6.81-5.64c-.17-.14-.27-.35-.27-.57s.1-.43.27-.57l6.81-5.64c.32-.26.78-.22 1.05.1.26.32.22.78-.1 1.04l-6.12 5.07 6.12 5.07c.31.26.36.73.1 1.04-.15.18-.36.27-.57.27z" fill="#{$white}"/></svg>'
        ) center/contain no-repeat;
      }
    }

    .swiper-button-next {
      right: 2vw;
      &:after {
        background: svgUrlFunc(
          '<svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg"><circle cx="19" cy="19" fill="#{#00478f}" r="19"/><path d="m16.24 25.38c-.21 0-.43-.09-.57-.27-.26-.32-.22-.78.1-1.04l6.12-5.07-6.12-5.07c-.31-.26-.36-.73-.1-1.04.26-.32.73-.36 1.05-.1l6.81 5.64c.17.14.27.35.27.57s-.1.43-.27.57l-6.81 5.64c-.14.11-.31.17-.47.17z" fill="#{$white}"/></svg>'
        ) center/contain no-repeat;
      }
    }
  }

  &__slider {
    position: relative;
    @include media-up(lg) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto auto;
      gap: 0;
      width: calc(100% - 100px);
      margin-top: 67px;
      margin-left: auto;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 17.85714286%;
      height: auto;
      aspect-ratio: 250/288;
      background: center/contain no-repeat;
      background-image: svgUrlFunc('<svg width="250" height="288" viewBox="0 0 250 288.26" xmlns="http://www.w3.org/2000/svg"><path d="m76.97 288.26h-76.97l173.03-288.26h76.97z" fill="#{#00478f}"/></svg>');
      transform: translate(-50%, -55%);
    }
  }

  .banner {
    flex-grow: 1;
    position: relative;
    display: grid;
    grid-template-columns: 1fr/1fr;
    grid-template-rows: 1fr/1fr;
    width: 100%;
    overflow: hidden;
    text-decoration: none;
    &:hover .banner-img{
      transform: scale(1.075);
    }
    &:nth-child(1) {
      grid-column: 1;
      grid-row: 1/span 2;
    }
    &:nth-child(2) {
      grid-column: 2;
      grid-row: 1;
    }
    &-img {
      grid-column: 1/2;
      grid-row: 1/2;
      width: 100%;
      height: 100%;
      transition: transform .3s ease-in-out;
      @include media-down(md) {
        max-width: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    &-body {
      grid-column: 1/2;
      grid-row: 1/2;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      padding: 1em map-get($spacers, 2);
      width: 100%;
      font-feature-settings: "palt";
      z-index: 1;
      @include media-up(lg) {
        padding-left: 2em;
        padding-bottom: 2em;
      }
    }
    .title {
      position: relative;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      margin-bottom: 0;
      line-height: 1;
      font-weight: $font-weight-bold;
      font-size: $font-size-lg;
      letter-spacing: 0.12em;
      @include media-up(lg) {
        font-size: calc(22 / 16 * $font-size-base);
      }
      span {
        -webkit-font-smoothing: antialiased;
      }
      small {
        margin-bottom: 1rem;
        font-family: 'Cantarell', $font-family-base;
        font-weight: $font-weight-normal;
        font-size: $font-size-xxs;
        letter-spacing: .2em;
        @include media-up(lg) {
          font-size: calc(11 / 16 * $font-size-base);
        }
      }
      &:after {
        content: "";
        position: absolute;
        bottom: .25em;
        left: calc(100% + .5em);
        width: 13px;
        height: 12px;
        background: center / contain no-repeat;
        background-image: svg-right-arrow02($white);
        @include media-up(lg) {
          left: calc(100% + .75em);
        }
      }
    }
  }
}

.block-people {
  margin: 90px auto 70px;
  @include media-up(lg) {
    width: clamp(50vw, 1200px, calc(100% - 200px));
    margin: max(8.5714vw, 120px) auto 177px;
  }
  &__title {
    @include c-title-spanEn-smallJa($tertiary,$white);
    margin-bottom: 0;
    @include media-down(lg) {
      @include c-title-spanEn-smallJa($tertiary,#373737);
      margin-bottom: 24px;
      justify-content: center;
      text-align: center;
    }
  }
  &__inner {
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
    @include media-down(lg) {
      width: 78%;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 34px 0;
    margin: 0 auto 38px;
    padding: 0;
    list-style: none;
    @include media-up(lg) {
      align-items: flex-start;
      flex-direction: row;
      gap: 0 45px;
      margin: 0;
      padding-top: 64px;
    }
  }
  .btnwrap {
    text-align: right;
    @include media-up(lg) {
      transform: translateY( calc(calc(100% * -1) + 16px) );
    }
    @include media-down(lg) {
      text-align: center;
    }
    .btn {
      @include c-btn-underline-arrow-right();
    }
  }
}

.block-people-item {
  width: 100%;
  box-shadow: 20px 20px 20px -15px rgba($black, 0.075);
  transition: $transition-base;
  @include media-up(lg) {
    width: calc(calc(100% / 3) - calc(45px / 2));
  }
  &:nth-child(2) {
    @include media-up(lg) {
      margin-top: -42px;
    }
  }
  &:nth-child(3) {
    @include media-up(lg) {
      margin-top: -84px;
    }
  }
  &:nth-child(n + 3) {
    @include media-down(lg) {
      display: none;
    }
  }

  &:hover, &:focus {
    transform: scale(1.05);
  }

  &__link {
    text-decoration: none;
    &:hover, &:focus {
      text-decoration: none;
    }
  }

  &__thumb {
    position: relative;
    margin-bottom: 0;
    aspect-ratio: 290/330;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
      transition: transform .2s ease-in-out;
    }
    .cate {
      position: absolute;
      bottom: 0;
      left: 0;
      min-width: 103px;
      padding: .5em 1em;
      background-color: $tertiary;
      font-size: calc(11 / 16 * $font-size-base);
      color: $white;
      font-weight: $font-weight-bold;
      letter-spacing: .2em;
      text-indent: 0.2em;
      text-align: center;
    }
  }

  &__body {
    background-color: $white;
    padding: map-get($spacers, 3) map-get($spacers, 3);
    font-weight: $font-weight-bold;
    letter-spacing: .2em;
    .desc {
      display: inline-block;
      margin-bottom: 1em;
      font-weight: inherit;
      font-size: calc(11 / 16 * $font-size-base);
    }
    .title {
      font-weight: inherit;
      font-size: $font-size-lg;
    }
  }
}

.block-aboutus {
  position: relative;
  padding-bottom: 24px;
  z-index: 0;
  @include media-up(lg) {
    padding-bottom: 120px;
    overflow: hidden;
  }
  &:before, &:after {
    content: '';
    position: absolute;
  }
  &:before {
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: calc(100% - 140px);
    background-color: #0057A8;
    z-index: -1;
  }
  
  &:after {
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 70%;
    background-color: #00478F;
    transform: skewX(-30deg) translateX(-65%);
    z-index: -1;
    @include media-up(lg) {
      width: calc(50vw - 180px);
      height: 100%;
      transform: skewX(-30deg);
    }
  }

  &__head {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding-top: 36px;
    z-index: 0;
    @include media-up(lg) {
      padding-top: 56px;
    }
    @include media-down(lg) {
      flex-direction: column;
    }
    &:before {
      @include media-up(lg) {
        content: '';
        position: absolute;
        top: calc(100% - 50px);
        left: -2.142857143vw;
        width: 18vw;
        height: auto;
        aspect-ratio: 252/359;
        display: block;
        background: center/contain no-repeat;
        background-image: svgUrlFunc('<svg width="252" height="359.05" viewBox="0 0 252 359.05" xmlns="http://www.w3.org/2000/svg"><g fill="#{$white}"><path d="m8.19 359.05h-8.19l173.03-288.27h8.19z"/><path d="m78.97 288.27h-8.19l173.03-288.27h8.19z"/></g></svg>');
        opacity: 0.15;
        z-index: -1;
      }
    }
  }

  &__img {
    width: calc(100% - 32px);
    height: auto;
    @include media-up(lg) {
      width: calc(50vw + 234px);
      height: 434px;
    }
    @include media-down(lg) {
      margin-right: auto;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__title {
    @include c-title-spanEn-smallJa($tertiary, $white);
    position: relative;
    @include media-up(lg) {
      margin-left: min(64px, 4.5vw);
      margin-bottom: 40px;
    }
    @include media-down(lg) {
      justify-content: center;
      text-align: center;
      margin: 48px auto 12px;
    }

    &:after {
      content: '';
      position: absolute;
      top: -26px;
      left: calc(100% - 10px);
      display: block;
      width: 79px;
      height: 69px;
      background: url('/assets/img/recruit/logo_mark.svg') center/contain no-repeat;
      @include media-up(lg) {
        top: -40px;
        left: calc(100% - 58px);
        width: 154px;
        height: 134px;
      }
    }
  }

  &__list {
    display: flex;
    justify-content: center;
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
    @include media-up(lg) {
      min-height: 244px;
      margin: 80px auto 0;
    }
    @include media-down(lg) {
      flex-direction: column;
      padding: 0 map-get($spacers, 3);
    }
  }
}

.block-aboutus-item {
  display: flex;
  color: $white;
  @include media-up(lg) {
    width: calc(100% / 3);
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 40px;
  }
  @include media-down(lg) {
    padding-top: 14px;
    padding-bottom: 14px;
  }

  &:hover, &:focus {
    text-decoration: none;
    color: $white;
    .icon {
      transform: translateX(5px);
    }
  }
  &:hover &__icon, &:focus &__icon {
    img {
      @include media-up(lg) {
        transform: translateY(-10px);
      }
    }
  }

  & + & {
    border: solid rgba($white, 0.3);
    border-width: 1px 0 0;
    @include media-up(lg) {
      border-width: 0 0 0 1px;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    @include media-down(lg) {
      max-width: 45%;
      width: 45%;
    }
    img {
      height: auto;
      transition: $transition-base;
      @include media-down(lg) {
        max-width: 100%;
        width: 72px;
      }
    }

    &.is-icon01 img {
      @include media-down(lg) {
        width: 64px;
      }
    }

    &.is-icon02 img,
    &.is-icon03 img {
      @include media-down(lg) {
        width: 72px;
      }
    }
  }

  &__title {
    margin-bottom: 0;
    font-weight: $font-weight-bold;
    font-size: $font-size-lg;
    letter-spacing: .12em;
    @include media-down(lg) {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-right: auto;
    }
    .icon {
      margin-left: 0.25em;
      fill: currentColor;
      transition: $transition-base;
    }
  }
}

.block-requirements {
  background-color: $white;
  font-feature-settings: "palt";
  &__bg {
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
  &__banner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: min(calc(100% - 27px), 348px);
    height: 120px;
    margin-top: -37px;
    margin-left: auto;
    background-color: #00448A;
    color: $white;
    text-align: center;
    text-decoration: none;
    transition: $transition-base;
    @include media-up(lg) {
      width: 680px;
      height: 204px;
      margin-top: -102px;
    }

    &:hover, &:focus {
      background-color: $tertiary;
      color: $white;
    }
    .enTitle {
      position: absolute;
      top: -30px;
      left: -44px;
      @include media-down(lg) {
        left: -22px;
        width: min(54.666vw, 205px);
        height: auto;
      }
      img {
        @include media-down(lg) {
          max-width: 100%;
          height: auto;
        }
      }
    }
    .title {
      display: inline-flex;
      align-items: center;
      font-weight: $font-weight-bold;
      font-size: $font-size-lg;
      margin-bottom: 0;
      letter-spacing: .12em;
      text-indent: 0.12em;
      @include media-up(lg) {
        font-size: calc(24 / 16 * $font-size-base);
      }
      .icon {
        margin-left: 0.5em;
        fill: currentColor;
        @include media-down(lg) {
          width: 13px;
          height: 12px;
        }
      }
    }
  }
}

.block-news-recruit {
  position: relative;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  background-color: $white;
  font-feature-settings: "palt";
  z-index: 0;
  @include media-down(lg) {
    padding-top: 70px;
    margin-bottom: 80px;
  }
  &__inner {
    max-width: 100%;
    width: calc(960px + calc(map-get($spacers, 3) * 2));
    margin: 0 auto;
    padding: 0 map-get($spacers, 3);
    @include media-up(lg) {
      padding: 100px map-get($spacers, 3) 75px;
    }
  }
  &__row {
    @include media-up(lg) {
      display: flex;
      justify-content: flex-start;
      gap: 64px;
    }
  }

  &__head {
    width: 100%;
    @include media-up(lg) {
      max-width: 130px;
    }
    @include media-down(lg) {
      display: flex;
      align-items: center;
    }
  }

  &__list-wrap {
    width: 100%;
    @include media-up(lg) {
      max-width: 100%;
      flex-grow: 1;
    }
  }

  &__list {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    @include media-up(lg) {
      margin-top: -1.5rem;
    }
  }

  &__title {
    position: relative;
    align-items: flex-start;
    margin-bottom: 0;
    padding-bottom: 14px;
    font-family: 'Cantarell', $font-family-base;
    font-weight: $font-weight-normal;
    font-size: calc(33 / 16 * $font-size-base);
    letter-spacing: .14em;
    color: $tertiary;
    text-align: left;
    @include media-down(lg) {
      margin-bottom: 20px;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 28px;
      height: 1px;
      background-color: $tertiary;
    }
  }

  &__btnwrap {
    text-align: right;
    .btn {
      padding-left: 0;
      padding-right: 0;
      font-family: 'Cantarell', $font-family-base;
      font-weight: $font-weight-medium;
      font-size: calc(13 / 16 * $font-size-base);
      color: $primary;
      letter-spacing: 0.12em;
      text-indent: 0.12em;
      transition: $transition-base;
      @include media-up(lg) {
        margin-top: 2.375rem;
      }
      @include media-down(lg) {
        margin-left: auto;
        font-size: $font-size-base * 0.8125;
      }
      &:hover,
      &:focus {
        text-decoration: none;
        color: #0057A8;
        span {
          border-color: #0057A8;
        }
      }
      span {
        padding: .375rem;
        border-bottom: 1px solid $primary;
        transition: $transition-base;
      }
      .icon {
        margin-left: 0.2em;
        color: #0057A8;
      }
    }
  }
}

.block-news-recruit-item {
  @include list-common__item();
  position: relative;
  &__link {
    display: block;
    padding: 1.4rem 0;
    color: #373737;
    transition: $transition-base;
    &:hover,
    &:focus {
      color: $primary;
      text-decoration: none;
      .date,
      .cate,
      .title.is-new:after,
      .comment {
        color: inherit;
      }
    }
  }

  &__body {
    @include media-up(lg) {
      display: flex;
      flex-direction: column;
    }
  }

  .title {
    flex-grow: 1;
    font-weight: $font-weight-bold;
    font-size: calc(15/16 * $font-size-base);
    letter-spacing: .16em;
    color: inherit;
    @include media-up(lg) {
      font-size: $font-size-base;
    }
  }

  .date-cate {
    margin-bottom: 0.5em;
    color: #A4A3A3;
    @include media-up(lg) {
      margin-right: 1em;
    }
  }
  
  .date {
    margin-bottom: 0;
    margin-right: 0.5em;
    font-family: 'Manrope', $font-family-base;
    font-weight: $font-weight-medium;
    font-size: $font-size-xs;
    color: inherit;
    letter-spacing: .2em;
    @include media-down(md) {
      display: inline-block;
      vertical-align: middle;
    }
  }
  
  .cate {
    vertical-align: middle !important;
    min-width: auto;
    margin: 0 0 0 .5em;
    padding: 0;
    background: none;
    border: none;
    font-weight: $font-weight-bold;
    color: inherit;
    @include media-down(md) {
      max-width: calc(50vw - map-get($spacers, 3) - map-get($gutters, 3));
    }
  }

  & + & {
    border: solid #EAEBEB;
    border-width: 1px 0 0;
  }
}