.block__maintenance__list-content {
  .about__techno__list {
    max-width: 100%;
    width: 320px;
    margin: 0 auto;
    list-style: none;
    font-weight: $font-weight-bold;
    color: #536387;
    line-height: 1.7;
    letter-spacing: 0.16em;
    @include media-up(lg) {
      width: 720px;
      margin: 0 auto;
      padding: 0;
      column-count: 3;
    }
    &__item {
      position: relative;
      margin-bottom: 0.5em;
      padding-left: 1em;
      &::before {
        content: '';
        position: absolute;
        top: .5em;
        left: 0;
        width: 7px;
        height: 7px;
        background-color: #CED4DF;
        border-radius: 100%;
      }
    }
  }
}