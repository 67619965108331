//コーポレートサイト用 デフォルトのコンテンツヘッダー
.content-head {
  margin-top: 40px;
  padding-left: map-get($spacers, 3);
  padding-right: map-get($spacers, 3);
  @include media-up(lg) {
    margin-top: 60px;
  }
  &__title {
    @include c-title-spanEn-smallJa();
    color: #373737;
    font-size: min(9.6vw, 2.5rem);
    font-family: 'Cantarell', $font-family-base;
    text-align: center;
    letter-spacing: 0.12em;
    margin-bottom: 20px;
  
    @include media-up(md) {
      margin-bottom: 30px;
    }
  }
}

//タイトル + バナー ABOUTコンテンツで使用
.content-head-banner {
  max-width: 100%;
  width: calc(100% - 2em);
  margin-left: auto;
  font-feature-settings: "palt";
  @include media-up(lg) {
    width: calc(50% + 486px);
  }

  &__title {
    @include c-title-spanEn-smallJa();
    margin-bottom: 2rem;
    font-size: min(9.6vw, 2.5rem);
    @include media-up(lg) {
      margin-bottom: 60px;
    }
  }

  .semi-title{
    letter-spacing: 0.1em;
  }

  &__img {
    img {
      max-width: 100%;
      height: auto;
      @include media-down(lg) {
        width: 100%;
      }
    }
  }
}