.is--recruit.is--article-2 {
	.rwd002-container {
		overflow: hidden;
	}
}

.block-system-title {
	.title {
		font-size: 1.25rem;
		font-family: 'Jost';
		font-weight: bold;
		letter-spacing: 0.1em;
		display: flex;
		flex-direction: column;
		position: relative;

		@include media-up(lg) {
			width: 210px;
		}

		.subtitle {
			font-size: 14px;
			font-weight: 300;
			line-height: 1.18rem;
			letter-spacing: 0.24em;
			margin-bottom: 1.25rem;
		}
	}
}

.subblock {
	margin-top: 18px;

	.title {
		color: #566384;
		font-size: 1.25rem;
		font-family: $font-family-base;
		font-weight: bold;
		line-height: 46px;
		letter-spacing: 0.14em;
		display: flex;
		gap: 10px;
	}
}

.link-google-map {
	margin-top: 35px;
	display: flex;
	justify-content: center;

	a {
		color: #2B334C;
		width: fit-content;
		font-size: 12px;
		font-family: 'Jost', sans-serif;
		font-weight: 600;
		line-height: 27px;
		letter-spacing: 0.160em;
		padding-bottom: 5px;
		display: flex;
		gap: 5px;
		position: relative;
		transition: all 0.5s;

		&::after {
			content: '';
			width: 100%;
			height: 1px;
			position: absolute;
			bottom: 0;
			background-color: #4D535F;
		}

		&:hover {
			text-decoration: none;
			gap: 10px;
		}
	}
}

.system__banner {
	max-width: 100%;
	width: calc(100% - 2em);
	margin-top: 0;
	margin-left: auto;
	font-feature-settings: "palt";
	@include media-up(lg) {
		width: calc(50% + 486px);
	}

	&-title {
		@include c-title-spanEn-smallJa;
		color: #17D1CF;
		font-size: min(9.6vw, 2.5rem);
		font-family: 'Jost', sans-serif;
		font-weight: 500;
		letter-spacing: 0.18em;
		margin-bottom: 35px;

		@include media-up(lg) {
			width: fit-content;
			text-align: center;
			margin-bottom: 70px;
			position: relative;
			left: 315px;
		}

		.subtitle {
			color: white;
			font-size: min(4vw, 1rem);
			font-family: $font-family-base;
			font-weight: bold;
			line-height: 18px;
			letter-spacing: 0.34em;
			margin-bottom: 10px;

			@include media-up(lg) {
				color: #373737;
			}
		}
	}

	&-img {

		img {
			// max-width: calc(100% + 1.75rem);
			width: 100%;
		}
	}
}

.block__system {
	font-family: $font-family-base;
	font-feature-settings: "palt";
	margin: 0 32.5px;
	padding-top: 40px;
	position: relative;

	@include media-up(lg) {
		display: flex;
		justify-content: flex-start;
		width: calc(50% + 486px);
    margin-left: auto;
    margin-right: 0;
		padding-top: 95px;
	}

	.title {
		color: #0057A8;
		font-size: min(6.4vw, 1.875rem);
		font-family: 'Jost', sans-serif;
		font-weight: 500;
		letter-spacing: 0.14em;
		display: flex;
		flex-direction: column;

		@include media-up(lg) {
			color: white;
			width: 253px;

		}

		.subtitle {
			color: #373737;
			font-size: min(3.47vw, 1rem);
			font-family: $font-family-base;
			font-weight: bold;
			line-height: 15px;
			letter-spacing: 0.26em;
			margin-bottom: 1.25rem;

			@include media-up(lg) {
				color: white;
				line-height: 18px;
				letter-spacing: 0.34em;
			}
		}
	}


	&-content {
		margin-top: 27px;

		@include media-up(lg) {
			width: 960px;
			margin-top: 0;
			margin-left: -253px;
		}

		&__title {
			font-size: min(4.8vw, 1.1875rem);
			font-family: $font-family-base;
			font-weight: bold;
			line-height: 36px;
			letter-spacing: 0.24em;
			margin-bottom: 43px;

			@include media-up(lg) {
				line-height: 38px;
				vertical-align: top;
				margin-bottom: 75px;
				position: relative;
				left: 50%;
				transform: translateX(-135px);
			}
		}

		p {
			color: #373737;
			font-size: 0.9375rem;
			line-height: 32px;
			letter-spacing: 0.12em;
			margin-bottom: 35px;

			@include media-up(lg) {
				line-height: 33px;
				letter-spacing: 0.14em;
			}
		}

		&__card {
			width: calc(100% + 30px);
			margin-left: -15px;
			margin-right: -15px;
			@include media-up(lg) {
				max-width: 960px;
				width: 100%;
				display: grid;
				grid-template-columns: auto auto auto;
				gap: 15px;
				margin: 0 auto;
			}
			img {
				max-width: 100%;
				height: auto;
			}
		}
	}


}

.block__system__cover {
	margin-top: 50px;

	@include media-up(lg) {
		height: 510px;
		margin-top: 100px;
	}

	img {
		width: 100%;

		@include media-up(lg) {
			opacity: 0;
		}
	}

	@include media-up(lg) {
		height: 500px;
		background-image: url('/assets/img/recruit/system/system_cover.jpg');
		background-size: cover;
		background-position: center;
		background-attachment: fixed;
		background-repeat: no-repeat;
		overflow: hidden;
	}
	  /* Safari用のハック */
		_::-webkit-full-page-media,
		_:future,
		:root & {
			@include media-up(lg) {
				background-attachment: scroll !important;
				background: url('/assets/img/recruit/system/system_cover.jpg') 50%/cover no-repeat;
			}
		}
}

.block__system__welfare {
	font-family: $font-family-base;
	font-feature-settings: "palt";
	margin-top: 43px;

	@include media-up(lg) {
		width: calc(50% + 486px);
		margin-left: auto;
		padding-top: 95px;
		display: flex;
		justify-content: flex-start;
		gap: 110px;
	}

	.title {
		color: #0057A8;
		font-size: min(6.4vw, 1.875rem);
		font-family: 'Jost', sans-serif;
		font-weight: 500;
		letter-spacing: 0.14em;
		display: flex;
		flex-direction: column;

		@include media-up(lg) {
			color: white;
			width: 253px;

		}

		.subtitle {
			color: #373737;
			font-size: min(3.47vw, 1rem);
			font-family: $font-family-base;
			font-weight: bold;
			line-height: 15px;
			letter-spacing: 0.26em;
			margin-bottom: 1.25rem;

			@include media-up(lg) {
				color: white;
				line-height: 18px;
				letter-spacing: 0.34em;
			}
		}
	}

	&-content {
		@include media-up(lg) {
			width: 100%;
		}

		.welfare-card {
			min-height: 400px;
			padding: 0 32px 50px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			@include media-up(lg) {
				.sp {
					display: none;
				}
			}

			@include media-up(lg) {
				padding-left: 0;
				padding-bottom: 85px;
			}

			&:first-child {
				@include media-up(lg) {
					.welfare-card-details {
						max-width: 605px;
					}
				}
			}

			&:not(:first-child) {
				padding: 50px 32px;

				@include media-up(lg) {
					padding: 85px 32px 85px 0;
				}
			}

			&:nth-child(even) {
				background-color: #F2F4F7;

				@include media-up(lg) {
					margin-left: -110px;
					padding-left: 110px;
				}
			}

			&:nth-child(2) {
				padding-bottom: 35px;
			}

			&:nth-child(3) {

				.welfare-card-title {
					margin-bottom: 5px;
				}
			}

			&:nth-child(6) {
				.welfare-card-title {
					margin-bottom: 30px;
				}
			}

			&-title {
				color: #566384;
				font-size: 1.3125rem;
				font-weight: bold;
				// line-height: 38px;
				letter-spacing: 0.14em;
				margin-bottom: 20px;
				display: flex;
				// align-items: center;
				gap: 20px;

				.icon {
					width: 46px;
					display: block;

					&--house {
						width: 59px;
					}

					&--gold {
						width: 31px;
					}
				}
			}

			.subtitle {
				font-size: 0.9375rem;
				font-weight: bold;
				line-height: 30px;
				letter-spacing: 0.14em;
			}

			&-details {
				padding: 12px 0;
				position: relative;

				tr:first-child {
					padding-bottom: 15px;
				}

				tr {
					padding: 15px 0;
					display: block;
					position: relative;
				}

				tr:not(:last-child) {
					border-bottom: 1px #E8E8E8 solid;

					&::after {
						content: '';
						width: 77px;
						height: 1px;
						position: absolute;
						left: 0;
						bottom: -1px;
						background-color: #C0C1C1;
					}
				}

				&.text-nounderline {
					border: none;

					&::after {
						display: none;
					}
				}

				&.text {
					font-size: 0.9375rem;
					line-height: 33px;
					letter-spacing: 0.12em;

					@include media-up(lg) {
						line-height: 30px;
						padding-right: 20px;
					}
				}
			}

			.details-title {
				font-size: 0.875rem;
				font-weight: bold;
				letter-spacing: 0.34em;
				margin-bottom: 2px;

				@include media-up(up) {
					width: 115px;
					margin-right: 45px;
					display: inline-block;

				}
			}

			.details-content {
				font-size: 0.875rem;
				letter-spacing: 0.15em;
				margin-bottom: 0;

				@include media-up(lg) {
					display: inline-block;
					vertical-align: top;
				}
			}

			.details-list {
				font-size: 0.875rem;
				letter-spacing: 0.16em;
				white-space: nowrap;
				list-style: none;
				margin: 0;
				padding-left: 15px;
				display: grid;
				grid-template-columns: 141px 160px;
				column-gap: 15px;

				@media screen and (min-width: 568px) {
					grid-template-columns: 141px 141px 150px;
					column-gap: 38px;
				}

				li {
					margin-bottom: 12px;
					position: relative;

					&::before {
						content: '';
						width: 3px;
						height: 3px;
						position: absolute;
						top: 12px;
						left: -15px;
						transform: translateY(-50%);
						background-color: #CFD4DE;
						border-radius: 50%;
					}
				}
			}

			&-dl {
				max-width: 590px;
				margin: 0;
				dt {
					font-size: 18px;
					font-weight: 700;
					line-height: 1.7;
					letter-spacing: .14em;
					&::before {
						content: '-';
					}
				}
				dd {
					font-size: 15px;
					font-weight: 500;
					line-height: 2;
					letter-spacing: .14em;
					margin: 10px 0 0;
				}
				@include media-down(lg){
					dt {
						font-size: 17px;
					}
					dd {
						font-size: 14px;
						margin-top: 5px;
					}
				}
			}

			&--attempt {
				dl {
					&:first-of-type {
						margin-top: 12px;
					}
					+ dl {
						margin-top: 40px;
						@include media-down(lg){
							margin-top: 25px;
						}
					}
					&:last-of-type {
						margin-bottom: 20px;
					}
				}
				ol {
					padding-left: 24px;
				}
				.btn_wrap {
					margin-top: 20px;
					a {
						font-size: 13px;
						font-weight: 500;
						letter-spacing: .12em;
						color: #14204E;
						display: grid;
						place-content: center;
						width: 285px;
						height: 53px;
						border: 1px solid #14204E;
						border-radius: 27px;
						box-sizing: border-box;
						transition: .3s;
						position: relative;

						&::before {
							content: '';
							display: block;
							position: absolute;
							width: 7px;
							height: 7px;
							border-right: 1px solid #14204E;
							border-bottom: 1px solid #14204E;
							transform: rotate(45deg);
							top: 0;
							bottom: 4px;
							right: 25px;
							margin: auto;
							transition: .3s;
						}
						&:hover {
							background-color: #14204E;
							color: #fff;
							text-decoration: none;
							&::before {
								border-color: #fff;
							}
						}
					}
				}
			}

			&-license {
				margin: 30px 0 0;
				padding: 0;
				max-width: 280px;
				@include media-down(lg){
					margin-top: 10px;
				}
				&-item {
					display: flex;
					justify-content: space-between;
					position: relative;
					padding-left: 1em;
					break-inside: avoid;
					margin-bottom: 10px;
					font-weight: 700;
					font-size: .8125rem;
					letter-spacing: .16em;
					color: #536387;
					&::before {
						content: "";
						position: absolute;
						top: .5em;
						left: 0;
						display: inline-block;
						width: 6px;
						height: 6px;
						margin: 0 .5em auto 0;
						border-radius: 100em;
						background-color: #ced4df;
					}
				}
			}

			&-school-list {
				list-style: none;
				padding-left: 0;
				margin-bottom: 40px;

				&-item {
					font-size: 14px;
					font-weight: bold;
					line-height: 21px;
					letter-spacing: 0.14em;
					position: relative;

					&:not(:last-child) {
						margin-bottom: 10px;
					}
				}
			}

			&-illustration {
				@include media-up(lg) {
					display: grid;
					grid-template-columns: 180px 1fr;
					gap: 50px;
					.text {
						padding-top: 0;
					}
				}

				img {
					width: 100%;
				}
			}
		}
	}
}

.block__system__support {
	font-size: 1rem;
	font-family: $font-family-base;
	font-feature-settings: "palt";
	font-weight: bold;
	line-height: 28px;
	letter-spacing: 0.15em;

	@include media-up(lg) {
		width: calc(50% + 488px);
		margin-right: -2.87499975rem;
		margin-left: auto;
		padding-top: 95px;
		display: flex;
		gap: 110px;
	}

	.title {
		color: #0057A8;
		font-size: min(6.4vw, 1.875rem);
		font-family: 'Jost', sans-serif;
		font-weight: 500;
		letter-spacing: 0.14em;
		display: flex;
		flex-direction: column;

		@include media-up(lg) {
			color: white;
			width: 210px;

		}

		.subtitle {
			color: #373737;
			font-size: min(3.47vw, 1rem);
			font-family: $font-family-base;
			font-weight: bold;
			line-height: 15px;
			letter-spacing: 0.26em;
			margin-bottom: 1.25rem;

			@include media-up(lg) {
				color: white;
				line-height: 18px;
				letter-spacing: 0.34em;
			}
		}
	}

	&-content {
		padding: 50px 32px;

		@include media-up(lg) {
			width: 576px;
			padding: 0;
		}
		
		figure{
			@include media-up(lg){
				margin-top: 35px;
			}
		}
	}

	img {
		max-width: 100%;
		width: 100%;
		height: auto;
	}
}