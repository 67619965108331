.is--recruit.is--article-6 {
  .recruit-entry {
    overflow: hidden;
  }
}

.block-businessfield-title {
  .title {
    font-size: 1.25rem;
    font-family: 'Jost';
    font-weight: bold;
    letter-spacing: 0.1em;
    display: flex;
    flex-direction: column;
    position: relative;

    @include media-up(lg) {
      width: 210px;
    }

    .subtitle {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.18rem;
      letter-spacing: 0.24em;
      margin-bottom: 1.25rem;
    }
  }
}

.subblock {
  margin-top: 18px;
  .title {
    color: #566384;
    font-size: 1.25rem;
    font-family: $font-family-base;
    font-weight: bold;
    line-height: 46px;
    letter-spacing: 0.14em;
    display: flex;
    gap: 10px;
  }
}

.link-google-map {
  margin-top: 35px;
  display: flex;
  justify-content: center;

  a {
    color: #2B334C;
    width: fit-content;
    font-size: 12px;
    font-family: 'Jost', sans-serif;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0.160em;
    padding-bottom: 5px;
    display: flex;
    gap: 5px;
    position: relative;
    transition: all 0.5s;

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      background-color: #4D535F;
    }

    &:hover {
      text-decoration: none;
      gap: 10px;
    }
  }
}

.businessfield__banner {
  max-width: 100%;
  width: calc(100% - 2em);
  margin-top: 25px;
  margin-left: auto;
  font-feature-settings: "palt";
  @include media-up(lg) {
    width: calc(50% + 528px);
    margin-top: 0;
  }

  &-title {
    @include c-title-spanEn-smallJa;
    color: #17D1CF;
    font-size: min(9.6vw, 2.5rem);
    font-family: 'Jost', sans-serif;
    font-weight: 500;
    letter-spacing: 0.18em;
    margin-bottom: 35px;

    @include media-up(lg) {
      width: fit-content;
      text-align: center;
      margin-bottom: 70px;
      position: relative;
      left: 315px;
    }

    .subtitle {
      color: white;
      font-size: min(4vw, 1rem);
      font-family: $font-family-base;
      font-weight: bold;
      line-height: 18px;
      letter-spacing: 0.34em;
      margin-bottom: 10px;

      @include media-up(lg) {
        color: #373737;
      }
    }
  }

  &-img {

    img {
      // max-width: calc(100% + 1.75rem);
      width: 100%;
    }
  }
}

.block__businessfield {
  font-family: $font-family-base;
  font-feature-settings: "palt";
  margin: 0 32.5px;
  padding-top: 55px;
  position: relative;

  @include media-up(lg) {
    width: calc(50% + 528px);
    margin-right: -2.87499975rem;
    margin-left: auto;
    padding-top: 95px;
    display: flex;
    gap: 120px;
  }

  .title {
    color: #0057A8;
    font-size: min(6.4vw, 1.875rem);
    font-family: 'Jost', sans-serif;
    font-weight: 500;
    letter-spacing: 0.14em;
    display: flex;
    flex-direction: column;

    @include media-up(lg) {
      color: white;
      width: 210px;

    }

    .subtitle {
      color: #373737;
      font-size: min(3.47vw, 1rem);
      font-family: $font-family-base;
      font-weight: bold;
      line-height: 15px;
      letter-spacing: 0.26em;
      margin-bottom: 1.25rem;

      @include media-up(lg) {
        color: white;
        line-height: 18px;
        letter-spacing: 0.34em;
      }
    }
  }


  &-content {
    margin-top: 27px;
    
    @include media-up(lg) {
      max-width: 587px;
      margin-top: 0;
    }

    &__title {
      color: #4E638A;
      font-size: min(5.1vw, 2rem);
      font-family: $font-family-serif;
      line-height: 1.78;
      letter-spacing: 0.12em;

      @include media-up(lg) {
        line-height: 1.75;
        letter-spacing: 0.14em;
        vertical-align: top;
      }
    }

    p {
      color: #373737;
      font-size: 0.9375rem;
      line-height: 32px;
      letter-spacing: 0.12em;
      margin-bottom: 35px;

      @include media-up(lg) {
        line-height: 33px;
        letter-spacing: 0.14em;
      }
    }
  }

  img {
    width: 100%;
  }
}

.block__businessfield__illustration {
  width: calc(100% + 65px);
  margin: 0 auto;
  margin-left: -32.5px;

  img {
    width: 100%;
  }

  @include media-up(lg){
    max-width: 600px;
    margin-left: 0;
  }

  video{
    width: 100%;
  }
}

.block__businessfield-presentation {
  position: relative;
  background-color: white;
  
  &-container{
    max-width: 1100px;
    width: 100%;
    font-family: $font-family-base;
    font-feature-settings: "palt";
    margin: 0 auto 50px;
    padding: 2.375rem 32.5px;
    position: relative;
  
    @include media-up(lg) {
      margin-top: 85px;
      margin-bottom: 0;
      padding: 85px 0 0;
    }
  }
  
  
  &__bg{

    @include media-up(lg){
      width: 937px;
      height: 717px;
      position: absolute;
      top: 15px;
      left: 50%;
      transform: translateX(155px);
    }
    
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__title {
    font-size: min(5.87vw, 1.5rem);
    font-family: $font-family-base;
    font-weight: bold;
    text-align: center;
    line-height: 34px;
    letter-spacing: 0.2em;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @include media-up(lg) {
      line-height: 44px;
      margin-bottom: 40px;
    }

    .subtitle {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.18rem;
      letter-spacing: 0.24em;
      margin-bottom: 1.25rem;
    }
  }

  &__job {
    margin-top: 27px;
    display: flex;
    flex-direction: column;
    gap: 75px;

    @include media-up(md) {
      margin-top: 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "card-one card-one"
        "card-two card-three"
        "card-four card-five";
      gap: 105px 58px;
    }

    .job-card {
      font-family: $font-family-base;
      font-feature-settings: "palt";
      display: grid;
      grid-template-rows: auto 1fr;

      &:first-child {
        grid-area: card-one;

        .card-content {

          @include media-up(md) {
            max-width: 690px;
            margin: 0 auto;
          }
        }
      }

      &:nth-child(2) {
        grid-area: card-two;
      }

      &:nth-child(3) {
        grid-area: card-three;
      }

      &:nth-child(4) {
        grid-area: card-four;
      }

      &:nth-child(5) {
        grid-area: card-five;
      }

      .card-img {
        position: relative;

        img {
          width: 100%;
        }
      }

      .card-content {
        display: flex;
        flex-direction: column;
      }
      
      &:not(:first-child){

        @include media-up(md){
          .card-title{
            margin-top: 25px;
          }
        }
      }

      .card-title {
        color: #373737;
        font-size: min(7.47vw, 1.875rem);
        font-weight: bold;
        text-align: center;
        letter-spacing: 0.12em;
        margin: 20px 0 25px;
        display: flex;
        flex-direction: column;

        @include media-up(md){
          margin-top: 35px;
        }

        .subtitle {
          color: #2557A3;
          font-size: min(4.27vw, 1.0625rem);
          font-family: 'Jost', sans-serif;
          font-weight: 500;
          line-height: 40px;
          letter-spacing: 0.2em;

          @include media-up(md){
            margin-bottom: 5px;
          }
        }
      }

      .card-details {
        font-size: 0.9375rem;
        line-height: 32px;
        letter-spacing: 0.1em;
      }

      .card-link-container{

        @include media-up(md){
          margin-top: auto;
        }
      }

      .card-link {
        color: #17214B;
        max-width: 700px;
        width: 100%;
        height: 60px;
        font-size: 0.875rem;
        font-family: $font-family-base;
        font-weight: bold;
        text-decoration: none;
        text-align: center;
        line-height: 13px;
        letter-spacing: 0.18em;
        margin: 16px auto 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        transition: all 0.5s;
        background-color: white;
        border: 1px #373737 solid;

        @include media-up(lg){
          margin-top: 20px;
        }

        &::after {
          content: '';
          width: 12px;
          height: 11px;
          position: absolute;
          top: 50%;
          right: 18px;
          transform: translateY(-50%);
          transition: all 0.5s;
          background-image: url('data:image/svg+xml;utf-8,<svg id="_レイヤー_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.24 12.3"><defs><style>.cls-1{fill:none;stroke:#17214b;stroke-miterlimit:10;stroke-width:2px;}</style></defs><g id="_ヘッダーマウスオーバー"><g><path class="cls-1" d="m5.87.74l5.9,5.41-5.9,5.42"/><line class="cls-1" x1="11.77" y1="6.15" x2="0" y2="6.15"/></g></g></svg>');
        }

        &:hover {
          color: white;
          background-color: #17214B;

          &::after {
            background-image: url('data:image/svg+xml;utf-8,<svg id="_レイヤー_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.24 12.3"><defs><style>.cls-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;}</style></defs><g id="_ヘッダーマウスオーバー"><g><path class="cls-1" d="m5.87.74l5.9,5.41-5.9,5.42"/><line class="cls-1" x1="11.77" y1="6.15" x2="0" y2="6.15"/></g></g></svg>');
          }
        }
      }
    }
  }
}

.block__businessfield__links__buttons {
  // margin: 0 32.5px 50px;
  padding: 0 32.5px 50px;
  background-color: white;

  @include media-up(sm) {
    flex-direction: row;
    justify-content: center;
    gap: 25px;
  }
  
  @include media-up(md){
    padding-top: 70px;
    padding-bottom: 150px;
  }

  .btn-link {
    color: white;
    max-width: 605px;
    height: 100px;
    font-size: 1.3125rem;
    font-family: 'Jost', sans-serif;
    font-weight: 500;
    text-decoration: none;
    line-height: 1.4;
    letter-spacing: 0.18em;
    margin: 0 auto;
    padding-left: 42px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    transition: all 0.3s;
    background-color: #00478F;
    border: 1px #00478F solid;

    &::after {
      content: '';
      width: 12px;
      height: 11px;
      position: absolute;
      top: 50%;
      right: 18px;
      transform: translateY(-50%);
      background-image: url('data:image/svg+xml;utf-8,<svg id="_レイヤー_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.24 12.3"><defs><style>.cls-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;}</style></defs><g id="_ヘッダーマウスオーバー"><g><path class="cls-1" d="m5.87.74l5.9,5.41-5.9,5.42"/><line class="cls-1" x1="11.77" y1="6.15" x2="0" y2="6.15"/></g></g></svg>');
    }

    .sublink {
      font-size: 0.75rem;
      font-family: $font-family-base;
      font-weight: 600;
      letter-spacing: 0.05em;
    }

    &:hover, &:focus {
      background-color: #17D1CF;
      border: 1px #17D1CF solid;
    }
  }
}