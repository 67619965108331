.is--guideline {
  .rwd002-block.is-head .is-catlist {
    margin-top: 0;
    margin-bottom: 2em;
    @include media-up(lg) {
      margin-bottom: 4em;
    }
  }

  .rwd002-container {
    margin-top: 0;
  }

  .rwd002-list-content {
    min-height: 120px;
    margin-bottom: 3em;
    padding-top: 2em;
    padding-bottom: 0;
    background: $white;
    @include media-up(lg) {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 4em;
      padding-bottom: 1rem;
    }

    &__inner {
      max-width: 960px;
    }
  }

  .rwd002-detail {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 2rem;
    padding-bottom: 4rem;
    background: $white;
    @include media-up(lg) {
      padding-top: 4rem;
      padding-bottom: 6rem;
    }
  }

  .rwd002-pagination {
    background: $white;
    margin-top: 0;
    margin-bottom: 2em;
    @include media-up(lg) {
      margin-bottom: 0;
    }
    &__totalpages {
      display: none;
    }
  }

  .rwd002-breadcrumbs {
    background: $white;
    margin: 0 auto;
    padding-top: map-get($spacers, 3);
    padding-bottom: map-get($spacers, 3);
    @include media-down(lg) {
      display: none;
    }
  }
}

.content-head-guideline {
  margin-top: 40px;
  padding-left: map-get($spacers, 3);
  padding-right: map-get($spacers, 3);
  @include media-up(lg) {
    margin-top: 60px;
  }
  &__title {
    @include c-recruit-title();
    margin-bottom: 18px;
    @include media-up(lg) {
      margin-bottom: 28px;
    }
    @include media-down(lg) {
      align-items: flex-start;
    }
    span {
      @include media-down(lg) {
        text-indent: 0;
      }
    }
    small {
      @include media-down(lg) {
        color: $white;
        text-indent: 0;
      }
    }
  }
}