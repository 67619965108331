@keyframes jump {

  0%,
  20% {
    transform: translateY(0);
  }

  10% {
    transform: translateY(3px);
  }
}

.anchor-links {
  margin-top: 2.18rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.825rem 1.25rem;

  @include media-up(lg) {
    margin-top: 65px;
    padding-right: 0;
    display: flex;
    justify-content: center;
    gap: 50px;
  }

  .anchor-link {
    color: #4E535D;
    font-size: min(2.93vw, 0.81rem);
    font-family: 'YuGothic', sans-serif;
    font-weight: 500;
    line-height: 1.75rem;
    letter-spacing: 0.290em;
    padding-bottom: 3px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px #4E535D solid;

    @include media-up(lg) {
      width: 140px;
    }

    &:hover, &:focus {
      text-decoration: none;

      .anchor-icon {
        animation: jump 1s infinite;
      }
    }
  }
}