@keyframes slider {
  from {transform: translateX(0);}
  to {transform: translateX(-3423px - 15px);}
}
@keyframes slider_sp {
  from {transform: translateX(0);}
  to {transform: translateX(-2178px - 10px);}
}

@keyframes bg {
  0% {
    opacity: 0;
    transform: scaleX(0) translateX(-5%);
  }
  30% {
    transform: scaleX(1) translateX(0);
  }
  100% {
    transform: scaleX(1) translateX(0);
  }
  30%, 100% {
    opacity: 1;
  }
}

.is--recruit.is--article-1 .rwd002-container {
  @include media-down(lg) {
    margin-top: 40px;
  }
}

.recruit-message {
  @include media-down(lg) {
    padding: 0 map-get($spacers, 3);
  }
  &__title {
    @include c-recruit-title();
    margin-bottom: 35px;
    @include media-up(lg) {
      margin-bottom: 44px;
    }
    @include media-down(lg) {
      align-items: flex-start;
    }
    small {
      @include media-down(lg) {
        color: $white;
      }
    }
  }

  &__slider {
    margin-bottom: 45px;
    overflow: hidden;
    @include media-up(lg) {
      margin-bottom: 110px;
    }
    @include media-down(lg) {
      margin-top: 45px;
      margin-left: calc(map-get($spacers, 3) * -1);
      margin-right: calc(map-get($spacers, 3) * -1);
    }
    &-inner {
      display: flex;
      align-items: center;
      column-gap: 15px;
      animation: slider 50s linear infinite;
      @include media-down(lg) {
        column-gap: 10px;
        animation: slider_sp 50s linear infinite;
      }
    }
    img {
      @include media-down(lg) {
        max-width: 2178px;
        height: auto;
      }
    }
  }

}

.recruit-message-section {
  max-width: 100%;
  margin: 0 auto;
  font-feature-settings: "palt";
  @include media-up(lg) {
    display: flex;
    gap: 104px;
    width: calc(980px + map-get($spacers, 3) + calc(50px + map-get($spacers, 3)));
    margin: 0 0 100px;
    padding-left: map-get($spacers, 3);
    padding-right: calc(50px + map-get($spacers, 3));
    transform: translateX(calc(50vw - 530px));
  }

  &__catch {
    margin: 0 0 100px;
    line-height: 1;
    font-size: calc(26 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    color: #0057A8;
    letter-spacing: .28em;
    @include media-up(lg) {
      margin-bottom: 70px;
      font-size: calc(44 / 16 * $font-size-base);
      color: $white;
    }
    @include media-down(lg) {
      max-width: 11em;
    }
    .bg-wrap {
      position: relative;
      display: inline-block;
      margin-bottom: 10px;
    }
    &.is-animated .bg-wrap::before {
      animation: bg 2.6s cubic-bezier(0.22, 1, 0.36, 1) forwards;
      background: $white;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform-origin: left center;
      @include media-up(lg) {
        background: #0057A8;
      }
    }
    .bg-inner {
      position: relative;
      display: inline-block;
      padding: .4em .75em;
      white-space: nowrap;
      z-index: 1;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    color: $white;
    font-family: 'Jost', $font-family-base;
    font-weight: $font-weight-medium;
    font-size: calc(24 / 16 * $font-size-base);
    letter-spacing: .14em;
    @include media-up(lg) {
      gap: 30px;
      min-width: 267px;
      font-size: calc(30 / 16 * $font-size-base);
    }
    small {
      font-weight: $font-weight-bold;
      font-size: $font-size-base;
      letter-spacing: .26em;
      @include media-up(lg) {
        letter-spacing: .36em;
      }
    }
  }

  &__body {
    @include media-up(lg) {
      margin-right: -50px;
    }
    .title {
      position: relative;
      margin: 0 0 23px;
      padding-bottom: 20px;
      font-size: calc(17 / 16 * $font-size-base);
      line-height: 1.7;
      font-weight: bold;
      letter-spacing: 0.24em;
      @include media-up(lg) {
        margin: 0 0 34px;
        font-size: calc(22 / 16 * $font-size-base);
      }
      
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 20px;
        height: 1px;
        background-color: #4E638A;
      }
    }

    p {
      font-size: calc(15 / 16 * $font-size-base);
      line-height: 2.2;
      letter-spacing: .12em;
    }
  }

  &__body + &__body {
    margin-top: 40px;
    @include media-up(lg) {
      margin-top: 80px;
    }
  }
}


.recruit-message-personality {
  max-width: 100%;
  margin: 0 auto 40px;
  font-feature-settings: "palt";
  @include media-up(lg) {
    display: flex;
    gap: 104px;
    width: calc(980px + map-get($spacers, 3) + calc(50px + map-get($spacers, 3)));
    margin: 0 0 100px;
    padding-left: map-get($spacers, 3);
    padding-right: calc(50px + map-get($spacers, 3));
    transform: translateX(calc(50vw - 530px));
  }


  &__title {
    display: flex;
    flex-direction: column;
    color: #0057A8;
    font-family: 'Jost', $font-family-base;
    font-weight: $font-weight-medium;
    font-size: calc(24 / 16 * $font-size-base);
    letter-spacing: .14em;
    @include media-up(lg) {
      gap: 30px;
      min-width: 241px;
      margin-bottom: 2rem;
      font-size: calc(30 / 16 * $font-size-base);
      color: $white;
    }
    @include media-down(lg) {
      margin-bottom: 30px;
    }
    small {
      font-weight: $font-weight-bold;
      font-size: $font-size-base;
      color: #373737;
      letter-spacing: .26em;
      @include media-up(lg) {
        color: $white;
        letter-spacing: .36em;
      }
      @include media-down(lg) {
        margin-bottom: 1em;
      }
    }
  }

  &__content {
    @include media-up(lg) {
      margin-right: -50px;
    }
  }

  &__body {
    p {
      font-size: calc(15 / 16 * $font-size-base);
      line-height: 2.2;
      letter-spacing: .12em;
    }

    .name {
      font-size: $font-size-lg;
      letter-spacing: .1em;
      text-align: right;
      @include media-up(lg) {
        margin-top: 1.5em;
        font-size: calc(23 / 16 * $font-size-base);
      }
      span {
        font-weight: 600;
        font-family: "游明朝", YuMincho, $font-family-serif;
      }
      small {
        font-size: $font-size-xs;
        @include media-up(lg) {
          font-size: calc(15 / 16 * $font-size-base);
        }
      }
    }
  }

  &__example {
    margin-top: 46px;
    padding-top: 46px;
    border-top: 1px solid #E8E8E8;
    @include media-up(lg) {
      margin-top: 84px;
      margin-bottom: 48px;
      padding-top: 34px;
    }
    
    .title {
      margin-bottom: 26px;
      font-weight: $font-weight-bold;
      color: #566384;
      font-size: calc(17 / 16 * $font-size-base);
      letter-spacing: .18em;
      @include media-up(lg) {
        margin-bottom: 30px;
      }
    }
    
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      @include media-up(lg) {
        column-count: 2;
      }
      li {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 1em;
        padding-left: 1.75em;
        break-inside: avoid;
        font-size: $font-size-sm;
        white-space: nowrap;
        letter-spacing: .16em;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          display: inline-block;
          width: 4px;
          height: 4px;
          margin: auto;
          background-color: #CFD4DE;
          border-radius: 100em;
        }
      }
    }
  }
}
