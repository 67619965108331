.page-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: map-get($spacers, 3);
  row-gap: map-get($spacers, 2);
  padding: 0 map-get($spacers, 3);
  margin: map-get($spacers, 6) 0;
  @include media-down(lg) {
    margin: map-get($spacers, 5) 0 map-get($spacers, 3);
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    flex-basis: 49%;
    max-width: 310px;
    height: 70px;
    padding: 1em 2em;
    background-color: $white;
    border-color: #CAD0DC;
    font-size: calc(15 / 16 * $font-size-base);
    letter-spacing: 0.28em;
    text-indent: 0.28em;
    @include media-down(lg) {
      height: 60px;
      font-size: calc(14 / 16 * $font-size-base);
    }
    &:hover, &:focus, &.current {
      background-color: $quaternary;
      border-color: $quaternary;
      color: $white;
    }
    span {
      position: relative;
    }
  }
}