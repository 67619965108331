//実績紹介
.is--about.is--article-6 {
  .about-entry {
    max-width: calc(970px + calc(map-get($spacers, 3) * 2));
    width: 100%;
    margin: 40px auto 0;
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 3);
    @include media-up(lg) {
      margin-top: 60px;
    }
  }

  .rwd002-pagination {
    display: none;
  }

  .works_ttl {
    @include vertical-title;
  }

  ul {
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
  
  .works_first_sec {
    margin-block: 37px 100px;
    img {
      max-width: 100%;
      height: auto;
    }
    .works-list {
      max-width: 750px;
      margin: 15px auto 0;
      &__item {
        &:nth-of-type(n + 2) {
          margin-top: 65px;
        }
      }
      &__title {
        @include bottom-border-title;
        margin-bottom: 40px;
      }
      .note {
        font-size: 12px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: .12em;
        margin-block: -10px 35px;
      }
      ul {
        display: grid;
        margin-inline: auto;
        column-gap: 32px;
        figure {
          text-align: center;
        }
        p {
          text-align: center;
          margin-top: 10px;
          font-size: 13px;
          font-weight: 500;
          line-height: 1.8;
          letter-spacing: .12em;
        }
        &.col2 {
          grid-template-columns: repeat(2, 1fr);
          max-width: 488px;
        }
        &.col3 {
          grid-template-columns: repeat(3, 1fr);
        }
      }
      h5 {
        margin-block: 40px -5px;
        line-height: 1.2;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: .12em;
      }
      .desc {
        margin-top: 30px;
        font-size: 15px;
        font-weight: 500;
        line-height: 2;
        letter-spacing: .12em;
      }
    }

    @include media-down(lg){
      margin-block: 54px 40px;
      img {
        width: 100%;
      }
      .works-list {
        margin-top: 30px;
        &__item {
          &:nth-of-type(n + 2) {
            margin-top: 35px;
          }
        }
        &__title {
          margin-bottom: 30px;
        }
        .note {
          margin-block: -10px 35px;
        }
        ul {
          column-gap: 14px;
          &.col2 {
            grid-template-columns: repeat(2, 1fr);
          }
          &.col3 {
            grid-template-columns: 1fr;
            li + li {
              margin-top: 20px;
            }
          }
        }
        h5 {
          margin-block: 10px 15px;
          font-size: 16px;
          line-height: 1.4;
        }
        .desc {
          margin-top: 0;
          font-size: 14px;
          line-height: 1.8;
        }
      }
    }
  }
  
  .works_service_sec {
    padding-block: 46px 98px;
    position: relative;
    z-index: 1;
    img {
      max-width: 100%;
      height: auto;
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100vw;
      height: 100%;
      left: calc(50% - 50vw);
      top: 0;
      background-color: #F8F8F8;
      z-index: -1;
    }
    .lead_text {
      text-align: center;
      margin-top: 20px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: .1em;
      line-height: auto;
    }
    .service-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 23px 33px;
      margin-top: 37px;
  
      &__item {
        background-color: #fff;
        padding: 15px 23px 30px;
        box-sizing: border-box;
      }
      &__title {
        border-bottom: 1px solid #627597;
        color: #627597;
        height: 63px;
        display: grid;
        place-content: center;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.3;
        letter-spacing: .14em;
        margin: 0;
      }
      figure {
        margin: 26px auto 0;
        display: grid;
        place-content: center;
        height: 132px;
      }
      ul {
        margin-top: 25px;
        li {
          position: relative;
          padding-left: 15px;
          font-size: 13px;
          font-weight: 700;
          line-height: 1.4;
          color: #536387;
          letter-spacing: .08em;
          + li {
            margin-top: 8px;
          }
          &::before {
            content: '';
            display: block;
            position: absolute;
            width: 9px;
            height: auto;
            border-radius: 50%;
            aspect-ratio: 1 / 1;
            overflow: hidden;
            background-color: #CED4DF;
            left: 0;
            top: 4px;
          }
        }
      }
    }

    @include media-down(lg){
      padding-block: 50px;
      .lead_text {
        margin-top: 25px;
      }
      .service-list {
        margin-top: 27px;
        display: block;
    
        &__item {
          padding: 10px 15px 20px;
          &:nth-of-type(n + 2){
            margin-top: 20px;
          }
        }
        &__title {
          height: 55px;
          font-size: 16px;
        }
        figure {
          margin: 20px auto 0;
          width: 80%;
          height: auto;
          img {
            width: 100%;
          }
        }
        ul {
          margin-top: 20px;
        }
      }
    }
  }
  
  .works_other_sec {
    margin-top: 76px;
    .note {
      font-size: 12px;
      font-weight: 700;
      letter-spacing: .22em;
      position: relative;
      max-inline-size: max-content;
      margin: 35px auto 44px;
    
      &::before {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        left: 50%;
        bottom: -14px;
        transform: translateX(-50%);
        border-left: 4.5px solid transparent;
        border-right: 4.5px solid transparent;
        border-top: 5px solid #cfd4de;
      }
      &::after {
        content: "";
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: -10px;
        background-color: #cfd4de;
      }
    }
    @include media-down(lg){
      margin-top: 50px;
    }
  }
  
  .block__works {
    margin-bottom: 50px;
    font-feature-settings: "palt";
    @include media-up(lg) {
      margin-bottom: 70px;
    }
  
    &__title {
      @include c-title-spanEn-smallJa();
      margin-bottom: 30px;
      font-weight: $font-weight-bold;
      text-align: center;
  
      @include media-up(lg) {
        margin-bottom: 52px;
      }
    }
  
    &__details {
      font-size: min(4vw, 16px);
      font-weight: $font-weight-bold;
      text-align: center;
      letter-spacing: 0.12em;
      text-indent: .1em;
  
      @include media-up(md) {
        margin-bottom: 45px;
      }
    }
  
    &__lead {
      margin-bottom: 28px;
      line-height: 2;
    }
  
    &__container {
      width: 100%;
      margin: 20px 0 35px;
      @include media-up(md) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px 32px;
      }
    }
  
    .works-card {
      @include media-up(md) {
        max-width: calc(calc(100% / 3) - 22px);
      }
      &:not(:last-child) {
        border-bottom: 1px #E9E9E9 solid;
  
        @include media-up(md) {
          border: none;
        }
      }
  
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
  
        @include media-up(md) {
          margin-bottom: 30px;
        }
      }
      
      a:hover, a:focus {
        .works-card-img img{
          transform: scale(1.1);
        }
      }
  
      &-link {
        text-decoration: none;
        padding: 15px 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 20px;
        position: relative;
  
        @include media-up(md) {
          padding: 0;
          grid-template-columns: 1fr;
          gap: 15px;
        }
      }
  
      &-img {
        overflow: hidden;
        @include media-up(lg) {
          max-width: 100%;
          width: 300px;
          height: 210px;
        }
        
        img {
          max-width: 100%;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          transition: transform 0.3s;
        }
      }
  
      &-title {
        color: #373737;
        font-size: 1.125rem;
        font-weight: $font-weight-bold;
        line-height: 27px;
        letter-spacing: 0.12em;
        display: flex;
        flex-direction: column;
        position: relative;
  
        @include media-up(md) {
          justify-content: center;
          align-items: center;
        }
  
        .subtitle {
          font-size: min(2.67vw, 0.875rem);
          font-weight: 500;
          font-family: 'Jost', sans-serif;
          letter-spacing: 0.2em;
  
          @include media-up(md) {
            font-weight: 300;
            letter-spacing: 0.24em;
          }
        }
  
        .main {
          position: relative;
          &::after {
            content: '';
            width: 18px;
            height: 5px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-image: svgUrlFunc('<svg width="18.3" height="4.98" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.3 4.98"><defs><style>.cls-1{fill:none;stroke:#373737;stroke-miterlimit:10;}</style></defs><path class="cls-1" d="m13.48.37l4.48,4.11"/><line class="cls-1" x1="17.96" y1="4.48" x2="0" y2="4.48"/></svg>');
            background-size: 100%;
            background-repeat: no-repeat;
  
            @include media-up(md){
            margin-left: 10px;
            }
          }
  
          &.long:after{
            @include media-down(sm){
              top: 75%;
            }
          }
        }
      }
  
      &.is-no-arrow .works-card-title {
        .main:after {
          display: none;
        }
      }
    }
  
    .block__links__buttons {
  
      .btn-link {
        color: #373737;
        height: 65px;
        position: relative;
        background-color: white;
        border-color: #CFD4DE;
        border-radius: 50px;
  
        @include media-up(md) {
          width: 366px;
          height: 77px;
          margin: 0 auto;
        }
  
        &::after {
          content: '';
          width: 11px;
          height: 10px;
          position: absolute;
          top: 50%;
          right: 35px;
          transform: translateY(-50%);
          background-image: url('data:image/svg+xml;utf-8,<svg id="_レイヤー_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.95 10.58"><defs><style>.cls-1{fill:none;stroke:#d6d6d6;stroke-miterlimit:10;}</style></defs><g id="_文字"><g><rect class="cls-1" x=".5" y=".5" width="8.21" height="6.85"/><polyline class="cls-1" points="11.45 3.24 11.45 10.08 3.24 10.08"/></g></g></svg>');
          background-size: 100%;
          background-repeat: no-repeat;
        }
      }
    }
  }
}