.is--about {
  .rwd002-pagination {
    display: none;
  }

  .rwd002-container {
    margin-top: 0;
  }

  .content-head-banner {
    margin-top: 46px;
    @include media-up(lg) {
      margin-top: 60px;
      margin-bottom: 100px;
    }
  }
}

//事業紹介
@import './about-solution';

//ユーティリティ設備
@import './about-utility';

//半導体製造設備
@import './about-semiconductor';

//システムオートメーション
@import './about-automation';

//メンテナンス
@import './about-maintenance';

//実績紹介
@import './about-works'