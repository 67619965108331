//////// 先輩インタビューの新卒タグ非表示 ////////////
// .is--recruit.is--list {
//   .block-people-item:nth-of-type(2) .block-people-item__thumb {
//     &::before {
//       content: 'キャリア';
//       display: block;
//       position: absolute;
//       min-width: 103px;
//       padding: 0.5em 1em;
//       background-color: #17d1cf;
//       font-size: .6875rem;
//       color: #fff;
//       font-weight: 700;
//       letter-spacing: .2em;
//       text-indent: 0.2em;
//       text-align: center;
//       left: 0;
//       bottom: 0;
//     }
//     .cate {
//       display: none;
//     }
//   }
// }
// .is--people.is--list {
//   .people-item:nth-of-type(2) .people-item__thumb {
//     &::before {
//       content: 'キャリア';
//       display: block;
//       position: absolute;
//       min-width: 103px;
//       padding: 0.5em 1em;
//       background-color: #17d1cf;
//       font-size: .6875rem;
//       color: #fff;
//       font-weight: 700;
//       letter-spacing: .2em;
//       text-indent: 0.2em;
//       text-align: center;
//       left: 0;
//       bottom: 0;
//     }
//     .cate {
//       display: none;
//     }
//   }
// }
////////////////////////////////////////////////

.is--people {
  &.is--detail {
    .rwd002-block.is-head .is-catlist {
      display: none;
    }
  }
  .rwd002-container {
    margin-top: 0;
  }

  .rwd002-container + .rwd002-pagination {
    display: none;
  }

  .rwd002-pagination {
    background: $white;
    margin-top: 0;
    margin-bottom: 0;
    &__totalpages {
      display: none;
    }
  }

  .rwd002-breadcrumbs {
    background: $white;
    margin: 0 auto;
    padding-top: map-get($spacers, 3);
    padding-bottom: map-get($spacers, 3);
    @include media-down(lg) {
      display: none;
    }
  }

  .rwd002-block-content.is-catlist {
    margin-top: 10px;
  }

  .is-catlist .rwd002-block-content__inner {
    @include media-down(lg) {
      justify-content: flex-start;
    }
  }
  
}

.content-head-people {
  margin-top: 40px;
  padding-left: map-get($spacers, 3);
  padding-right: map-get($spacers, 3);
  @include media-up(lg) {
    margin-top: 60px;
  }
  &__title {
    @include c-recruit-title();
    margin-bottom: 30px;
    @include media-up(lg) {
      margin-bottom: 58px;
    }
    @include media-down(lg) {
      align-items: flex-start;
    }
    span {
      @include media-down(lg) {
        text-indent: 0;
      }
    }
    small {
      @include media-down(lg) {
        color: $white;
        text-indent: 0;
      }
    }
  }
}

.list-categoryNav {
  display: flex;
  justify-content: center;
  @include media-down(md) {
    margin-bottom: map-get($spacers, 4);
  }
  .people-list-container & {
    margin-bottom: 3.5rem;
  }
  &__list{
    width: auto;
    max-width: calc(1280px + 1em);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    margin: 0 auto 0;
    padding: 0;
    @include media-down(lg) {
      margin: 0 -0.8em 0;
      justify-content: flex-start;
    }
    @include media-down(md) {
      margin: 0 -0.5em 0;
    }
  }
  .cate {
    @extend .btn;
    @extend .btn-outline-primary;
    min-width: 100px;
    padding-left: 1.2em;
    padding-right: 1.2em;
    border-radius: 3px;
    background-color: $white;
    color: #627597;
    border-color: #CAD0DC;
    margin: 1em 0.5em 0;
    font-size: $font-size-sm;
    letter-spacing: .12em;
    text-indent: 0.12em;
    @include media-down(md) {
      padding-top: .325em;
      padding-bottom: .325em;
      margin: 1em 0.415em 0;
    }
    @include media-up(lg) {
      min-width: 154px;
    }
  }
  .cate.current {
    background-color: #163673;
    border-color: #163673;
    color: $white;
  }
}

@mixin c-people-item() {
  width: 100%;
  box-shadow: 20px 20px 20px -15px rgba($black, 0.075);
  transition: $transition-base;
  @include media-up(lg) {
    width: calc(calc(100% / 3) - 30px);
  }

  &:hover, &:focus {
    transform: scale(1.05);
  }

  &__link {
    text-decoration: none;
    &:hover, &:focus {
      text-decoration: none;
    }
  }

  &__thumb {
    position: relative;
    margin-bottom: 0;
    aspect-ratio: 290/330;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
      transition: transform .2s ease-in-out;
    }
    .cate {
      position: absolute;
      bottom: 0;
      left: 0;
      min-width: 103px;
      min-height: 28px;
      padding: .5em 1em;
      background-color: $tertiary;
      font-size: $font-size-xs;
      color: $white;
      font-weight: $font-weight-bold;
      letter-spacing: .2em;
      text-indent: 0.2em;
      text-align: center;
    }
  }

  &__body {
    background-color: $white;
    padding: map-get($spacers, 3) map-get($spacers, 3);
    font-weight: $font-weight-bold;
    letter-spacing: .2em;
    .desc {
      display: inline-block;
      margin-bottom: 1em;
      font-weight: inherit;
      font-size: calc(11 / 16 * $font-size-base);
    }
    .title {
      display: block;
      font-weight: inherit;
      font-size: $font-size-lg;
    }
  }
}

//一覧
.people-list-container {
  padding: 0 map-get($spacers, 3);
}

.people-list-itemlist {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 33px 0;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto 38px;
  padding: 0;
  list-style: none;

  @include media-up(lg) {
    align-items: flex-start;
    flex-direction: row;
    gap: 38px 45px;
    margin: 0 auto 90px;
  }
}

.people-item {
  @include c-people-item;
}

//詳細
.people-detail-head {
  @include media-up(lg) {
    margin-bottom: 110px;
  }

  &__row {
    display: flex;
    flex-direction: column;
    @include media-up(lg) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
    }
  }

  &__img {
    width: 100%;
    height: auto;
    @include media-up(lg) {
      grid-column: 2;
      grid-row: 1;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__body {
    color: $white;
    background-color: #00478F;
    padding: 2rem map-get($spacers, 3) 40px;
    @include media-up(lg) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      grid-column: 1;
      grid-row: 1;
      padding: 50px min(102px, 7.2857vw) 50px 2rem;
    }
  }

  &__body-inner {
    .cate {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 103px;
      min-height: 28px;
      margin-bottom: 16px;
      padding: .5em 1em;
      background-color: $tertiary;
      font-size: calc(12 / 16 * $font-size-base);
      color: $white;
      font-weight: $font-weight-bold;
      letter-spacing: .2em;
      text-indent: 0.2em;
      text-align: center;
      @include media-up(lg) {
        margin-bottom: 40px;
      }
    }

    .name {
      display: flex;
      flex-direction: column;
      letter-spacing: .1em;
      span {
        margin-bottom: 10px;
        font-size: $font-size-xs;
        letter-spacing: .18em;
        @include media-up(lg) {
          font-size: $font-size-sm;
        }
      }
      strong {
        margin-bottom: 14px;
        font-size: calc(22 / 16 * $font-size-base);
        @include media-up(lg) {
          margin-bottom: 20px;
          font-size: calc(26 / 16 * $font-size-base);
        }
      }
      small {
        font-size: $font-size-xs;
      }
    }
  }

  &__catch {
    margin-bottom: 24px;
    font-weight: $font-weight-bold;
    font-size: calc(24 / 16 * $font-size-base);
    line-height: 1.6;
    letter-spacing: .22em;
    @include media-up(lg) {
      margin-bottom: 30px;
      font-size: calc(30 / 16 * $font-size-base);
    }
  }
}

.people-detail-lead {
  font-feature-settings: "palt";
  padding: 50px map-get($spacers, 3) 0;
  @include media-up(lg) {
    padding: 0 map-get($spacers, 3) 66px 0;
  }
  &__row {
    display: flex;
    gap: 0 65px;
    @include media-down(lg) {
      gap: 20px 0;
      flex-direction: column;
    }
  }
  &__body {
    @include media-up(lg) {
      display: flex;
      align-items: center;
      width: calc(50% + 40px);
    }
  }
  &__body-inner {
    @include media-up(lg) {
      max-width: 600px;
      width: 100%;
      margin-right: auto;
    }
    .title {
      margin-bottom: 20px;
      line-height: 1.7;
      font-family: $font-family-serif;
      font-size: calc( 19 / 16 * $font-size-base);
      color: #4E638A;
      letter-spacing: .12em;
      @include media-up(lg) {
        margin-bottom: 36px;
        font-size: calc( 24 / 16 * $font-size-base);
        letter-spacing: .0825em;
      }
    }
    p {
      font-size: calc(15 / 16 * $font-size-base);
      line-height: 2.2;
      letter-spacing: .12em;
    }
  }
  &__img {
    @include media-up(lg) {
      width: 50%;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}

.people-detail-section {
  padding: 0 map-get($spacers, 3);
  background-color: $white;
  font-feature-settings: "palt";
  @include media-up(lg) {
    padding-top: 60px;
    padding-bottom: 70px;
  }
  &__inner {
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
  }
  &__body {
    margin: 50px auto;
    @include media-up(lg) {
      max-width: 750px;
      width: 100%;
      margin: 0 auto 50px;
    }
    .title {
      margin-bottom: 20px;
      line-height: 1.8;
      font-family: $font-family-serif;
      font-size: calc( 19 / 16 * $font-size-base);
      color: #4E638A;
      letter-spacing: .12em;
      @include media-up(lg) {
        margin-bottom: 36px;
        font-size: calc( 24 / 16 * $font-size-base);
        letter-spacing: .0825em;
        text-align: center;
      }
    }
    p {
      font-size: calc(15 / 16 * $font-size-base);
      line-height: 2.06;
      letter-spacing: .12em;
      @include media-up(lg) {
        line-height: 2.2;
      }
    }
  }
  &__img {
    @include media-up(lg) {
      margin: 0 auto 60px;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.people-detail-schedule {
  background-color: #F2F4F7;
  padding: 74px map-get($spacers, 3);
  @include media-up(lg) {
    padding: 100px map-get($spacers, 3) 75px;
  }

  &__title {
    @include c-recruit-title($body-color,#0057A8);
    margin-bottom: 56px;
    @include media-up(lg) {
      margin-bottom: 64px;
    }
    @include media-down(lg) {
      font-size: calc(24 / 16 * $font-size-base);
    }
    small {
      @include media-down(lg) {
        color: $body-color;
        font-size: calc(13 / 16 * $font-size-base);
      }
    }
  }

  &__list {
    position: relative;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    z-index: 0;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: .75em;
      display: block;
      width: 1px;
      height: calc(100% - .75em);
      background-color: #CAD0DC;
      z-index: -1;
      @include media-up(lg) {
        left: 20.75%;
      }
    }
  }

  &__item {
    @include media-up(lg) {
      display: grid;
      grid-template-columns: 20.75% auto;
      grid-template-rows: auto;
      gap: 46px 0;
    }
    @include media-down(lg) {
      position: relative;
      padding-left: 30px;
    }
    &:before {
      @include media-down(lg) {
        content: '';
        position: absolute;
        top: .45em;
        left: 0;
        display: inline-block;
        width: 14px;
        height: 13px;
        background-image: svgUrlFunc('<svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13"><path fill="#{#cad0dc}" d="M7,0C3.13,0,0,2.91,0,6.5s3.13,6.5,7,6.5,7-2.91,7-6.5S10.87,0,7,0Zm0,9.29c-1.66,0-3-1.25-3-2.79s1.34-2.79,3-2.79,3,1.25,3,2.79-1.34,2.79-3,2.79Z"/><ellipse fill="#{#4e638a}" cx="7" cy="6.5" rx="3" ry="2.79"/></svg>');
        transform: translateX(-50%);
        z-index: 1;
      }
    }
    .time {
      font-family: 'Roboto', $font-family-base;
      font-weight: $font-weight-light;
      font-family: $font-size-sm;
      letter-spacing: .18em;
      @include media-down(lg) {
        font-size: calc(15 / 16 * $font-size-base);
      }
    }
    dt {
      position: relative;
      text-align: right;
      z-index: 0;
      @include media-up(lg) {
        padding-right: 24px;
      }
      .time {
        @include media-down(lg) {
          display: none;
        }
      }
      &:before {
        @include media-up(lg) {
          content: '';
          position: absolute;
          top: .3em;
          right: 0;
          display: inline-block;
          width: 14px;
          height: 13px;
          background-image: svgUrlFunc('<svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13"><path fill="#{#cad0dc}" d="M7,0C3.13,0,0,2.91,0,6.5s3.13,6.5,7,6.5,7-2.91,7-6.5S10.87,0,7,0Zm0,9.29c-1.66,0-3-1.25-3-2.79s1.34-2.79,3-2.79,3,1.25,3,2.79-1.34,2.79-3,2.79Z"/><ellipse fill="#{#4e638a}" cx="7" cy="6.5" rx="3" ry="2.79"/></svg>');
          transform: translateX(50%);
          z-index: 1;
        }
      }
    }
    dd {
      padding: 0;
      @include media-up(lg) {
        padding-left: 2rem;
      }
      .time {
        @include media-up(lg) {
          display: none;
        }
      }
      strong {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        font-size: $font-size-lg;
        letter-spacing: .14em;
        @include media-up(lg) {
          display: block;
          font-size: calc(17 / 16 * $font-size-base);
        }
        @include media-down(lg) {
          gap: 0 1rem;
        }
      }

      p {
        margin-bottom: 0;
        font-size: calc(13 / 16 * $font-size-base);
        line-height: 2;
        letter-spacing: .16em;
      }

      p + p {
        margin-top: 1em;
      }
    }
  }
}

.people-detail-foot {
  background: $white;
  padding: 30px map-get($spacers, 3);
  @include media-up(lg) {
    padding: 70px map-get($spacers, 3);
  }
}

//関連記事
.people-related-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 33px 0;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 0 0 38px;
  list-style: none;
  
  @include media-up(lg) {
    align-items: flex-start;
    flex-direction: row;
    gap: 38px 45px;
    padding: 0;
  }

  &__wrap {
    background: $white;
    @include media-up(lg) {
      padding-top: 52px;
    }
  }
}
.people-related-item {
  @include c-people-item;
  small {
    font-size: calc(13 / 16 * $font-size-base);
    @include media-up(lg) {
      font-size: calc(15 / 16 * $font-size-base);
    }
  }
  span {
    font-size: calc(24 / 16 * $font-size-base);
    @include media-up(lg) {
      font-size: calc(28 / 16 * $font-size-base);
    }
  }
}