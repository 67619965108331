.block__semiconductor__list-content {
  .about__techno__list {
    &__item {
      @include media-up(md) {
        &:nth-child(2) { order: 8; }
        &:nth-child(3) { order: 4; }
        &:nth-child(4) { order: 3; }
        &:nth-child(5) { order: 7; }
        &:nth-child(6) { order: 2; }
        &:nth-child(7) { order: 5; }
        &:nth-child(8) { order: 6; }
      }
    }
  }
}