
//採用サイト共通
.is--recruit,
.is--people,
.is--guideline {
  .rwd002-header {
    @include media-down(lg) {
      background-color: #0057A8;
      transition: $transition-base;
    }
    @include media-up(lg) {
      background-color: transparent;
      padding-left: min(50px, 3vw);
    }
    @include media-up(xl) {
      padding-left: 50px;
    }
    &.is-transparent {
      background-color: transparent;
    }
    &__logo .logo-recruit {
      fill: $tertiary;
      @include media-up(lg) {
        width: 72px;
        height: auto;
        margin-left: min(15px, 1vw);
      }
      @include media-up(xxl) {
        width: 90px;
        margin-left: min(20px, 1.4vw);
      }
    }
    &__logo-link {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
    }
    .header-contact-link {
      flex-direction: column;
      font-family: 'Jost', $font-family-base;
      font-weight: 600;
      background: $tertiary;
      color: $black;
      font-size: $font-size-xs;
      letter-spacing: .12em;
      text-indent: 0.12em;
      text-decoration: none;
      &:hover, &:focus {
        background-color: #0057A8;
        color: $white;
      }
      .icon {
        width: 8px;
        height: 8px;
        fill: currentColor;
      }
    }
  }

  .rwd002-header__drawerBtn {
    background-color: transparent;
  }

  .rwd002-header__drawerBtn__bars {
    stroke: $white;
  }

  .rwd002-header__drawerBtn.is-active .rwd002-header__drawerBtn__bars {
    stroke: $body-color;
  }

  .rwd002-gnav__switch {
    @include media-down(lg) {
      margin-top: 20px;
    }
  }

  .rwd002-gnav-item {
    @include media-up(lg) {
      font-size: min(calc(13 / 16 * $font-size-base), 1vw);
      letter-spacing: .1em;
    }
    @include media-up(xxl) {
      letter-spacing: .2em;
    }
    &__link {
      color: #373737;
      @include media-up(lg) {
        padding: 0 min(.75em, 1vw);
      }
      @include media-up(xl) {
        padding: 0 min(.75em, 1vw);
      }
      @include media-up(xxl) {
        padding: 0 min(1em, 1vw);
      }
    }

    &__link span:after {
      background-color: $tertiary;
    }
    
    &.is-form {
      @include media-up(lg) {
        width: 120px;
      }
      @include media-up(xl) {
        width: 150px;
      }
      @include media-down(lg) {
        border: none;
      }
    }

    &.is-form .rwd002-gnav-item__link {
      font-family: 'Jost', $font-family-base;
      font-weight: 600;
      border-radius: 0;
      background: $tertiary;
      font-size: $font-size-sm;
      color: $black;
      @include media-up(lg) {
        flex-direction: row;
      }
      @include media-down(lg) {
        justify-content: center;
        height: 55px;
        margin-top: 2.5em;
        margin-bottom: 0;
        padding: 1em 2em;
        border: none;
      }
      &:after {
        display: none;
      }
      span {
        position: relative;
        @include media-down(lg) {
          display: flex;
          order: 1;
        }
      }
      span:before {
        content: '';
        display: inline-block;
        width: 13px;
        height: 12px;
        margin: auto;
        background: svgUrlFunc('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 12" width="13" height="12"><polygon fill="#{$black}" points="6.43 0 5.1 1.44 9.03 5.02 0 5.02 0 6.98 9.03 6.98 5.1 10.56 6.43 12 13 6 6.43 0"/></svg>') center/contain no-repeat;
        transition: $transition-base;
        @include media-up(lg) {
          position: absolute;
          top: 0;
          bottom: 0;
          left: calc(100% + 0.5em);
        }
        @include media-down(lg) {
          margin-left: 0.5em;
          order: 2;
        }
      }
      &:hover, &:focus {
        background-color: #0057A8;
        color: $white;
        span:before {
          background: svgUrlFunc('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 12" width="13" height="12"><polygon fill="#{$white}" points="6.43 0 5.1 1.44 9.03 5.02 0 5.02 0 6.98 9.03 6.98 5.1 10.56 6.43 12 13 6 6.43 0"/></svg>') center/contain no-repeat;  
        }
      }
      .icon {
        margin: 0;
        fill: currentColor;
        @include media-up(lg) {
          margin-left: 0.5em;
        }
      }
    }
  }

  .rwd002-footer {
    background-color: #00478F;
  }
}

.is--recruit {
  .rwd002-pagination,
  .rwd002-breadcrumbs {
    display: none;
  }
}

.content-head-recruit {
  margin-top: 40px;
  padding-left: map-get($spacers, 3);
  padding-right: map-get($spacers, 3);
  @include media-up(lg) {
    margin-top: 60px;
  }
  &__title {
    @include c-recruit-title();
    margin-bottom: 30px;
    @include media-up(lg) {
      margin-bottom: 58px;
    }
    @include media-down(lg) {
      align-items: flex-start;
    }
    span {
      @include media-down(lg) {
        text-indent: 0;
      }
    }
    small {
      @include media-down(lg) {
        color: $white;
        text-indent: 0;
      }
    }
  }
}

//Recruit TOP
@import './recruit-home';

//メッセージ
@import './recruit-message';

//教育・福利厚生
@import './recruit-system';

//数字でわかる吉和田浜松
@import './recruit-data';

//募集要項・選考について
@import './recruit-requirements';

//漫画で見る吉和田浜松
@import './recruit-comic';

//職種紹介
@import './recruit-businessfield';